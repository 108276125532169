import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect,Suspense, useState } from 'react';
import { quoritLogo } from "../components/images";

const NotFound = () => {
  let [loading, setLoading] = useState(true)
  let location = useLocation()
  useEffect(() => {
   setLoading(false)
  },[location.pathname])
  return (
    <>
      {loading && (
                <div className="loadergifdiv">
                    <img src={quoritLogo} alt="loader" className="img-fluid" style={{maxWidth:"320px"}} />
                </div>
            )}
      <h1
        style={{
          height: "100%",
          minHeight: "85vh",
          color: "#111",
          fontSize: "7.5em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        404
        <br />
        <Link
          to={"/login"}
          style={{
            display: "block",
            fontSize: ".5em",
            textDecoration: "underline",
          }}
        >
          Login
        </Link>
      </h1>
    </>
  );
};

export default NotFound;


