import { useEffect, useState } from "react";
import "./SharedWith.scss";
import Modal from "react-bootstrap/Modal";
import callApi from "../../../util/apiCaller";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../../util/Message";

const SharedWith = (props) => {
  const [sharedCases, setSharedCases] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    async function fetchContent() {
      const res = await callApi(`case/${props.caseId}`, "get");
      let arr = [];
      let docotorList = [];
      if (res && res?.status === "Success") {
        arr = [...res.data.case.referred_doctor_list];
        for (let item of arr) {
          const doc = await callApi(`doctor/${item}`, "get");
          if (doc && doc?.status === "Success") {
            docotorList.push(doc?.data?.doctor);
          }
        }
        setSharedCases(docotorList);
      }
    }
    if (props?.caseId && search === "") {
      fetchContent(props.caseId);
    }
  }, [props.caseId, search]);
  const doctorDetailHandler = (keyName, keyValue) => {
    setSearch(keyValue);

    let filt = sharedCases.filter(
      (item) => !item.contact_name.toLowerCase().indexOf(keyValue)
    );
    setSharedCases(filt);
  };
  const handleRemoveDoctor = async (id) => {
    if (id && sharedCases?.length > 1) {
      if (window.confirm("Do you want to delete referred doctor?") === true) {
        const res = await callApi(
          `case/${props.caseId}/referred-doctor/${id}/remove-from-case`,
          "post",
          {
            is_deleted: true,
          }
        );
        if (res && res.status === "Success") {
          toast(SuccessMsg("Referred doctor has been sucessfully deleted "), {
            className: "mail_toast",
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
          });
        } else {
          toast(SuccessMsg("Something went wrong"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
      }
    } else {
      toast(
        SuccessMsg("Referred doctor cannot be deleted should be more than one"),
        {
          className: "mail_toast",
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
        }
      );
    }
  };
  return (
    <>
      <Modal
        {...props}
        className={`define_modal ${props?.className}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div class="modal-header">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30em",
              padding: "1em 3.0em",
            }}
          >
            <div
              style={{
                fontSize: "1.5em",
                marginBottom: "10px",

                fontWeight: "600",
              }}
            >
              Shared with
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.onHide();
                }}
              ></button>
            </div>
          </div>
          <div className="patient_flex_input">
            <input
              placeholder="Search"
              value={search}
              onChange={(e) => {
                doctorDetailHandler("patient", e.target.value);
              }}
            />
            <img src="images/Search.png" alt="" />
          </div>
        </div>

        <Modal.Body>
          {sharedCases?.map((data, i) => (
            <div className="define_modal_wrapper" key={data._id}>
              <span className="list_item" key={`define-${Math.random(i)}`}>
                {data?.contact_name}
              </span>
              <span>
                <p
                  style={{
                    padding: "0.475em 0.625em",
                    marginLeft: "0.475em",
                    border: "1px solid #ED8847",
                    background: "#fbf1e9",
                    borderRadius: "10px",
                    fontSize: "1.125em",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveDoctor(data?._id)}
                >
                  Remove
                </p>
              </span>
            </div>
          ))}
        </Modal.Body>
        <div class="modal-footer"></div>
      </Modal>
    </>
  );
};

export default SharedWith;
