import { Card, Container, Row, Col } from "react-bootstrap";
import Header from "../Header/Header";
import styles from "./homePage.module.scss";
import { problemImg, productImg } from "../../components/images/index";
import { useEffect, useState } from "react";
import HeaderV2 from "../../components/Header/HeaderV2";
import HomeModal from "./HomeModal";
import { Link, useNavigate } from "react-router-dom";
import cases from "../../components/constant/differentcases.json";
import { useDispatch } from "react-redux";
import {
  setCases,
  setCompleted,
  setConsult,
  setOngoing,
  setReffered,
} from "../../redux/action";
import { USER_TYPE } from "../../components/constant/authEnums";

const HomePage = () => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = window.localStorage.getItem(USER_TYPE);
  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);
  const handleUserDetail = (data) => {
    navigate("/cases-page");
    if (data === cases[0].label) {
      dispatch(setCases(data));
      dispatch(setOngoing(true));
      dispatch(setCompleted(false));
      dispatch(setReffered(false));
      dispatch(setConsult(false));
    } else if (data === cases[1].label) {
      dispatch(setCases(data));
      dispatch(setOngoing(false));
      dispatch(setCompleted(false));
      dispatch(setReffered(true));
      dispatch(setConsult(false));
    } else if (data === cases[2].label) {
      dispatch(setCases(data));
      dispatch(setOngoing(false));
      dispatch(setCompleted(false));
      dispatch(setReffered(false));
      dispatch(setConsult(true));
    } else if (data === cases[3].label) {
      dispatch(setCases(data));
      dispatch(setOngoing(false));
      dispatch(setCompleted(true));
      dispatch(setReffered(false));
      dispatch(setConsult(false));
    }
  };
  return (
    <>
      <HeaderV2 />
      <Container className={`${styles.main_flex}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fbf1e9",
            padding: "16px 0",
            margin: "30px auto",
            maxWidth: "350px",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal(true);
          }}
        >
          <img
            src="images/lightbulb-flash-line.svg"
            width={40}
            height={45}
            style={{ marginRight: "20px" }}
            alt="img"
          />
          <h3 style={{ paddingTop: "10px" }}>How to Use ?</h3>
        </div>
        <Row>
          {userType === "patient" ? null : (
            <>
              {" "}
              <Col lg={4}>
                {/* <div className={`${styles.home_flex}`}> */}
                <Link
                  className={`${styles.home_flex_child}`}
                  to={"https://quorit.com/"}
                  target="_blank"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src="images/blog 1.png" alt="img1" />
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h3>Resources</h3>
                  </div>
                </Link>
              </Col>
              <Col lg={4}>
                {/* <div style={{width:"350px", backgroundColor:"#fbf1e9",  padding:"40px"}}> */}
                <div
                  className={`${styles.home_flex_child}`}
                  onClick={() => handleUserDetail(cases[1].label)}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src="images/network_img.png" alt="img2" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h3>Cases Shared To Me</h3>
                  </div>
                </div>
              </Col>
            </>
          )}
          <Col lg={4}>
            {/* <div style={{ width:"350px",  backgroundColor:"#fbf1e9", padding:"40px"}}> */}
            <div
              className={`${styles.home_flex_child}`}
              onClick={() => handleUserDetail(cases[3].label)}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="images/right_tick_img.png" alt="img4" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3>Completed cases</h3>
              </div>
            </div>
            {/* </div> */}
          </Col>
          <Col lg={4}>
            {/* <div style={{ width:"350px",  backgroundColor:"#fbf1e9",marginTop:"20px",  padding:"40px"}}> */}
            <div
              className={`${styles.home_flex_child}`}
              onClick={() => handleUserDetail(cases[0].label)}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="images/Frame_spin_img.png" alt="img5" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3>Ongoing cases</h3>
              </div>
            </div>
          </Col>
          {userType === "patient" ? null : (
            <>
              {" "}
              <Col lg={4}>
                {/* <div style={{ width:"360px",  backgroundColor:"#fbf1e9", marginTop:"20px", paddingTop:"40px", paddingBottom:"40px", paddingLeft:"30px",paddingRight:"30px"}}> */}
                <div
                  className={`${styles.home_flex_child}`}
                  onClick={() => handleUserDetail(cases[2].label)}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src="images/person_msg_img.png" alt="img6" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h3>Cases Sent for Discussion</h3>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                {/* <div style={{ width:"350px",  backgroundColor:"#fbf1e9",marginTop:"20px",  paddingTop:"40px",paddingBottom:"40px", paddingLeft: "40px", paddingRight:"40px" }}> */}
                <div
                  className={`${styles.home_flex_child}`}
                  onClick={() => navigate("/patient-list")}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src="images/PATIENT LIST.svg" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h3>Patient List</h3>
                  </div>
                </div>
                {/* </div> */}
              </Col>
            </>
          )}
          <Col lg={4}>
            {/* <div className={`${styles.home_flex_child1}`} style={{ width:"350px",  backgroundColor:"#fbf1e9",marginTop:"20px",  padding:"40px"}}> */}
            <div
              className={`${styles.home_flex_child}`}
              onClick={() => {
                navigate("/product-page");
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={productImg} alt="img7" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3>Products</h3>
              </div>
            </div>
            {/* </div> */}
          </Col>
          {/* <Col lg={3} className={`${styles.home_flex_child}`}> */}
          {/* <div style={{ width:"350px",  backgroundColor:"#fbf1e9",marginTop:"20px",  padding:"40px"}}> */}
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={problemImg} width={200} alt="" />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h3 >How to use</h3>
                        </div> */}

          {/* </Col> */}
        </Row>
      </Container>
      <HomeModal
        className="white_theme"
        show={modal}
        onHide={() => setModal(false)}
      />
    </>
  );
};

export default HomePage;
