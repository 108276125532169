import { Container, Stack } from "react-bootstrap";
import styles from "./otpLoginPage.module.scss";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import RadioButton from "../../components/RadioButton5/RadioButton";
import logintype from "../../components/constant/loginType.json";
import { countries } from "../../db/dataTypesData";
import CountryCodeDropdown from "../../components/CountryCodeDropdown/CountryCodeDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  loginDoctorViaEmailOTP,
  loginDoctorViaPhoneOTP,
  loginPatientViaEmailOTP,
  loginPatientViaPhoneOTP,
} from "../../redux/action";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import { USER_TYPE } from "../../components/constant/authEnums";
import FullWidthTextField2 from "../../components/TextField2/FullWidthTextField";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";

const OtpLoginPage = () => {
  const [user, setUser] = useState({
    phone_number: "",
    country_code: "91",
    contact_email: "",
  });
  const userType = window.localStorage.getItem(USER_TYPE);
  const [loginType, setLoginType] = useState("Email");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (user?.country_code !== "91") {
      toast(
        SuccessMsg(
          "Mobile OTP is supported only in India. International users will receive the OTP through Email"
        ),
        {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        }
      );
    }
  }, [user?.country_code]);
  const handleUserDetail = (keyName, keyValue) => {
    let update = { ...user };
    if (keyName === "login_type") {
      setLoginType(keyValue);
    }
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    update[keyName] = keyValue;
    setUser(update);
  };
  const handleEmailValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.contact_email) {
      isValid = false;
      errors["contact_email"] = "Contact email cannot be blank";
    }

    return {
      isValid,
      errors,
    };
  };
  const handlePhoneValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.phone_number) {
      isValid = false;
      errors["phone_number"] = "Phone number cannot be blank";
    }
    if (!user?.country_code) {
      isValid = false;
      errors["country_code"] = "Country code cannot be blank";
    }
    return {
      isValid,
      errors,
    };
  };
  const handleOtpLogin = () => {
    if (loginType === "Email") {
      const check = handleEmailValidation(user);
      if (check.isValid) {
        if (userType === "doctor") {
          dispatch(
            loginDoctorViaEmailOTP({ email: user?.contact_email }, navigate)
          );
        } else if (userType === "patient") {
          dispatch(
            loginPatientViaEmailOTP({ email: user?.contact_email }, navigate)
          );
        }
      } else {
        setErrors(check.errors);
      }
    } else {
      const checks = handlePhoneValidation(user);
      if (checks.isValid) {
        if (userType === "doctor") {
          dispatch(
            loginDoctorViaPhoneOTP(
              {
                country_code: user?.country_code,
                phone_number: user?.phone_number,
              },
              navigate
            )
          );
        } else if (userType === "patient") {
          dispatch(
            loginPatientViaPhoneOTP(
              {
                country_code: user?.country_code,
                phone_number: user?.phone_number,
              },
              navigate
            )
          );
        }
      } else {
        setErrors(checks.errors);
      }
    }
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error my-1">{errors[keyName]}</p>
    ) : null;
  };
  return (
    <>
      <div className={`${styles.login_flex}`}>
        <div className={`${styles.login_flex_child9}`}>
          <div className={`${styles.login_flex_child_sub7}`}>
            <img src="images/quorit_logo_img.png" alt="" />
            <h1>LOGIN</h1>
            <p>Log in with Mobile Number/Email ID</p>
          </div>
          <ul className={`${styles.radio_container}`}>
            {logintype.map((item) => (
              <RadioButton
                key={item.label}
                checked={item.label === loginType}
                keyName={"login_type"}
                value={item.label}
                label={item.label}
                labelId={item.label}
                patientDetailHandler={handleUserDetail}
              />
            ))}
          </ul>
          <form className={`${styles.login_flex2}`}>
            {loginType === "Mobile" ? (
              <div className={`${styles.login_flex_phone}`}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className={styles.phone_code}>
                    <CountryCodeDropdown
                      countries={countries}
                      textLabel={"Code*"}
                      id={"code7"}
                      keyName={"country_code"}
                      handleUserDetail={handleUserDetail}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <FullWidthTextField2
                      key={"27s"}
                      type="text"
                      placeholder="Enter Mobile Number*"
                      value={user?.phone_number}
                      onChange={(e) =>
                        handleUserDetail("phone_number", e.target.value)
                      }
                    />
                  </div>
                </div>
                {renderError("phone_number")}
              </div>
            ) : (
              <div className={`${styles.login_flex_child_sub2}`}>
                <div className={styles.login_email}>
                  <img src="email-outline.svg" width={50} height={50} alt="" />
                  <FullWidthTextField
                    key={"y67"}
                    type="text"
                    placeholder="Email Id"
                    value={user?.contact_email}
                    onChange={(e) =>
                      handleUserDetail("contact_email", e.target.value)
                    }
                  />
                </div>
                {renderError("contact_email")}
              </div>
            )}
            <div className={`${styles.login_flex_child_subtext}`}>
              <p>
                we will send you an <b>one Time Password</b> on this number
              </p>
            </div>
          </form>
          <button
            className={`${styles.login_flex_child_btn}`}
            onClick={handleOtpLogin}
          >
            Get OTP
          </button>
          {userType === "patient" ? null : (
            <Link className={`${styles.otp_login}`} to={"/login"}>
              <h3>Login with password</h3>
            </Link>
          )}
          <Link className={`${styles.otp_login2}`} to={"/registration-page"}>
            <span className={styles.reg_account}>Don't have an account ?</span>
            <span className={styles.reg_text}>Register</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default OtpLoginPage;
