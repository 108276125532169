import styles from "./patientsearchpage.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Header from "../Header/Header";
import RadioButton from "../../components/RadioButton5/RadioButton";
import patient from "../../components/constant/patient.json";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../redux/selector";
import callApi from "../../util/apiCaller";
import { Country, State, City } from "country-state-city";
import _ from "lodash";
import { quoritLogo } from "../../components/images";
import { useCallback } from "react";
import { DOCTOR_ID, PATIENT_ID } from "../../components/constant/authEnums";
import AutoCompleteField from "../../components/AutoCompleteField/AutoCompleteFieldLightTheme";
import {
  addPatientDetail,
  fetchDoctorDetail,
  setPatientId,
} from "../../redux/action";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { SuccessMsg } from "../../util/Message";
import "react-datepicker/dist/react-datepicker.css";
import HeaderV2 from "../../components/Header/HeaderV2";

//import { Search } from "@mui/icons-material";

const PatientSearchPage = () => {
  let location = useLocation();
  useEffect(() => {
    setLoading(false);
  }, [location.pathname]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const doctorID = window.localStorage.getItem(DOCTOR_ID);
  const doctorId = useSelector((state) => getUserId(state));
  const [patientType, setPatientType] = useState(patient[0]?.value);
  const [patientList, setPatientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [code, setCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [patientDetail, setPatientDetail] = useState({});
  const [data, setData] = useState(false);
  const [search, setSearch] = useState({
    patient: "",
  });
  const [errors, setErrors] = useState({});
  const [bloodGroup, setBloodGroup] = useState([]);
  const [user, setUser] = useState({
    contact_name: "",
    user_type: "patient",
    contact_email: "",
    phone_number: "",
    country_code: "91",
    profile_image: "",
    country: "",
    code: "",
    state_code: "",
    dob: new Date(),
    age: "",
    address: "",
    city: "",
    state: "",
    blood_group: "",
    weight: "",
    height: "",
    business_id: "6597d3f68836f7c222e1a504",
  });

  useEffect(() => {
    let country = Country.getAllCountries().map((option) => {
      return option.name;
    });

    setCountry(country);
  }, []);
  useEffect(() => {
    let state = State.getStatesOfCountry(code).map((option) => {
      return option.name;
    });
    setState(state);
  }, [code]);
  useEffect(() => {
    // if(customer && customer._id) {
    //     navigate(consoleUrl);
    // }
    if ((code, stateCode)) {
      let cityList = City.getCitiesOfState(code, stateCode).map(
        (option) => option.name
      );

      setCity(cityList);
    }
  }, [code, stateCode]);

  const debouncedHandlePincode = useCallback(
    _.debounce((user) => {
      fetchByDoctorId(user);
    }, 700),
    []
  );
  const handleSearchPateint = (patient) => {
    debouncedHandlePincode(patient);
  };
  const fetchByDoctorId = (user) => {
    if (user) {
      callApi(`patient/doctor/${doctorID}/list`, "post", {
        pageNum: 1,
        pageSize: 50,
        filters: {
          qtext: user,
        },
      }).then((res) => {
        if (res?.status === "Success") {
          const { list } = res?.data;
          setPatientList([...list]);
        } else {
        }
      });
    }
  };
  const patientDataPopulate = (id) => {
    let arr = [...patientList];
    for (let item of arr) {
      if (item?._id === id) {
        let data = item?.patient;
        setUser({
          _id: data?._id,
          contact_name: data?.contact_name,
          user_type: "patient",
          contact_email: data?.contact_email,
          phone_number: data?.phone_number,
          country_code: "91",
          profile_image: data?.profile_image,
          country: data?.country,
          state_code: data?.state_code,
          dob: data?.dob,
          age: data?.age,
          address: data?.address,
          city: data?.city,
          state: data?.state,
          blood_group: data?.blood_group,
          weight: data?.weight,
          height: data?.height,
          business_id: "6597d3f68836f7c222e1a504",
        });
        setStateCode(data?.state_code);
        setCode(data?.code);
        window.localStorage.setItem(PATIENT_ID, item?.patient?._id);
        setData(true);
      }
    }
  };
  useEffect(() => {
    const fetchContent = async (doctorId) => {
      try {
        const res = await callApi(`patient/doctor/${doctorId}/list`, "post", {
          pageNum: 1,
          pageSize: 50,
          filters: {},
        });

        if (res && res?.status === "Success") {
          const { list } = res?.data;
          setPatientList(list);
        } else {
        }
      } catch (err) {}
    };
    fetchContent(doctorID);
  }, [doctorID]);

  const patientDetailHandler = (keyName, keyValue) => {
    let update = { ...search };
    if (keyName === "patient") {
      handleSearchPateint(keyValue);
      update[keyName] = keyValue;
    } else {
      if (keyValue === patient[0]?.value) {
        setPatientType(keyValue);

        setData(false);
      } else {
        setData(true);
        setUser({
          contact_name: "",
          user_type: "patient",
          contact_email: "",
          phone_number: "",
          country_code: "91",
          profile_image: "",
          state_code: "",
          country: "",
          code: "",
          dob: "",
          age: "",
          address: "",
          city: "",
          state: "",
          blood_group: "",
          weight: "",
          height: "",
          business_id: "6597d3f68836f7c222e1a504",
        });
        setPatientType(keyValue);
      }
    }
    setSearch(update);
  };

  useEffect(() => {
    fetchContent("blood-group-types");
  }, []);
  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  async function fetchContent(code) {
    try {
      const res = await callApi(`content/code/${code}`, "get");

      if (res && res?.status === "Success") {
        const { params } = res?.data?.content;

        let param = params?.map((options) => options?.label);
        setBloodGroup([...param]);
      } else {
      }
    } catch (err) {}
  }
  const addPatientHandler = (keyName, keyValue) => {
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    let update = { ...user };
    if (keyName === "country") {
      Country.getAllCountries().forEach((option) => {
        if (option.name === keyValue) {
          if (option.isoCode) {
            setCode(option.isoCode);
            update["code"] = option.isoCode;
          }
        }
      });
    }
    if (keyName === "state") {
      State.getStatesOfCountry(code).forEach((option) => {
        if (option.name === keyValue) {
          if (option.isoCode) {
            setStateCode(option.isoCode);
            update["state_code"] = option.isoCode;
          }
        }
      });
    }
    update[keyName] = keyValue;

    setUser(update);
  };
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Contact name cannot be blank";
    }
    // if (!user?.country_code) {
    //     isValid = false;
    //     errors["country_code"] = "Country code cannot be blank"
    // }
    if (!user?.phone_number) {
      isValid = false;
      errors["phone_number"] = "Phone number cannot be blank";
    }
    if (!user?.dob) {
      isValid = false;
      errors["dob"] = "Dob cannot be blank";
    }
    // if (!user?.age) {
    //     isValid = false;
    //     errors["age"] = "Age cannot be blank"
    // }
    // if (!user?.phone_number) {
    //     isValid = false;
    //     errors["phone_number"] = "Phone number cannot be blank"
    // }
    // if (!user?.phone_number) {
    //     isValid = false;
    //     errors["profile_image"] = "Profile image cannot be blank"
    // }
    if (!user?.city) {
      isValid = false;
      errors["city"] = "City cannot be blank";
    }
    if (!user?.state) {
      isValid = false;
      errors["state"] = "State cannot be blank";
    }
    if (!user?.address) {
      isValid = false;
      errors["address"] = "Address cannot be blank";
    }

    return {
      isValid,
      errors,
    };
  };

  const handleApi = async () => {
    //debugger;
    const update = {};
    update.contact_name = _.startCase(user?.contact_name);
    if (user?._id) {
      navigate("/add-new-case");
    } else {
      const check = handleValidation(user);
      if (check.isValid) {
        setLoading(true);
        const res = await callApi(`patient/new-patient`, "post", {
          patient: { ...user, ...update },
        });

        if (res && res?.status === "Success") {
          setLoading(false);
          const { patient } = res?.data;
          window.localStorage.setItem(PATIENT_ID, patient?._id);
          navigate("/add-new-case");
          toast(SuccessMsg("New patient added successfully"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        } else {
          toast(SuccessMsg("Something went wrong"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
        setErrors({});
      } else {
        setErrors(check.errors);
      }
    }
  };

  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error">{errors[keyName]}</p>
    ) : null;
  };

  return (
    <>
      <HeaderV2 />
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Container className={`${styles.patient_flex}`}>
        <div className={styles.heading_text}>
          <h2>Patient Details</h2>
        </div>

        <ul className={`${styles.radio_container}`}>
          {patient?.map((item) => (
            <RadioButton
              key={item.label}
              checked={item.label === patientType}
              keyName={"patient_type"}
              value={item.value}
              label={item.label}
              labelId={item.label}
              patientDetailHandler={patientDetailHandler}
            />
          ))}
        </ul>
        {data === false ? (
          <>
            {" "}
            <div className={`${styles.patient_flex_input}`}>
              <input
                placeholder="Search"
                value={search?.patient}
                onChange={(e) =>
                  patientDetailHandler("patient", e.target.value)
                }
              />
              <img src="images/Search.png" alt="" />
            </div>
            <div style={{ margin: "3.125em 0", cursor: "pointer" }}>
              {patientList?.map((item) => (
                <div
                  className={`${styles.patient_list}`}
                  key={item?._id}
                  onClick={(e) => patientDataPopulate(item?._id)}
                >
                  <div className={`${styles.cases_flex}`}>
                    <div className={`${styles.cases_flex_child1}`}>
                      <span style={{ marginRight: "10px" }}>
                        Patient ID: {item?.patient?.patient_num}
                      </span>
                    </div>
                    <div className={`${styles.cases_flex_child2}`}>
                      <div>
                        <h3>{item?.patient?.contact_name}</h3>

                        <p></p>
                        <h5>Phone Number: {item?.patient?.phone_number}</h5>
                      </div>
                      <div>Email Id: {item?.patient?.contact_email}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        {data ? (
          <>
            {" "}
            <Row className={`${styles.patient_flex_body}`}>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    Patient Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Patient Name*"
                    value={user?.contact_name}
                    onChange={(e) =>
                      addPatientHandler("contact_name", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("contact_name")}
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    Phone Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Phone Number*"
                    value={user?.phone_number}
                    onChange={(e) =>
                      addPatientHandler("phone_number", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("phone_number")}
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    DOB<span style={{ color: "red" }}>*</span>
                  </label>
                  <DatePicker
                    selected={user?.dob}
                    scrollableYearDropdown
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Date of birth"
                    onChange={(e) => addPatientHandler("dob", e)}
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>{renderError("dob")}</div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Age (years)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Age (years)"
                    value={user?.age}
                    onChange={(e) => addPatientHandler("age", e.target.value)}
                  />
                </div>

                <div className={`${styles.patient_flex_child}`}>
                  <label>Height (cm)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Height in cm"
                    value={user?.height}
                    onChange={(e) =>
                      addPatientHandler("height", e.target.value)
                    }
                  />
                </div>

                <div className={`${styles.patient_flex_child}`}>
                  <label>Weight (kg)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Weight in kg"
                    value={user?.weight}
                    onChange={(e) =>
                      addPatientHandler("weight", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    value={user?.contact_email}
                    onChange={(e) =>
                      addPatientHandler("contact_email", e.target.value)
                    }
                  />
                </div>

                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    Location<span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <input type="text"
                                class="form-control"
                                placeholder="City*"
                                value={user?.city}
                                onChange={(e) => addPatientHandler("city",e.target.value)}
                            /> */}
                  <div style={{ width: "100%", marginTop: "15px" }}>
                    <AutoCompleteField
                      textLabel={"Country"}
                      data={country}
                      id={"country112"}
                      value={user?.country}
                      keyName={"country"}
                      handleUserDetail={addPatientHandler}
                    />
                  </div>
                  <div style={{ width: "100%", marginTop: "15px" }}>
                    <AutoCompleteField
                      textLabel={"State"}
                      data={state}
                      id={"state152"}
                      value={user?.state}
                      keyName={"state"}
                      handleUserDetail={addPatientHandler}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "15px",
                    }}
                  >
                    <AutoCompleteField
                      textLabel={"City"}
                      data={city}
                      id={"city172"}
                      value={user?.city}
                      keyName={"city"}
                      handleUserDetail={addPatientHandler}
                    />
                  </div>
                </div>
                {/* <div style={{ "marginLeft": "40px" }}>{renderError("city")}</div>
                        <div className={`${styles.patient_flex_child}`}>
                            <label>State<span style={{ color: "red" }}>*</span></label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="State*"
                                value={user?.state}
                                onChange={(e) => addPatientHandler("state",e.target.value)}
                            />
                        </div>
                        <div style={{ "marginLeft": "40px" }}>{renderError("state")}</div> */}
                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    Address<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Address*"
                    value={user?.address}
                    onChange={(e) =>
                      addPatientHandler("address", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("address")}
                </div>
                <div className={`${styles.patient_flex_child2}`}>
                  <AutoCompleteField
                    textLabel={"Blood Group*"}
                    data={bloodGroup}
                    id={"blood142"}
                    value={user?.blood_group}
                    keyName={"blood_group"}
                    handleUserDetail={addPatientHandler}
                  />
                </div>
                {/* <div className={`${styles.patient_flex_child}`}>
                            <label>Referred Doctor</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Referred Doctor"
                                value={user?.referred_doctor}
                                onChange={(e) => addPatientHandler("referred_doctor",e.target.value)}
                            />
                        </div> */}
              </Col>
            </Row>
            <div className={`${styles.patient_flex_btn}`}>
              <button onClick={handleApi}>
                {user?._id ? "Add New Case" : "Add Patient"}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default PatientSearchPage;
