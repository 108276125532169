import { Container, Row, Col } from "react-bootstrap";
import styles from "./profilepage.module.scss";
import Header from "../Header/Header";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser, getUserId } from "../../redux/selector";
import { updateDoctorDetail, updatePatientDetail } from "../../redux/action";
import axios from "axios";
import { Link } from "react-router-dom";
import { callUploadApi } from "../../util/apiCaller";
import HeaderV2 from "../../components/Header/HeaderV2";
import { USER_TYPE } from "../../components/constant/authEnums";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import callApi from "../../util/apiCaller";

const API_URL = `https://apidev.docuses.in`;

const ProfilePage = () => {
  const doctor = useSelector((state) => getUser(state));
  const userId = useSelector((state) => getUserId(state));
  const [password, setPassword] = useState("");
  const userType = window.localStorage.getItem(USER_TYPE);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [user, setUser] = useState({
    contact_name: "",
    contact_email: "",
    country_code: "",
    phone_number: "",
    local_password: "",
    qualification: "",
    specialization: "",
    license_number: "",
    license_document: null,
    profile_image: null,
    hospital_address: "",
    hospital_name: "",
    age: "",
    address: "",
    country: "",
    city: "",
    state: "",
    blood_group: "",
  });
  useEffect(() => {
    fetchContent("blood-group-types");
  }, []);
  async function fetchContent(code) {
    try {
      const res = await callApi(`content/code/${code}`, "get");

      if (res && res?.status === "Success") {
        const { params } = res?.data?.content;

        let param = params?.map((options) => options?.label);
        setBloodGroup([...param]);
      } else {
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (doctor) {
      let str = "";
      str = doctor?.contact_name;
      setUser({
        contact_name: str,
        contact_email: doctor?.contact_email,
        country_code: "91",
        phone_number: doctor?.phone_number,
        local_password: doctor?.local_password,
        qualification: doctor?.qualification,
        specialization: doctor?.specialization,
        license_number: doctor?.license_number,
        license_document: doctor?.license_document,
        profile_image: doctor?.profile_image,
        hospital_address: doctor?.hospital_address,
        hospital_name: doctor?.hospital_name,
        age: doctor?.age,
        address: doctor?.address,
        country: doctor?.country,
        city: doctor?.city,
        state: doctor?.state,
        blood_group: doctor?.blood_group,
      });
    }
  }, [doctor]);
  const handleUserDetail = async (keyName, keyValue) => {
    let update = { ...user };
    if (keyName === "local_password") {
      setPassword(keyValue);
    }
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    update[keyName] = keyValue;
    if (keyName === "profile_image") {
      let data = new FormData();
      data.append("file", keyValue);

      let config = {
        method: "post",
        url: `media/upload`,
        data: data,
      };

      // let result = await axios.request(config);
      let result = await callUploadApi(`media/upload`, "post", data);
      //console.log(result);
      if (result?.data?.link) {
        update["profile_image"] = result?.data?.link;
      }
    }
    if (keyName === "license_document") {
      let data = new FormData();
      data.append("file", keyValue);

      let config = {
        method: "post",
        url: `${API_URL}/media/upload`,
        data: data,
      };

      let result = await callUploadApi(`media/upload`, "post", data);
      //console.log(result);
      if (result?.data?.link) {
        update["license_document"] = result?.data?.link;
      }
    }

    setUser(update);
  };
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Contact name cannot be blank";
    }
    if (!user?.country_code) {
      isValid = false;
      errors["country_code"] = "Country code cannot be blank";
    }
    if (!user?.phone_number) {
      isValid = false;
      errors["phone_number"] = "Phone number cannot be blank";
    }
    if (!user?.qualification) {
      isValid = false;
      errors["qualification"] = "Qualification cannot be blank";
    }
    if (!user?.specialization) {
      isValid = false;
      errors["specialization"] = "Specialization cannot be blank";
    }
    if (!user?.phone_number) {
      isValid = false;
      errors["phone_number"] = "Phone number cannot be blank";
    }
    if (!user?.phone_number) {
      isValid = false;
      errors["profile_image"] = "Profile image cannot be blank";
    }
    if (!user?.license_document) {
      isValid = false;
      errors["license_document"] = "Licence document cannot be blank";
    }
    if (!user?.license_number) {
      isValid = false;
      errors["license_number"] = "Licence number cannot be blank";
    }
    if (!user?.contact_email) {
      isValid = false;
      errors["contact_email"] = "Contact email cannot be blank";
    }
    if (!user?.hospital_name) {
      isValid = false;
      errors["hospital_name"] = "Hospital name cannot be blank";
    }
    if (!user?.hospital_address) {
      isValid = false;
      errors["hospital_address"] = "Hospital address cannot be blank";
    }
    if (!user?.local_password) {
      isValid = false;
      errors["local_password"] = "Password cannot be blank";
    } else if (user?.local_password && user?.local_password?.length < 8) {
      isValid = false;
      errors["local_password"] = "Password must be of atleast 8 character";
    }
    return {
      isValid,
      errors,
    };
  };

  const handleUpdateApi = () => {
    // const check = handleValidation(user)
    // if (check.isValid) {
    if (userType === "doctor") {
      dispatch(updateDoctorDetail(userId, user));
    } else if (userType === "patient") {
      dispatch(updatePatientDetail(user, userId));
    }
    // } else {
    //     setErrors(check.errors)
    // }
  };
  const handleRemove = () => {
    dispatch(updateDoctorDetail(userId, { profile_image: null }));
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error">{errors[keyName]}</p>
    ) : null;
  };
  return (
    <>
      <HeaderV2 />
      <Container className={`${styles.profile_flex}`}>
        <div className={`${styles.profile_heading}`}>
          <h1>Basic Profile Details</h1>
        </div>
        <Row className={`${styles.profile_flex_body}`}>
          <Col lg={6} className={`${styles.profile_flex_body_part}`}>
            <div className={`${styles.image_uploader}`}>
              <div className={`${styles.image_size}`}>
                <img
                  src={
                    user?.profile_image
                      ? user?.profile_image
                      : "images/Profile-Latest.svg"
                  }
                  width={100}
                  height={100}
                  alt=""
                />
              </div>
              <div className={`${styles.upload_flex}`}>
                <h3>Profile Pic</h3>
                <div className={`${styles.upload_flex_child}`}>
                  <span onClick={handleRemove} style={{ fontSize: "1.2em" }}>
                    Remove
                  </span>

                  <input
                    type="file"
                    hidden
                    id="uploadfile1"
                    name="upload-profile"
                    //value={user?.profile_image}
                    onChange={(e) =>
                      handleUserDetail("profile_image", e.target.files[0])
                    }
                  />
                  <label htmlFor="uploadfile1" style={{ fontSize: "1.2em" }}>
                    Upload
                  </label>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("profile_image")}
            </div>
            <label>Name</label>
            <div className={`${styles.profile_flex_child1}`}>
              <img src="images/Profile.svg" width={50} height={50} alt="" />
              <FullWidthTextField
                key={"pro1"}
                type="text"
                placeholder="Enter name"
                value={user?.contact_name}
                onChange={(e) =>
                  handleUserDetail("contact_name", e.target.value)
                }
              />
              {/* <input
                type="text"
                placeholder="Enter name"
                value={user?.contact_name}
                onChange={(e) =>
                  handleUserDetail("contact_name", e.target.value)
                }
              /> */}
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("contact_name")}
            </div>
            <label>Phone</label>
            <div className={`${styles.profile_flex_child1}`}>
              <img src="images/cellphone-iphone.png" alt="" />
              <FullWidthTextField
                key={"pro2"}
                type="text"
                placeholder="Phone number"
                value={user?.phone_number}
                onChange={(e) =>
                  handleUserDetail("phone_number", e.target.value)
                }
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("phone_number")}
            </div>
            {userType === "patient" ? (
              <>
                {" "}
                <label>Age</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img
                    src="images/calender_img.png"
                    width={60}
                    height={60}
                    alt="img"
                  />
                  <FullWidthTextField
                    key={"pro3"}
                    type="text"
                    placeholder="age"
                    value={user?.age}
                    onChange={(e) => handleUserDetail("age", e.target.value)}
                  />
                </div>
                <div className={`${styles.profile_flex_child5}`}>
                  <SelectDropdown
                    label={"Blood Group*"}
                    data={bloodGroup}
                    labelId={"blood132"}
                    value={user?.blood_group}
                    keyName={"blood_group"}
                    handleUserDetail={handleUserDetail}
                  />
                </div>
              </>
            ) : null}
            {userType === "patient" ? null : (
              <>
                <label>Change Password</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img src="images/lock.svg" alt="" />
                  <FullWidthTextField
                    key={"pro4"}
                    type="password"
                    placeholder="Change Password"
                    value={password}
                    onChange={(e) =>
                      handleUserDetail("local_password", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("local_password")}
                </div>
                <label>Qualification</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img src="images/Qualification.svg" alt="img" />
                  <FullWidthTextField
                    key={"pro5"}
                    type="text"
                    placeholder="Qualification"
                    value={user?.qualification}
                    onChange={(e) =>
                      handleUserDetail("qualification", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("qualification")}
                </div>
                <label>Specialization</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img
                    src="images/Specialization(1).svg"
                    width={60}
                    height={60}
                    alt=""
                  />
                  <FullWidthTextField
                    key={"pro6"}
                    type="text"
                    placeholder="Specialization"
                    value={user?.specialization}
                    onChange={(e) =>
                      handleUserDetail("specialization", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("specialization")}
                </div>
              </>
            )}
          </Col>
          <Col lg={6} className={`${styles.profile_flex_body_part}`}>
            <label>Email</label>
            <div className={`${styles.profile_flex_child1}`}>
              <img src="email-outline.svg" alt="" />
              <FullWidthTextField
                key={"pro7"}
                type="text"
                placeholder="Enter email"
                value={user?.contact_email}
                onChange={(e) =>
                  handleUserDetail("contact_email", e.target.value)
                }
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("contact_email")}
            </div>
            {userType === "patient" ? null : (
              <>
                <label>Licence No.</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img src="images/license.svg" alt="" />
                  <FullWidthTextField
                    key={"pro8"}
                    type="text"
                    placeholder="License No."
                    value={user?.license_number}
                    onChange={(e) =>
                      handleUserDetail("license_number", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("license_number")}
                </div>

                <div className={`${styles.profile_flex_child2}`}>
                  <input
                    type="file"
                    hidden
                    id="licence23"
                    name="license-document"
                    //value={user?.license_document}
                    onChange={(e) =>
                      handleUserDetail("license_document", e.target.files[0])
                    }
                  />
                  <label htmlFor="licence23">
                    <img
                      src="images/upload-icon-grey.svg"
                      alt="license_upload"
                    />
                    <span style={{ marginLeft: "10px" }}>Upload license</span>
                  </label>
                </div>
                <div style={{ cursor: "pointer", paddingBottom: "5px" }}>
                  {user?.license_document ? (
                    <img
                      src={user?.license_document}
                      width={150}
                      height={100}
                      title="document"
                      style={{
                        padding: "10px 0",
                        border: "1px solid black",
                        cursor: "pointer",
                      }}
                    ></img>
                  ) : (
                    renderError("license_document")
                  )}
                </div>
                <label>Hospital Name</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img src="images/hospital.svg" alt="" />
                  <FullWidthTextField
                    key={"pro9"}
                    type="text"
                    placeholder="Hospital Name"
                    value={user?.hospital_name}
                    onChange={(e) =>
                      handleUserDetail("hospital_name", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("hospital_name")}
                </div>
                <label>Hospital Address</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img src="images/Hospital Address.svg" alt="" />
                  <FullWidthTextField
                    key={"pro10"}
                    type="text"
                    placeholder="Hospital Address"
                    value={user?.hospital_address}
                    onChange={(e) =>
                      handleUserDetail("hospital_address", e.target.value)
                    }
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {renderError("hospital_address")}
                </div>
              </>
            )}

            {userType === "patient" ? (
              <>
                {" "}
                <label>Address</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img
                    src="images/Hospital Address.svg"
                    alt=""
                    style={{ color: "red" }}
                  />
                  <FullWidthTextField
                    key={"pro11"}
                    type="text"
                    placeholder="Address"
                    value={user?.address}
                    onChange={(e) =>
                      handleUserDetail("address", e.target.value)
                    }
                  />
                </div>
                <label>City</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img
                    src="images/hospital.svg"
                    alt=""
                    style={{ color: "red" }}
                  />
                  <FullWidthTextField
                    key={"pro12"}
                    type="text"
                    placeholder="city"
                    value={user?.city}
                    onChange={(e) => handleUserDetail("city", e.target.value)}
                  />
                </div>
                <label>State</label>
                <div className={`${styles.profile_flex_child1}`}>
                  <img src="images/Hospital Address.svg" alt="" />
                  <FullWidthTextField
                    key={"pro13"}
                    type="text"
                    placeholder="state"
                    value={user?.state}
                    onChange={(e) => handleUserDetail("state", e.target.value)}
                  />
                </div>
              </>
            ) : null}
          </Col>
        </Row>
        <div className={`${styles.profile_flex_btn}`}>
          <button onClick={handleUpdateApi}>SAVE</button>
        </div>
      </Container>
    </>
  );
};

export default ProfilePage;
