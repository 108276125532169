import styles from "./casespage.module.scss";
import RadioButton from "../../components/RadioButton5/RadioButton";
import differentCases from "../../components/constant/differentcases.json";
import patientCases from "../../components/constant/patientcases.json";
//import cases from "../../components/constant/differentcases1.json";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header/Header";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import callApi from "../../util/apiCaller";
import {
  getCompleted,
  getConsult,
  getUserId,
  getOngoing,
  getReffered,
  getCases,
  getAcceptCases,
} from "../../redux/selector";
import {
  CASE_ID,
  DOCTOR_ID,
  PATIENT_ID,
  REFFERED_FROM,
  PATIENTS_DOCTOR,
} from "../../components/constant/authEnums";
import { logDOM } from "@testing-library/react";
import {
  acceptRefferedCases,
  rejectRefferedCases,
  setCaseDetail,
  setCaseId,
  setPatientDetail,
  setPatientId,
  setRefferedDoctor,
} from "../../redux/action";
import HeaderV2 from "../../components/Header/HeaderV2";
import _, { create } from "lodash";
import { USER_TYPE } from "../../components/constant/authEnums";
import SharedWith from "./SharedWith/SharedWith";

const CasesPage = () => {
  const [modal, setModal] = useState(false);
  const [casesType, setCasesType] = useState(differentCases[0]?.label);
  const doctorId = window.localStorage.getItem(DOCTOR_ID);
  const [ongoing, setOngoing] = useState(differentCases[0]?.label);
  const [loading, setLoading] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [caseRefferedId, setCaseRefferedId] = useState([]);
  const [consultCase, setConsultCase] = useState(false);
  const [completedCase, setCompletedCase] = useState(false);
  const navigate = useNavigate();
  const [doctorCount, setDoctorCount] = useState([]);
  const [counterDoctor, setCounterDocotor] = useState([]);
  const [referedCase, setReferedCase] = useState(false);
  const [cases, setCases] = useState([]);
  const [caseAccept, setCaseAccept] = useState([]);
  const [patientDet, setPatientDet] = useState([]);
  const [negate, setNegate] = useState(false);
  const [data, setData] = useState(differentCases[0]?.display_name);
  const [patientList, setPatientList] = useState([]);
  const [referredDoctorToMe, setReferredDoctorToMe] = useState([]);
  const [caseUser, setCaseUser] = useState([]);
  const [patientListConsult, setPatientListConsult] = useState([]);
  const [casesConsult, setCasesConsult] = useState([]);
  const dispatch = useDispatch();
  const consult = useSelector((state) => getConsult(state));
  const completed = useSelector((state) => getCompleted(state));
  const going = useSelector((state) => getOngoing(state));
  const reffered = useSelector((state) => getReffered(state));
  const dataCases = useSelector((state) => getCases(state));
  const [acceptCase, setAcceptCase] = useState(false);

  const userType = window.localStorage.getItem(USER_TYPE);
  const [referredDoctorList, setReferredDoctorList] = useState([]);
  useEffect(() => {
    if (userType === "patient") {
      setCaseUser(patientCases);

      async function fetchContent(ongoing) {
        if (negate) {
          try {
            setLoading(true);
            const res = await callApi(`case/list`, "post", {
              pageNum: 1,
              pageSize: 50,
              filters: {
                case_status: ongoing,
                patient_id: doctorId,
                is_visible_to_patient: true,
              },
            });

            if (res && res?.status === "Success") {
              const { list } = res?.data;
              setLoading(false);

              setCases(list);
              let ress = list?.map((item) => item?.patient_id);

              let arr1 = [];
              for (let item of ress) {
                const result = await callApi(`patient/${item}`, "get");
                if (result && result?.status === "Success") {
                  arr1?.push(result?.data?.patient);
                }
              }
              setPatientList(arr1);
            } else {
            }
          } catch (err) {}
        }
      }
      fetchContent(ongoing);
    } else if (userType === "doctor") {
      setCaseUser(differentCases);
      async function fetchContent(ongoing) {
        if (negate) {
          try {
            setLoading(true);
            const res = await callApi(`case/list`, "post", {
              pageNum: 1,
              pageSize: 50,
              filters: {
                case_status: ongoing,
                doctor_id: doctorId,
                is_referred_case: false,
              },
            });

            if (res && res?.status === "Success") {
              const { list } = res?.data;
              setLoading(false);

              setCases(list);
              let ress = list?.map((item) => item?.patient_id);

              let arr1 = [];
              for (let item of ress) {
                const result = await callApi(`patient/${item}`, "get");
                if (result && result?.status === "Success") {
                  arr1.push(result?.data?.patient);
                }
              }
              setPatientList(arr1);
            } else {
            }
          } catch (err) {}
        }
      }
      fetchContent(ongoing);
    }
  }, [userType, negate]);

  useEffect(() => {
    if (dataCases) {
      setCasesType(dataCases);
    }
  }, [dataCases]);
  useEffect(() => {
    if (reffered) {
      setReferedCase(reffered);
    }
  }, [reffered]);
  useEffect(() => {
    if (going) {
      setNegate(true);
    }
  }, [going]);
  useEffect(() => {
    if (consult) {
      setConsultCase(consult);
    }
  }, [consult]);
  useEffect(() => {
    if (completed) {
      setCompletedCase(completed);
    }
  }, [completed]);

  useEffect(() => {
    if (referedCase) {
      async function fetchContent() {
        try {
          setLoading(true);
          const res = await callApi(`case/referred-to-me`, "post", {
            pageNum: 1,
            pageSize: 20,
            filters: {
              referred_to: doctorId,
            },
          });

          if (res && res?.status === "Success") {
            const { cases } = res?.data;
            setLoading(false);
            console.log(cases);
            setCaseAccept(cases);
            let ress = cases?.map((item) => item?.patient_id);
            let docotorList = [];
            for (let item of cases) {
              const casesdocs = await callApi(`case/${item?._id}`, "get");
              if (casesdocs && casesdocs?.status === "Success") {
                const doc = await callApi(
                  `doctor/${casesdocs?.data?.case?.doctor_id}`,
                  "get"
                );
                if (doc && doc?.status === "Success") {
                  docotorList.push(doc?.data?.doctor?.contact_name);
                }
              }
            }
            setReferredDoctorToMe(docotorList);
            let arr1 = [];
            for (let item of ress) {
              const result = await callApi(`patient/${item}`, "get");
              if (result && result?.status === "Success") {
                arr1.push(result?.data?.patient);
              }
            }
            setPatientDet(arr1);
          } else {
          }
        } catch (err) {}
      }

      fetchContent();
    }
  }, [referedCase, acceptCase]);
  useEffect(() => {
    if (referedCase) {
      async function fetchContent() {
        try {
          //debugger;
          setLoading(true);
          const ress = await callApi(`case/open-referred-case-list`, "post", {
            pageNum: 1,
            pageSize: 20,
            filters: {
              referred_to: doctorId,
            },
          });

          if (ress && ress?.status === "Success") {
            const { cases } = ress?.data;

            setLoading(false);

            setCases([]);
            setPatientList([]);
            let res = cases?.map((item) => item?.case_id);
            let refferId = cases?.map((item) => item?._id);
            setCaseRefferedId(refferId);
            let docotorList = [];
            for (let item of cases) {
              const doc = await callApi(`doctor/${item?.referred_by}`, "get");
              if (doc && doc?.status === "Success") {
                docotorList.push(doc?.data?.doctor?.contact_name);
              }
            }
            setReferredDoctorList(docotorList);
            let arr1 = [];

            let arrs = [];
            for (let item of res) {
              ///let pesres = []
              const result = await callApi(`case/${item}`, "get");
              if (result && result?.status === "Success") {
                arr1.push(result?.data?.case);

                const pes = await callApi(
                  `patient/${result?.data?.case?.patient_id}`,
                  "get"
                );
                if (pes && pes?.status === "Success") {
                  arrs.push(pes?.data?.patient);
                }
              }
              // arrs.push([pesres])
            }

            setPatientList(arrs);

            setCases(arr1);
          } else {
          }
        } catch (err) {}
      }
      fetchContent();
    }
  }, [referedCase, acceptCase]);

  useEffect(() => {
    if (consultCase) {
      async function fetchContent() {
        try {
          setLoading(true);
          const res = await callApi(`case/list`, "post", {
            pageNum: 1,
            pageSize: 50,
            filters: {
              is_referred_case: true,
              doctor_id: doctorId,
            },
          });

          if (res && res?.status === "Success") {
            const { list } = res?.data;
            setLoading(false);

            setCasesConsult(list);
            let ress = list?.map((item) => item?.patient_id);
            //console.log(ress);
            let docotorList = [];
            let count = [];
            for (let item of list) {
              count.push(item?.referred_doctor_list);
              const doc = await callApi(
                `doctor/${
                  item?.referred_doctor_list?.[
                    item.referred_doctor_list.length - 1
                  ]
                }`,
                "get"
              );
              if (doc && doc?.status === "Success") {
                docotorList.push(doc?.data?.doctor?.contact_name);
              }
            }
            setDoctorCount(count);
            setReferredDoctorList(docotorList);
            let arr1 = [];
            for (let item of ress) {
              const result = await callApi(`patient/${item}`, "get");
              if (result && result?.status === "Success") {
                arr1.push(result?.data?.patient);
              }
            }
            setPatientListConsult(arr1);
          } else {
          }
        } catch (err) {}
      }
      fetchContent();
    }
  }, [consultCase]);
  useEffect(() => {
    if (userType === "doctor") {
      if (negate) {
        async function fetchContent() {
          try {
            setLoading(true);
            const res = await callApi(`case/list`, "post", {
              pageNum: 1,
              pageSize: 50,
              filters: {
                case_status: ongoing,
                is_referred_case: true,
                doctor_id: doctorId,
              },
            });

            if (res && res?.status === "Success") {
              const { list } = res?.data;
              setLoading(false);

              setCasesConsult(list);
              let ress = list?.map((item) => item?.patient_id);
              //console.log(ress);
              let docotorList = [];
              let count = [];
              for (let item of list) {
                count.push(item?.referred_doctor_list);
                const doc = await callApi(
                  `doctor/${
                    item?.referred_doctor_list?.[
                      item.referred_doctor_list.length - 1
                    ]
                  }`,
                  "get"
                );
                if (doc && doc?.status === "Success") {
                  docotorList.push(doc?.data?.doctor?.contact_name);
                }
              }
              setDoctorCount(count);
              setReferredDoctorList(docotorList);
              let arr1 = [];
              for (let item of ress) {
                const result = await callApi(`patient/${item}`, "get");
                if (result && result?.status === "Success") {
                  arr1.push(result?.data?.patient);
                }
              }
              setPatientListConsult(arr1);
            } else {
            }
          } catch (err) {}
        }
        fetchContent();
      }
    }
  }, [negate]);
  useEffect(() => {
    if (userType === "patient") {
      if (completedCase) {
        async function fetchContent() {
          try {
            setLoading(true);
            const res = await callApi(`case/list`, "post", {
              pageNum: 1,
              pageSize: 50,
              filters: {
                case_status: "Completed",
                patient_id: doctorId,
              },
            });

            if (res && res?.status === "Success") {
              const { list } = res?.data;
              setLoading(false);
              // let arr = [...cases]
              // arr = [...list]
              setCases(list);
              let ress = list?.map((item) => item?.patient_id);
              //console.log(ress);
              let arr1 = [];
              for (let item of ress) {
                const result = await callApi(`patient/${item}`, "get");
                if (result && result?.status === "Success") {
                  arr1?.push(result?.data?.patient);
                }
              }
              setPatientList(arr1);
            } else {
            }
          } catch (err) {}
        }
        fetchContent();
      }
    }
  }, [completedCase]);
  useEffect(() => {
    if (userType === "doctor") {
      if (completedCase) {
        async function fetchContent() {
          try {
            setLoading(true);
            const res = await callApi(`case/list`, "post", {
              pageNum: 1,
              pageSize: 50,
              filters: {
                case_status: "Completed",
                doctor_id: doctorId,
              },
            });

            if (res && res?.status === "Success") {
              const { list } = res?.data;
              setLoading(false);
              // let arr = [...cases]
              // arr = [...list]
              setCases(list);
              let ress = list?.map((item) => item?.patient_id);
              //console.log(ress);
              let arr1 = [];
              for (let item of ress) {
                const result = await callApi(`patient/${item}`, "get");
                if (result && result?.status === "Success") {
                  arr1.push(result?.data?.patient);
                }
              }
              setPatientList(arr1);
            } else {
            }
          } catch (err) {}
        }
        fetchContent();
      }
    }
  }, [completedCase]);

  const casesHandler = async (keyName, keyValue) => {
    if (keyValue === differentCases[0].label) {
      setCasesType(keyValue);
      setReferedCase(false);
      setNegate(true);
      setConsultCase(false);
      setCompletedCase(false);
      setData(differentCases[0].display_name);
    } else if (keyValue === differentCases[1].label) {
      setCasesType(keyValue);
      setReferedCase(true);
      setNegate(false);
      setConsultCase(false);
      setCompletedCase(false);
      setData(differentCases[1].display_name);
    } else if (keyValue === differentCases[2].label) {
      setCasesType(keyValue);
      setReferedCase(false);
      setNegate(false);
      setConsultCase(true);
      setCompletedCase(false);
      setData(differentCases[2].display_name);
    } else if (keyValue === differentCases[3].label) {
      setCasesType(keyValue);
      setNegate(false);
      setReferedCase(false);
      setConsultCase(false);
      setCompletedCase(true);
      setData(differentCases[3].display_name);
    }
  };
  const handleAccted = (case_id, reffere_id, accept) => {
    acceptRefferedCases(case_id, reffere_id, "accept").then((res) => {
      setAcceptCase(!acceptCase);
    });
  };
  const handleRejectCases = (case_id, reffere_id, reject) => {
    acceptRefferedCases(case_id, reffere_id, "reject").then((res) => {
      setAcceptCase(!acceptCase);
    });
  };

  const handleAcctedCases = (cases1, patients1) => {
    let arr = [...caseAccept];
    let arr2 = [...patientDet];
    let update1 = {};
    let update2 = {};
    let caseid = "";
    let patientid = "";
    let doctorId = "";
    for (let item1 of arr) {
      if (item1.case_number === cases1) {
        update1 = { ...item1 };
        caseid = item1?._id;
        doctorId = item1?.doctor_id;
      }
    }
    for (let item2 of arr2) {
      if (item2.patient_num === patients1) {
        update2 = { ...item2 };
        patientid = item2?._id;
      }
    }
    window.localStorage.setItem(PATIENT_ID, patientid);
    window.localStorage.setItem(CASE_ID, caseid);
    window.localStorage.setItem(PATIENTS_DOCTOR, doctorId);
    // dispatch(setCaseId(caseid));
    // dispatch(setPatientId(patientid));
    // dispatch(setCaseDetail(update1));
    // dispatch(setPatientDetail(update2));
    if (window.localStorage.getItem(CASE_ID)) {
      navigate("/edit-session");
    }
    dispatch(setRefferedDoctor(true));
  };
  const handleCasesConsult = (cases1, patients1) => {
    let arr = [...casesConsult];
    let arr2 = [...patientListConsult];
    let update1 = {};
    let update2 = {};
    let caseid = "";
    let patientid = "";
    let doctorId = "";
    for (let item1 of arr) {
      if (item1.case_number === cases1) {
        update1 = { ...item1 };
        caseid = item1?._id;
        doctorId = item1?.doctor_id;
      }
    }
    for (let item2 of arr2) {
      if (item2.patient_num === patients1) {
        update2 = { ...item2 };
        patientid = item2?._id;
      }
    }
    window.localStorage.setItem(PATIENT_ID, patientid);
    window.localStorage.setItem(CASE_ID, caseid);
    window.localStorage.setItem(PATIENTS_DOCTOR, doctorId);
    // dispatch(setCaseId(caseid));
    // dispatch(setPatientId(patientid));
    // dispatch(setCaseDetail(update1));
    // dispatch(setPatientDetail(update2));
    dispatch(setRefferedDoctor(false));
    if (window.localStorage.getItem(CASE_ID)) {
      navigate("/edit-session");
    }
  };

  const handleEditSession = (cases1, patients1) => {
    let arr = [...cases];
    let arr2 = [...patientList];
    let update1 = {};
    let update2 = {};
    let caseid = "";
    let patientid = "";
    let doctorId = "";
    for (let item1 of arr) {
      if (item1.case_number === cases1) {
        update1 = { ...item1 };
        caseid = item1?._id;
        doctorId = item1?.doctor_id;
      }
    }
    for (let item2 of arr2) {
      if (item2.patient_num === patients1) {
        update2 = { ...item2 };
        patientid = item2?._id;
      }
    }
    window.localStorage.setItem(PATIENT_ID, patientid);
    window.localStorage.setItem(CASE_ID, caseid);
    window.localStorage.setItem(PATIENTS_DOCTOR, doctorId);
    // dispatch(setCaseId(caseid));
    // dispatch(setPatientId(patientid));
    // dispatch(setCaseDetail(update1));
    // dispatch(setPatientDetail(update2));
    dispatch(setRefferedDoctor(false));
    if (window.localStorage.getItem(CASE_ID)) {
      navigate("/edit-session");
    }
  };

  return (
    <>
      <HeaderV2 />
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Container className={`${styles.case_flex}`}>
        <ul className={`${styles.radio_container}`}>
          {caseUser?.map((item) => (
            <RadioButton
              key={item.label}
              checked={item.label === casesType}
              keyName={"case_type"}
              value={item.label}
              label={item.display_name}
              labelId={item.label}
              patientDetailHandler={casesHandler}
            />
          ))}
        </ul>

        <h1 className={styles.text_margin}>{data}</h1>
        <Row>
          {referedCase
            ? caseAccept?.map((item, i) => (
                <Col lg={4}>
                  <div
                    className={`${styles.cases_flex}`}
                    key={item?._id}
                    onClick={() =>
                      handleAcctedCases(
                        item?.case_number,
                        patientDet[i]?.patient_num
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className={`${styles.cases_flex_child1}`}>
                      <span style={{ marginRight: "10px" }}>
                        Patient ID: {patientDet[i]?.patient_num}
                      </span>
                      <span>Case No.: {item?.case_number}</span>
                    </div>
                    <div className={`${styles.cases_flex_child2}`}>
                      <div>
                        <h3>{patientDet[i]?.contact_name}</h3>

                        <p>{patientDet[i]?.city}</p>
                        <h5>
                          First Visit:
                          {new Date(item?.created_at).toLocaleDateString()}
                        </h5>
                        <h5>
                          Last Checked At:{" "}
                          {new Date(item?.updated_at).toLocaleDateString()}
                        </h5>
                      </div>
                      <div>
                        <img
                          src={item?.latest_session_image_id?.url}
                          width={90}
                          height={90}
                          style={{ borderRadius: "10px" }}
                          alt="wound_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.case_flex_refered}>
                    <p>Shared by {referredDoctorToMe[i]}</p>
                  </div>
                </Col>
              ))
            : null}
          {consultCase
            ? casesConsult?.map((item, i) => (
                <Col lg={4}>
                  <div
                    className={`${styles.cases_flex}`}
                    key={item?._id}
                    onClick={() =>
                      handleCasesConsult(
                        item?.case_number,
                        patientListConsult[i]?.patient_num
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className={`${styles.cases_flex_child1}`}>
                      <span style={{ marginRight: "10px" }}>
                        Patient ID: {patientListConsult[i]?.patient_num}
                      </span>
                      <span>Case No.: {item?.case_number}</span>
                    </div>
                    <div className={`${styles.cases_flex_child2}`}>
                      <div>
                        <h3>{patientListConsult[i]?.contact_name}</h3>

                        <p>{patientListConsult[i]?.city}</p>
                        <h5>
                          First Visit:
                          {new Date(item?.created_at).toLocaleDateString()}
                        </h5>
                        <h5>
                          Last Checked At:{" "}
                          {new Date(item?.updated_at).toLocaleDateString()}
                        </h5>
                      </div>
                      <div>
                        <img
                          src={item?.latest_session_image_id?.url}
                          width={90}
                          height={90}
                          style={{ borderRadius: "10px" }}
                          alt="wound_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.case_flex_refered}>
                    <p>
                      Shared with {referredDoctorList[i]}{" "}
                      {doctorCount?.[i]?.length > 1 ? "and" : null}{" "}
                      {doctorCount?.[i]?.length > 1
                        ? doctorCount?.[i]?.length - 1
                        : null}{" "}
                      {doctorCount?.[i]?.length > 1 ? "more" : null}
                    </p>
                  </div>
                </Col>
              ))
            : null}
          {negate
            ? casesConsult?.map((item, i) => (
                <Col lg={4}>
                  <div
                    className={`${styles.cases_flex}`}
                    key={item?._id}
                    onClick={() =>
                      handleCasesConsult(
                        item?.case_number,
                        patientListConsult[i]?.patient_num
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className={`${styles.cases_flex_child1}`}>
                      <span style={{ marginRight: "10px" }}>
                        Patient ID: {patientListConsult[i]?.patient_num}
                      </span>
                      <span>Case No.: {item?.case_number}</span>
                    </div>
                    <div className={`${styles.cases_flex_child2}`}>
                      <div>
                        <h3>{patientListConsult[i]?.contact_name}</h3>

                        <p>{patientListConsult[i]?.city}</p>
                        <h5>
                          First Visit:
                          {new Date(item?.created_at).toLocaleDateString()}
                        </h5>
                        <h5>
                          Last Checked At:{" "}
                          {new Date(item?.updated_at).toLocaleDateString()}
                        </h5>
                      </div>
                      <div>
                        <img
                          src={item?.latest_session_image_id?.url}
                          width={90}
                          height={90}
                          style={{ borderRadius: "10px" }}
                          alt="wound_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.case_flex_refered}
                    onClick={() => {
                      setModal(true);
                      setCaseId(item?._id);
                    }}
                  >
                    <p>
                      Shared with {referredDoctorList[i]}{" "}
                      {doctorCount?.[i]?.length > 1 ? "and" : null}{" "}
                      {doctorCount?.[i]?.length > 1
                        ? doctorCount?.[i]?.length - 1
                        : null}{" "}
                      {doctorCount?.[i]?.length > 1 ? "more" : null}
                    </p>
                  </div>
                </Col>
              ))
            : null}

          {consultCase === false
            ? cases?.map((item, i) => (
                <Col lg={4}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    key={i}
                  >
                    <div
                      className={`${styles.cases_flex}`}
                      onClick={() =>
                        handleEditSession(
                          item?.case_number,
                          patientList[i]?.patient_num
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className={`${styles.cases_flex_child1}`}>
                        <span style={{ marginRight: "10px" }}>
                          Patient ID: {patientList[i]?.patient_num}
                        </span>
                        <span>Case No.: {item?.case_number}</span>
                      </div>
                      <div className={`${styles.cases_flex_child2}`}>
                        <div>
                          <h3>{patientList[i]?.contact_name}</h3>

                          <p>{patientList[i]?.city}</p>
                          <h5>
                            First Visit:
                            {new Date(item?.created_at).toLocaleDateString()}
                          </h5>
                          <h5>
                            Last Checked At:{" "}
                            {new Date(item?.updated_at).toLocaleDateString()}
                          </h5>
                        </div>
                        <div>
                          <img
                            src={item?.latest_session_image_id?.url}
                            width={90}
                            height={90}
                            style={{ borderRadius: "10px" }}
                            alt="wound_img"
                          />
                        </div>
                      </div>
                    </div>

                    {referedCase ? (
                      <>
                        <div className={styles.case_flex_refered}>
                          <p>Shared by {referredDoctorList[i]}</p>
                        </div>
                        <div className={`${styles.btn_flex}`}>
                          <button
                            onClick={() =>
                              handleRejectCases(
                                item?._id,
                                caseRefferedId[i],
                                "reject"
                              )
                            }
                          >
                            Reject
                          </button>
                          <button
                            style={{ backgroundColor: "#a70302" }}
                            onClick={() =>
                              handleAccted(
                                item?._id,
                                caseRefferedId[i],
                                "accept"
                              )
                            }
                          >
                            Accept
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </Col>
              ))
            : null}
        </Row>
      </Container>
      <SharedWith
        className="white_theme"
        show={modal}
        caseId={caseId}
        onHide={() => setModal(false)}
      />
    </>
  );
};

export default CasesPage;
