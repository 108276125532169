import styles from "./patientdetailpage.module.scss";
import Header from "../Header/Header";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import RadioButton from "../../components/RadioButton/RadioButton";
import patient from "../../components/constant/patient.json";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addPatientDetail, updatePatientDetail } from "../../redux/action";
import callApi from "../../util/apiCaller";
import { Country, State, City } from "country-state-city";
import _ from "lodash";
import AutoCompleteField from "../../components/AutoCompleteField/AutoCompleteFieldLightTheme";
import FullPageLoader from "../FullPageLoader";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const PatientDetailPage = ({ data, patientId, disable }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showText, setShowText] = useState(false);

  const [errors, setErrors] = useState({});
  const [bloodGroup, setBloodGroup] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [booleanPath, setBooleanPath] = useState(false);
  const [stateCode, setStateCode] = useState("");
  const [city, setCity] = useState([]);
  const [code, setCode] = useState("");
  const [user, setUser] = useState({
    contact_name: "",
    user_type: "patient",
    contact_email: "",
    phone_number: "",
    country_code: "91",
    state_code: "",
    profile_image: "",
    dob: new Date(),
    age: "",
    address: "",
    country: "",
    code: "",
    city: "",
    state: "",
    blood_group: "",
    weight: "",
    height: "",
    business_id: "6597d3f68836f7c222e1a504",
  });
  useEffect(() => {
    fetchContent("blood-group-types");
  }, []);
  const [padnum, setPadnum] = useState(8);
  useEffect(() => {
    if (
      location.pathname === "/edit-session" ||
      location.pathname === "/patient-list"
    ) {
      setBooleanPath(true);

      setPadnum(2);
    } else {
      setBooleanPath(false);
    }
    if (location.pathname === "/patient-list") {
      setShowText(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setCode(data?.code);
      setStateCode(data?.state_code);

      setUser({
        contact_name: data?.contact_name,
        user_type: "patient",
        contact_email: data?.contact_email,
        phone_number: data?.phone_number,
        country_code: "91",
        profile_image: data?.profile_image,
        dob: data?.dob,
        age: data?.age,
        address: data?.address,
        city: data?.city,
        country: data?.country,
        state: data?.state,
        blood_group: data?.blood_group,
        weight: data?.weight,
        height: data?.height,
        business_id: "6597d3f68836f7c222e1a504",
      });
    }
  }, [data]);
  useEffect(() => {
    let country = Country.getAllCountries().map((option) => {
      return option.name;
    });

    setCountry(country);
  }, []);
  useEffect(() => {
    let state = State.getStatesOfCountry(code).map((option) => {
      return option.name;
    });
    setState(state);
  }, [code]);
  useEffect(() => {
    // if(customer && customer._id) {
    //     navigate(consoleUrl);
    // }
    if ((code, stateCode)) {
      let cityList = City.getCitiesOfState(code, stateCode).map(
        (option) => option.name
      );

      setCity(cityList);
    }
  }, [code, stateCode]);

  async function fetchContent(code) {
    try {
      const res = await callApi(`content/code/${code}`, "get");

      if (res && res?.status === "Success") {
        const { params } = res?.data?.content;

        let param = params?.map((options) => options?.label);
        setBloodGroup([...param]);
      } else {
      }
    } catch (err) {}
  }
  const addPatientHandler = (keyName, keyValue) => {
    console.log(keyValue);

    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    let update = { ...user };
    if (keyName === "country") {
      Country.getAllCountries().forEach((option) => {
        if (option.name === keyValue) {
          if (option.isoCode) {
            setCode(option.isoCode);
            update["code"] = option.isoCode;
          }
        }
      });
    }

    if (keyName === "state") {
      State.getStatesOfCountry(code).forEach((option) => {
        if (option.name === keyValue) {
          if (option.isoCode) {
            setStateCode(option.isoCode);
            update["state_code"] = option.isoCode;
          }
        }
      });
    }
    update[keyName] = keyValue;

    setUser(update);
  };
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Contact name cannot be blank";
    }
    // if (!user?.country_code) {
    //     isValid = false;
    //     errors["country_code"] = "Country code cannot be blank"
    // }
    if (!user?.phone_number) {
      isValid = false;
      errors["phone_number"] = "Phone number cannot be blank";
    }
    if (!user?.dob) {
      isValid = false;
      errors["dob"] = "Dob cannot be blank";
    }
    // if (!user?.age) {
    //     isValid = false;
    //     errors["age"] = "Age cannot be blank"
    // }
    // if (!user?.phone_number) {
    //     isValid = false;
    //     errors["phone_number"] = "Phone number cannot be blank"
    // }
    // if (!user?.phone_number) {
    //     isValid = false;
    //     errors["profile_image"] = "Profile image cannot be blank"
    // }
    if (!user?.city) {
      isValid = false;
      errors["city"] = "City cannot be blank";
    }
    if (!user?.state) {
      isValid = false;
      errors["state"] = "State cannot be blank";
    }
    if (!user?.address) {
      isValid = false;
      errors["address"] = "Address cannot be blank";
    }

    return {
      isValid,
      errors,
    };
  };

  const handleApi = () => {
    //debugger;
    const check = handleValidation(user);
    if (check.isValid) {
      if (data && patientId) {
        dispatch(updatePatientDetail(user, patientId, setLoading));
        setLoading(false);
      }

      setErrors({});
    } else {
      setErrors(check.errors);
    }
  };

  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error">{errors[keyName]}</p>
    ) : null;
  };
  const disableOnTrue = (flag) => {
    return {
      opacity: flag === false ? 1 : 1,
      pointerEvents: flag === false ? "none" : "initial",
    };
  };

  return (
    <>
      {/* <Header /> */}
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <Container
        className={`${styles.patient_flex}`}
        style={{ padding: `${padnum}em 0 0.688em` }}
      >
        {booleanPath ? null : <h2>Patient Details</h2>}
        {showText ? <h2>Patient Details</h2> : null}
        {/* <ul className={`${styles.radio_container}`}>
                            {patient.map(item => (
                                <RadioButton
                                    key={item.label}
                                    
                                    value={item.value}
                                    label={item.label}
                                    labelId={item.label}
                                    
                                />
                            ))}
                           
                        </ul>
                 <div  className={`${styles.patient_flex_input}`}>
                    <input placeholder="Search" />
                    <img src="images/Search.png" alt="" />
                </div> */}
        <div style={disableOnTrue(disable)}>
          <Row className={`${styles.patient_flex_body}`}>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.patient_flex_child}`}>
                <label>
                  Patient Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  //class="form-control"
                  placeholder="Enter Patient Name*"
                  value={user?.contact_name}
                  onChange={(e) =>
                    addPatientHandler("contact_name", e.target.value)
                  }
                />
              </div>
              <div style={{ marginLeft: "40px" }}>
                {renderError("contact_name")}
              </div>
              <div className={`${styles.patient_flex_child}`}>
                <label>
                  Phone Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  // class="form-control"
                  placeholder="Enter Phone Number*"
                  value={user?.phone_number}
                  onChange={(e) =>
                    addPatientHandler("phone_number", e.target.value)
                  }
                />
              </div>
              <div style={{ marginLeft: "40px" }}>
                {renderError("phone_number")}
              </div>
              {booleanPath ? null : (
                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    DOB<span style={{ color: "red" }}>*</span>
                  </label>

                  <DatePicker
                    selected={user?.dob}
                    scrollableYearDropdown
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Date of birth"
                    onChange={(e) => addPatientHandler("dob", e)}
                  />
                </div>
              )}
              {showText ? (
                <div className={`${styles.patient_flex_child}`}>
                  <label>
                    DOB<span style={{ color: "red" }}>*</span>
                  </label>

                  <DatePicker
                    selected={user?.dob}
                    scrollableYearDropdown
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Date of birth"
                    onChange={(e) => addPatientHandler("dob", e)}
                  />
                </div>
              ) : null}
              <div style={{ marginLeft: "40px" }}>{renderError("dob")}</div>
              <div className={`${styles.patient_flex_child}`}>
                <label>Age (years)</label>
                <input
                  type="text"
                  //class="form-control"
                  placeholder="Age (years)"
                  value={user?.age}
                  onChange={(e) => addPatientHandler("age", e.target.value)}
                />
              </div>

              <div className={`${styles.patient_flex_child}`}>
                <label>Height (cm)</label>
                <input
                  type="text"
                  // class="form-control"
                  placeholder="Height in cm"
                  value={user?.height}
                  onChange={(e) => addPatientHandler("height", e.target.value)}
                />
              </div>

              <div className={`${styles.patient_flex_child}`}>
                <label>Weight (kg)</label>
                <input
                  type="text"
                  // class="form-control"
                  placeholder="Weight in kg"
                  value={user?.weight}
                  onChange={(e) => addPatientHandler("weight", e.target.value)}
                />
              </div>
            </Col>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.patient_flex_child}`}>
                <label>Email</label>
                <input
                  type="text"
                  // class="form-control"
                  placeholder="Email"
                  value={user?.contact_email}
                  onChange={(e) =>
                    addPatientHandler("contact_email", e.target.value)
                  }
                />
              </div>

              <div className={`${styles.patient_flex_child}`}>
                <label>
                  Location<span style={{ color: "red" }}>*</span>
                </label>
                {/* <input type="text"
                                class="form-control"
                                placeholder="City*"
                                value={user?.city}
                                onChange={(e) => addPatientHandler("city",e.target.value)}
                            /> */}
                <div style={{ width: "90%", marginTop: "15px" }}>
                  <AutoCompleteField
                    textLabel={"Country"}
                    data={country}
                    id={"country12"}
                    value={user?.country}
                    keyName={"country"}
                    handleUserDetail={addPatientHandler}
                  />
                  {/* <SelectDropdown
                    label={"Country"}
                    data={country}
                    labelId={"country12"}
                    value={user?.country}
                    keyName={"country"}
                    handleUserDetail={addPatientHandler}
                  /> */}
                </div>
                <div style={{ width: "90%", marginTop: "15px" }}>
                  <AutoCompleteField
                    textLabel={"State"}
                    data={state}
                    id={"state12"}
                    value={user?.state}
                    keyName={"state"}
                    handleUserDetail={addPatientHandler}
                  />
                  {/* <SelectDropdown
                    label={"State"}
                    data={state}
                    labelId={"state12"}
                    value={user?.state}
                    keyName={"state"}
                    handleUserDetail={addPatientHandler}
                  /> */}
                </div>
                <div
                  style={{
                    width: "90%",
                    marginTop: "15px",
                  }}
                >
                  <AutoCompleteField
                    textLabel={"City"}
                    data={city}
                    id={"city12"}
                    value={user?.city}
                    keyName={"city"}
                    handleUserDetail={addPatientHandler}
                  />
                  {/* <SelectDropdown
                    label={"City"}
                    data={city}
                    labelId={"city12"}
                    value={user?.city}
                    keyName={"city"}
                    handleUserDetail={addPatientHandler}
                  /> */}
                </div>
              </div>
              {/* <div style={{ "marginLeft": "40px" }}>{renderError("city")}</div>
                        <div className={`${styles.patient_flex_child}`}>
                            <label>State<span style={{ color: "red" }}>*</span></label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="State*"
                                value={user?.state}
                                onChange={(e) => addPatientHandler("state",e.target.value)}
                            />
                        </div> */}
              <div style={{ marginLeft: "40px" }}>{renderError("state")}</div>
              <div className={`${styles.patient_flex_child}`}>
                <label>
                  Address<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  // class="form-control"
                  placeholder="Enter Address*"
                  value={user?.address}
                  onChange={(e) => addPatientHandler("address", e.target.value)}
                />
                <div style={{ marginLeft: "40px" }}>
                  {renderError("address")}
                </div>
              </div>
              <div className={`${styles.patient_flex_child2}`}>
                <SelectDropdown
                  label={"Blood Group*"}
                  data={bloodGroup}
                  labelId={"blood12"}
                  value={user?.blood_group}
                  keyName={"blood_group"}
                  handleUserDetail={addPatientHandler}
                />
              </div>
              <div className={`${styles.patient_flex_child}`}>
                {/* <label>Referred Doctor</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Referred Doctor"
                                value={user?.referred_doctor}
                                onChange={(e) => addPatientHandler("referred_doctor",e.target.value)}
                            /> */}
              </div>
            </Col>
          </Row>
          <div className={`${styles.patient_flex_btn}`}>
            <button onClick={handleApi}>
              {booleanPath ? "Save Patient" : "Add Patient"}
            </button>
          </div>
        </div>
        {/* <Row className={`${styles.patient_flex_body}`}>
                    <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound1"} label={"wound Location"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound2"} label={"wound Cause"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound12"} label={"wound Type"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound13"} label={"wound duration"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound14"} label={"Mobility"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound15"} label={"Nutrition"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound14"} label={"Nutrition"}/>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                            <div>
                        <label>Alcholic*</label>
                            </div>
                            <div style={{display:"flex"}}>
                                <input type="radio" />
                                <label>Yes</label>
                                <input type="radio" />
                                <label>No</label>
                            </div>
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                            <div>
                        <label>Smoker*</label>
                            </div>
                            <div style={{display:"flex"}}>
                                <input type="radio" />
                                <label>Yes</label>
                                <input type="radio" />
                                <label>No</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                        <div className={`${styles.patient_flex_child}`}>
                            <div>
                                <label>Allergies</label>
                            </div>
                        <textarea type="text" class="form-control" placeholder="Enter Details" />
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <div>
                                <label>Medications</label>
                        </div>

                        <textarea type="text" class="form-control" placeholder="Enter Details"  />
                        </div>
                        <div className={`${styles.patient_flex_child}`}>
                        <div>
                                <label>other factors which could delay healing</label>
                        </div>
                        <textarea type="text" class="form-control" placeholder="Enter Details"  />
                        </div>
                    </Col>
                </Row>*/}
      </Container>
    </>
  );
};

export default PatientDetailPage;
