import { applyMiddleware,createStore } from 'redux';
import rootReducer from './reducers';
import {configureStore} from '@reduxjs/toolkit';
import {thunk} from "redux-thunk";


// componentWillMount
const middleware = [thunk];
// const store = configureStore(
//     {reducer: rootReducer},
//     applyMiddleware(...middleware),
//   );
const store = createStore(rootReducer,
    applyMiddleware(thunk)
  // other store enhancers if any
);

export default store;