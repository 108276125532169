import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Product.module.css";
import callApi from "../../util/apiCaller";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HeaderV2 from "../../components/Header/HeaderV2";
import { useNavigate, Link } from "react-router-dom";

const Product = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchContent() {
    try {
      setLoading(true);
      const res = await callApi(`config`, "get");

      if (res && res?.status === "Success") {
        const { products } = res?.data;
        setLoading(false);

        setProduct(products);
      } else {
      }
    } catch (err) {}
  }
  useEffect(() => {
    fetchContent();
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <>
      <HeaderV2 />
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Container className={styles.main}>
        {/* <div
          style={{ margin: "10px 0", paddingLeft: "1em", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
          <span style={{ fontSize: "20px" }}> Back</span>
          {/* </ArrowBackIcon> */}
        {/* </div>  */}
        <Row>
          {product?.map((item) => (
            <Col lg={4} key={item?.title}>
              <Link
                className={`${styles.product_flex}`}
                to={"https://quorit.com/"}
                target="_blank"
              >
                <div className={`${styles.product_img}`}>
                  <img src={item?.image_url} alt="" />
                </div>
                <p className={`${styles.product_title}`}>{item?.title}</p>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Product;
