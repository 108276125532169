import { useEffect, useState } from "react";
import "./EditModal.scss";
import Modal from "react-bootstrap/Modal";
import callApi from "../../../util/apiCaller";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../../util/Message";

const EditModal = (props) => {
  const [editMessage, setEditMessage] = useState("");
  const handleEditMessage = async () => {
    const res = await callApi(
      `case/${props.case_id}/comment/${props.id}/update-comment`,
      "post",
      {
        text: editMessage,
      }
    );
    if (res.status === "Success") {
      props.setRender(!props.render);
      toast(SuccessMsg("Edited successfully "), {
        className: "mail_toast",
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
        theme: "colored",
      });
      props.onHide();
    }
  };

  return (
    <>
      <Modal
        {...props}
        className={`define_modal ${props?.className}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div class="modal-header">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30em",
              padding: "1em 3.0em",
            }}
          >
            <div
              style={{
                fontSize: "1.5em",
                marginBottom: "10px",

                fontWeight: "600",
              }}
            >
              Update
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.onHide();
                }}
              ></button>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div className="input_container">
            <textarea
              type="text"
              rows={3}
              value={editMessage}
              onChange={(e) => setEditMessage(e.target.value)}
              placeholder="Type a message"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5em",
            }}
          >
            <p
              style={{
                padding: "0.475em 0.625em",
                display: "flex",
                width: "200px",
                justifyContent: "center",
                border: "1px solid #ED8847",
                background: "#fbf1e9",
                borderRadius: "10px",
                fontSize: "1.125em",
                cursor: "pointer",
              }}
              onClick={() => handleEditMessage()}
            >
              Edit
            </p>
          </div>
        </Modal.Body>
        <div class="modal-footer"></div>
      </Modal>
    </>
  );
};

export default EditModal;
