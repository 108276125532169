import { Container, Row, Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import styles from "./comparesessionpage.module.scss";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import Header from "../Header/Header";
import compareSession from "../../components/constant/compareSession.json";
import Chart from "react-apexcharts";
import RadioButton4 from "../../components/RadioButton4/RadioButton4";
import callApi from "../../util/apiCaller";
import { CASE_ID, PATIENT_ID } from "../../components/constant/authEnums";
import AutocompleteFieldLightTheme from "../../components/AutoCompleteFieldLightTheme2/AutoCompleteFieldLightTheme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, Link } from "react-router-dom";
import { forEach } from "lodash";
import { colors } from "@mui/material";
import HeaderV2 from "../../components/Header/HeaderV2";
import moment from "moment/moment";

const CompareSessionPage = () => {
  const [compareList, setCompareList] = useState(compareSession[0]?.label);
  const navigate = useNavigate();
  const caseId = window.localStorage.getItem(CASE_ID);
  const [imgId, setImgId] = useState("");
  const [report, setReport] = useState([]);
  const [session, setSession] = useState([]);
  const [sessionImg, setSessionImg] = useState([]);
  const [img1Id, setImg1Id] = useState([]);
  const [img2Id, setImg2Id] = useState([]);
  const [sessionLength, setSessionLength] = useState([]);
  const [comment, setComment] = useState([]);
  const [sessionBreath, setSessionBreath] = useState([]);
  const [sessionVolume, setSessionVolume] = useState([]);
  const [sessionDepth, setSessionDepth] = useState([]);
  const [sessionArea, setSessionArea] = useState([]);
  const [sessionImg2, setSessionImg2] = useState([]);
  const [necrotic, setNecrotic] = useState([]);
  const [infected, setInfected] = useState([]);
  const [slough, setSlough] = useState([]);
  const [granulating, setGranulating] = useState([]);
  const [epithelializing, setEpithelializing] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [imgUrl2, setImgUrl2] = useState("");
  const [imgList2, setImgList2] = useState([]);
  const [sessionList, setSessionList] = useState([]);

  const [sessionListId, setSessionListId] = useState("");

  useEffect(() => {
    const fetchContent = async (caseId) => {
      if (caseId) {
        try {
          const res = await callApi(`session/list`, "post", {
            pageNum: 1,
            pageSize: 50,
            filters: {
              case_id: caseId,
            },
          });

          if (res && res?.status === "Success") {
            const { list } = res?.data;

            let comment = [];
            let arr = [];

            list.forEach((item, i) =>
              arr.push({
                session: `S-${i + 1}${"\n"}(${moment(item?.created_at).format(
                  "DD-MM-YYYY"
                )})`,
                ...item,
              })
            );
            setSession([...arr]);
            if (list) {
              setImg1Id(list?.[0]?._id);
              setImg2Id(list?.[1]?._id);
            }
            // for (let item of arr) {
            //   if (
            //     item.session ===
            //     `S - ${1}\n${new Date(item?.created_at)
            //       .toLocaleDateString()
            //       .slice(0, 10)}`
            //   ) {
            //     setImg1Id(item?._id);
            //   }
            //   if (
            //     item.session ===
            //     `S - ${2}\n${new Date(item?.created_at)
            //       .toLocaleDateString()
            //       .slice(0, 10)}`
            //   ) {
            //     setImg2Id(item?._id);
            //   }
            // }
            for (let item of arr) {
              const res = await callApi(`sessionnote/list`, "post", {
                pageNum: 1,
                pageSize: 50,
                filters: {
                  session_id: item?._id, // Include any specific filters here
                },
              });

              if (res && res?.status === "Success") {
                const { list } = res?.data;
                console.log(list);
                let update = {};
                let arr = [...list];
                for (let item of arr) {
                  if (item?.note_type === "observation") {
                    update.observation = item?.note_type;
                    update.observation_text = item?.text;
                    update.created_at = item?.created_at;
                  }
                  if (item?.note_type === "note") {
                    update.privatenote = `private${item?.note_type}`;
                    update.privatenote_text = item?.text;
                    update.created_at = item?.created_at;
                  }
                  if (item?.note_type === "treatment") {
                    update.treatment = item?.note_type;
                    update.treatment_text = item?.text;
                    update.created_at = item?.created_at;
                  }
                }

                comment.push(update);
              }
            }
            setComment([...comment]);

            let rep = [];
            list.forEach((item, i) =>
              rep.push({
                session: `Session${" "}${i + 1}${" "}(${moment(
                  item?.created_at
                ).format("DD-MM-YYYY")})`,
                link: item?.report_link[item?.report_link?.length - 1],
              })
            );
            setReport([...rep]);

            let result = arr.map((item) => item?.session);
            setSessionList([...result]);
            let length = arr.map((item) => item?.wound_length);
            setSessionLength([...length]);
            let breadth = arr.map((item) => item?.wound_breadth);
            setSessionBreath([...breadth]);
            let depth = arr.map((item) => item?.wound_depth);
            setSessionDepth([...depth]);
            let area = arr.map((item) => item?.wound_area);
            setSessionArea([...area]);
            let volume = arr.map((item) => item?.wound_volume);
            setSessionVolume([...volume]);
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            let arr5 = [];
            for (let item of arr) {
              if (item?.tissue_classification?.[0]?.tissue_color === "pink") {
                arr1.push(
                  item?.tissue_classification[0]?.tissue_generation_percent
                );
              }
              if (item?.tissue_classification?.[1]?.tissue_color === "black") {
                arr2.push(
                  item?.tissue_classification[1]?.tissue_generation_percent
                );
              }
              if (item?.tissue_classification?.[2]?.tissue_color === "brown") {
                arr3.push(
                  item?.tissue_classification[2]?.tissue_generation_percent
                );
              }
              if (item?.tissue_classification?.[3]?.tissue_color === "green") {
                arr4.push(
                  item?.tissue_classification[3]?.tissue_generation_percent
                );
              }
              if (item?.tissue_classification?.[4]?.tissue_color === "yellow") {
                arr5.push(
                  item?.tissue_classification[4]?.tissue_generation_percent
                );
              }
            }
            setNecrotic(arr2);
            setGranulating(arr3);
            setInfected(arr4);
            setSlough(arr5);
            setEpithelializing(arr1);
          } else {
          }
        } catch (err) {}
      }
    };
    fetchContent(caseId);
  }, [caseId]);

  // useEffect(() => {
  //     const fetchContent = async (sessionListId) => {
  //         if (sessionListId) {
  //             try {
  //                 const res = await callApi(`sessionimage/list`, 'post', {
  //                     "pageNum": 1,
  //                     "pageSize": 50,
  //                     "filters": {
  //                         session_id: sessionListId,
  //                     }
  //                 });

  //                 if (res && res?.status === 'Success') {
  //                     const { list } = res?.data;
  //                    // setImgList(list)
  //                     let arr = [...list]

  //                     setImgList([...arr])

  //                 }
  //             } catch (err) {

  //             }
  //         }
  //     }
  //     fetchContent(sessionListId)
  // }, [sessionListId])

  const options_length = {
    chart: {
      background: "#fbf1e9",
      foreColor: "#111",
    },
    xaxis: {
      categories: sessionList,
      labels: {
        offsetX: 2,
        offsetY: 0,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 35,
      },
    },
    // stroke: {
    //     curve: 'stepline',
    //   },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: ["#ED8847"],
    // fill: {
    //     opacity:0.1,
    // },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Length",
      alien: "center",
      margin: 20,
      offsetY: 35,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_length = [
    {
      name: "length",
      data: sessionLength,
    },
  ];
  const options_breadth = {
    chart: {
      background: "#fbf1e9",
      foreColor: "#111",
    },
    xaxis: {
      categories: sessionList,
      labels: {
        offsetX: 2,
        offsetY: 0,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 40,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: ["#ED8847"],
    fill: {
      colors: ["#f44336"],
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Breadth",
      alien: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_breadth = [
    {
      name: "breadth",
      data: sessionBreath,
    },
  ];
  const options_depth = {
    chart: {
      background: "#fbf1e9",
      foreColor: "#111",
    },
    xaxis: {
      categories: sessionList,
      labels: {
        offsetX: 2,
        offsetY: 0,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 40,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: ["#ED8847"],
    fill: {
      colors: ["#f44336"],
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Depth",
      alien: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_depth = [
    {
      name: "depth",
      data: sessionDepth,
    },
  ];
  const options_area = {
    chart: {
      background: "#fbf1e9",
      foreColor: "#111",
    },
    xaxis: {
      categories: sessionList,
      padding: "200px",

      labels: {
        offsetX: 2,
        offsetY: 0,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 35,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    fill: {
      colors: ["#f44336"],
    },
    colors: ["#ED8847"],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Area",
      alien: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_area = [
    {
      name: "area",
      data: sessionArea,
    },
  ];
  const options_volume = {
    chart: {
      background: "#fbf1e9",
      foreColor: "#111",
    },
    xaxis: {
      categories: sessionList,
      labels: {
        offsetX: 2,
        offsetY: 0,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 35,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: ["#ED8847"],
    fill: {
      colors: ["#f44336"],
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Volume",
      alien: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_volume = [
    {
      name: "volume",
      data: sessionVolume,
    },
  ];
  const options_stack = {
    chart: {
      // background: "#f4f4f4",
      // foreColor: '#333',
      background: "#ffffff",
      foreColor: "#111",
      stacked: true,
      stackType: "100%",
    },
    xaxis: {
      categories: sessionList,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: ["#111", "#74AA49", "#feec3b", "#AA4949", "#EA1E63"],
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Tissue Classification",
      alien: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_stack = [
    {
      name: "Necrotic",
      data: necrotic,
    },
    {
      name: "Infected",
      data: infected,
    },
    {
      name: "Slough",
      data: slough,
    },
    {
      name: "Granulating",
      data: granulating,
    },
    {
      name: "Epithelializing",
      data: epithelializing,
    },
  ];
  useEffect(() => {
    const fetchContent = async (img1Id) => {
      try {
        const res = await callApi(`sessionimage/list`, "post", {
          pageNum: 1,
          pageSize: 50,
          filters: {
            session_id: img1Id,
          },
        });

        if (res && res?.status === "Success") {
          const { list } = res?.data;
          // setImgList(list)
          //let arr = [...list]

          let arr = [];

          list.forEach((item, i) =>
            arr.push({ session: `Image${" "}${i + 1}`, ...item })
          );

          setSessionImg([...arr]);
          let result = arr.map((item) => item?.session);
          for (let item of arr) {
            if (item.session === result[0]) {
              setImgUrl(item?.url);
            }
          }

          setImgList([...result]);
        }
      } catch (e) {}
    };
    fetchContent(img1Id);
  }, [img1Id]);
  const sessionHandler = async (keyName, keyValue) => {
    if (keyName === "compare_list") {
      if (keyValue === compareSession[0].label) {
        setCompareList(keyValue);
      } else if (keyValue === compareSession[1].label) {
        setCompareList(keyValue);
      } else if (keyValue === compareSession[2].label) {
        setCompareList(keyValue);
      } else if (keyValue === compareSession[3].label) {
        setCompareList(keyValue);
      } else if (keyValue === compareSession[4].label) {
        setCompareList(keyValue);
      } else if (keyValue === compareSession[5].label) {
        setCompareList(keyValue);
      }
    }
    if (keyName === "session_type") {
      let arr = [...session];
      for (let item of arr) {
        if (item?.session === keyValue) {
          setImg1Id(item._id);
        }
      }
    }
  };
  useEffect(() => {
    const fetchContent = async (img2Id) => {
      try {
        const res = await callApi(`sessionimage/list`, "post", {
          pageNum: 1,
          pageSize: 50,
          filters: {
            session_id: img2Id,
          },
        });

        if (res && res?.status === "Success") {
          const { list } = res?.data;

          let arr = [];

          list.forEach((item, i) =>
            arr.push({ session: `Image${" "}${i + 1}`, ...item })
          );

          setSessionImg2(arr);
          let result = arr.map((item) => item.session);
          for (let item of arr) {
            if (item.session === result[0]) {
              setImgUrl2(item?.url);
            }
          }

          setImgList2([...result]);
        }
      } catch (e) {}
    };
    fetchContent(img2Id);
  }, [img2Id]);
  const sessionImageHandler = async (keyName, keyValue) => {
    if (keyName === "session_type1") {
      let arr1 = [...session];
      for (let item of arr1) {
        if (item?.session === keyValue) {
          setImg2Id(item?._id);
        }
      }
    }
  };
  const sessionImgHandler = (keyName, keyValue) => {
    if (keyName === "session_img") {
      let arr = [...sessionImg];

      for (let item of arr) {
        if (item.session === keyValue) {
          setImgUrl(item?.url);
        }
      }
    }
    if (keyName === "session_img2") {
      let arr2 = [...sessionImg2];
      for (let item of arr2) {
        if (item.session === keyValue) {
          setImgUrl2(item?.url);
        }
      }
    }
  };

  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <HeaderV2 />
      <Container className={`${styles.compare_flex}`}>
        <div>
          <ul className={`${styles.radio_container}`}>
            {compareSession?.map((item, i) => (
              <RadioButton4
                key={item?.label}
                checked={item?.label === compareList}
                keyName={"compare_list"}
                value={item?.label}
                label={item?.display_name}
                labelId={item?.label}
                patientDetailHandler={sessionHandler}
              />
            ))}
          </ul>
        </div>
      </Container>
      {compareList === compareSession[0].label ? (
        <>
          <Container className={`${styles.patient_flex}`}>
            <h2 style={{ marginLeft: "0.67em" }}>Image Comparison</h2>
            <Row className={`${styles.patient_flex_body}`}>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div
                  className={`${styles.auto_flex}`}
                  style={{ marginLeft: "1em" }}
                >
                  {sessionList.length > 0 ? (
                    <AutocompleteFieldLightTheme
                      data={sessionList}
                      id={"session5"}
                      value={sessionList.length > 0 ? sessionList[0] : null}
                      keyName={"session_type"}
                      textLabel={"Session"}
                      handleUserDetail={sessionHandler}
                    />
                  ) : null}
                  {/* <SelectDropdown label={"sessions"} labelId={"session34"} /> */}
                </div>
              </Col>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div
                  className={`${styles.auto_flex}`}
                  style={{ marginLeft: "1em" }}
                >
                  {sessionList.length > 0 ? (
                    <AutocompleteFieldLightTheme
                      data={sessionList}
                      id={"session6"}
                      value={sessionList.length > 1 ? sessionList[1] : null}
                      keyName={"session_type1"}
                      textLabel={"Session"}
                      handleUserDetail={sessionImageHandler}
                    />
                  ) : null}
                  {/* <SelectDropdown label={"sessions"} labelId={"session45"} /> */}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className={`${styles.patient_flex}`}>
            <Row className={`${styles.patient_flex_body}`}>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div
                  className={`${styles.auto_flex2}`}
                  style={{ marginLeft: "1em" }}
                >
                  {imgList.length > 0 ? (
                    <AutocompleteFieldLightTheme
                      data={imgList}
                      id={"session10"}
                      value={imgList.length > 0 ? imgList[0] : null}
                      keyName={"session_img"}
                      textLabel={"Session Image"}
                      handleUserDetail={sessionImgHandler}
                    />
                  ) : null}
                  {/* <SelectDropdown label={"sessionsImages"} labelId={"session1"} /> */}
                </div>
                {imgUrl ? (
                  <Card
                    className={`${styles.auto_flex_img}`}
                    style={{ marginLeft: "1em" }}
                  >
                    <img src={imgUrl} alt="img1" width={"90%"} height={350} />
                  </Card>
                ) : null}
              </Col>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div
                  className={`${styles.auto_flex2}`}
                  style={{ marginLeft: "1em" }}
                >
                  {imgList2.length > 0 ? (
                    <AutocompleteFieldLightTheme
                      data={imgList2}
                      value={imgList2.length > 0 ? imgList2[0] : null}
                      id={"session11"}
                      keyName={"session_img2"}
                      textLabel={"Session Image"}
                      handleUserDetail={sessionImgHandler}
                    />
                  ) : null}
                  {/* <SelectDropdown label={'sessionsImages'} labelId={"session2"} /> */}
                </div>
                {imgUrl2 ? (
                  <Card
                    className={`${styles.auto_flex_img}`}
                    style={{ marginLeft: "1em" }}
                  >
                    <img src={imgUrl2} alt="img2" width={"90%"} height={350} />
                  </Card>
                ) : null}
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
      {compareList === compareSession[1].label ? (
        <Container className={`${styles.patient_flex}`}>
          <h2 style={{ marginLeft: "0.625em" }}>Measurement in Graph</h2>
          <Row className={`${styles.patient_flex_body}`}>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.chart_flex}`}>
                <Chart
                  options={options_area}
                  series={series_area}
                  type="line"
                  height={450}
                />
              </div>
            </Col>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.chart_flex}`}>
                <Chart
                  options={options_volume}
                  series={series_volume}
                  type="line"
                  height={450}
                />
              </div>
            </Col>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.chart_flex}`}>
                <Chart
                  options={options_length}
                  series={series_length}
                  type="line"
                  height={450}
                />
              </div>
            </Col>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.chart_flex}`}>
                <Chart
                  options={options_breadth}
                  series={series_breadth}
                  type="line"
                  height={450}
                />
              </div>
            </Col>
            <Col lg={6} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.chart_flex}`}>
                <Chart
                  options={options_depth}
                  series={series_depth}
                  type="line"
                  height={450}
                />
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {compareList === compareSession[2].label ? (
        <Container className={`${styles.patient_flex}`}>
          <h2 style={{ marginLeft: "0.625em" }}>Tissue Classification</h2>
          <Row className={`${styles.patient_flex_body}`}>
            <Col lg={8} className={`${styles.patient_flex_body_part}`}>
              <div style={{ marginLeft: "0.625em" }}>
                <Chart
                  options={options_stack}
                  series={series_stack}
                  type="bar"
                  height={450}
                  // stacked= {true}
                  // stackType="100%"
                />
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {compareList === compareSession[3].label ? (
        <Container className={`${styles.patient_flex}`}>
          <h2 style={{ marginLeft: "1em" }}>Details</h2>
          <Row
            className={`${styles.patient_flex_body}`}
            style={{ marginLeft: "1em" }}
          >
            <Col lg={8} className={`${styles.patient_flex_body_part}`}>
              <div className={`${styles.other_parameters}`}>
                <div style={{ padding: "10px", fontSize: "1.25em" }}>
                  <div style={{ padding: "10px" }}>
                    <table style={{ margin: "10px 0" }}>
                      <tr style={{ margin: "10px 0" }}>
                        <td style={{ padding: "10px 0" }}>Peri Wound Area</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px 0" }}>Odour</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px 0" }}>Exudate</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px 0" }}>Pain</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px 0" }}>Infection</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div
                  style={{
                    padding: "1.25em",
                    border: "1px solid black",
                    display: "flex",
                    fontSize: "1.25em",
                  }}
                >
                  {session?.map((item, i) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 10px",
                        }}
                        key={`s${i}`}
                      >
                        <div
                          style={{ background: "#fbf1e9", padding: "20px" }}
                          className={styles.wound_graph}
                        >
                          <table
                            style={{
                              color: "#ED8847",
                              margin: "10px 0",
                              fontWeight: "500",
                            }}
                          >
                            <tr style={{ color: "#ED8847", margin: "10px 0" }}>
                              <td style={{ padding: "10px 0" }}>
                                {item?.surrounding_skin
                                  ? item?.surrounding_skin?.slice(0, 1)
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "10px 0" }}>
                                {" "}
                                {item?.wound_odour
                                  ? item?.wound_odour?.slice(0, 1)
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "10px 0" }}>
                                {item?.wound_exudates
                                  ? item?.wound_exudates?.slice(0, 2)
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "10px 0" }}>
                                {item?.pain ? item?.pain?.slice(0, 1) : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "10px 0" }}>
                                {item?.infection
                                  ? item?.infection?.slice(0, 1)
                                  : "NA"}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <p style={{ marginLeft: "5px" }}>S-{i + 1}</p>
                        <p>
                          {new Date(item?.created_at)
                            .toLocaleDateString()
                            .slice(0, 5)}
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div
                style={{
                  margin: "1.87em 0",
                  paddingLeft: "1.25em",
                  fontSize: "1.2em",
                }}
              >
                <table>
                  <tr>
                    <td>NA - Data Not available</td>
                  </tr>
                  <tr>
                    <td>Hi - High</td>
                  </tr>
                  <tr>
                    <td>MO - Moderate </td>
                  </tr>
                  <tr>
                    <td>Lo - Low</td>
                  </tr>
                  <tr>
                    <td>O - Oedema</td>
                  </tr>
                  <tr>
                    <td>E - Erythematic</td>
                  </tr>
                  <tr>
                    <td>M - Macerated</td>
                  </tr>
                  <tr>
                    <td>H - Healthy/Intact</td>
                  </tr>
                  <tr>
                    <td>Y - Yes</td>
                  </tr>
                  <tr>
                    <td>N - No</td>
                  </tr>
                  <tr>
                    <td>C - Continuous</td>
                  </tr>
                  <tr>
                    <td>I - Intermittent</td>
                  </tr>
                  <tr>
                    <td>A - At dressing change</td>
                  </tr>
                  <tr>
                    <td>P - Post Dressing</td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {compareList === compareSession[4].label ? (
        <Container className={`${styles.patient_flex}`}>
          <h2 style={{ marginLeft: "0.67em" }}>Comments</h2>
          <Row className={`${styles.patient_flex_body}`}>
            {comment?.map((item, i) => (
              <Col
                lg={12}
                className={`${styles.patient_flex_body_part}`}
                key={`s${i}`}
              >
                <div style={{ marginLeft: "1em" }}>
                  <div className={`${styles.session_flex_comment}`}>
                    {`Session${" "}${i + 1}
                  ${"\n"}(${moment(item?.created_at).format("DD-MM-YYYY")})`}
                  </div>
                  <h3 style={{ fontSize: "1.25em" }}>
                    {item?.observation ? null : "No comments available"}
                  </h3>
                  <div>
                    <h5 style={{ fontWeight: "600" }}>
                      {item?.observation ? "Obervations" : null}
                    </h5>
                    <p style={{ fontSize: "1.25em" }}>
                      {item?.observation_text}
                    </p>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600" }}>
                      {item?.privatenote ? "PrivateNotes" : null}
                    </h5>
                    <p style={{ fontSize: "1.25em" }}>
                      {item?.privatenote_text}
                    </p>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600" }}>
                      {item?.treatment ? "Treatments" : null}
                    </h5>
                    <p style={{ fontSize: "1.25em" }}>{item?.treatment_text}</p>
                  </div>
                </div>
                {/* <textarea type="text" placeholder="session1" rows={6} style={{ width: "100%" }} /> */}
              </Col>
            ))}
            {/* <Col lg={12} className={`${styles.patient_flex_body_part}`}>
                        <textarea type="text" placeholder="session2" rows={6} style={{ width: "100%" }} />
                    </Col> */}
          </Row>
        </Container>
      ) : null}
      {compareList === compareSession[5].label ? (
        <Container className={`${styles.patient_flex}`}>
          <h2 style={{ marginLeft: "0.67em" }}>Reports</h2>
          <Row className={`${styles.patient_flex_body}`}>
            {report?.map((item, i) => (
              <>
                <Col lg={12} className={`${styles.patient_flex_body_part}`}>
                  {/* <textarea type="text" placeholder="session1" rows={6} style={{ width: "100%" }} /> */}
                  <div style={{ marginLeft: "0.4em" }}>
                    <div className={`${styles.session_flex_report}`}>
                      {item?.session}
                    </div>
                    <div
                      className={`${styles.profile_flex_child2}`}
                      key={`${i}`}
                    >
                      {item?.link ? (
                        <Link
                          className={styles.download_link}
                          to={item?.link}
                          target="_blank"
                          download
                        >
                          <div>
                            {/* <img src={uploadImages} alt="license_upload" /> */}
                            <span
                              style={{
                                marginLeft: "10px",
                                fontSize: "1.25em",
                                color: "#111",
                              }}
                            >
                              Download Report
                            </span>
                          </div>
                        </Link>
                      ) : (
                        <p style={{ fontSize: "1.25em" }}>
                          {"No reports available"}
                        </p>
                      )}
                    </div>
                  </div>
                </Col>
              </>
            ))}
            {/* <Col lg={12} className={`${styles.patient_flex_body_part}`}>
                        <textarea type="text" placeholder="session2" rows={6} style={{ width: "100%" }} />
                    </Col> */}
          </Row>
        </Container>
      ) : null}
    </>
  );
};

export default CompareSessionPage;
