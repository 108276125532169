import TextField from "@mui/material/TextField";
import style from "./fulltextfield.module.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});
const lightThemed = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const FullWidthTextField = ({ key,value, lightTheme, ...rest }) => {
  const { width } = useWindowSize();
  return (
    <>
    
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <TextField
            key={key}
            outline={false}
            value={value}
            className={style.fullwidth_input}
            fullWidth
            inputProps={{
              style: {
                fontSize: width > 768 ? "0.9em" : "0.9em",
                border:"none",
                padding:"12px",
                color:"#111"
              },
            }}
            sx={{
              "& fieldset": {
                borderRadius: width > 768 ? "7px" : "7px",
                border: "1px solid gray",
              },
              // input label when focused
              "& label": {
                lineHeight: width > 768 ? "0.8em" : "0.8em",
              },
              "& label.Mui-focused": {
                color: "#fff",
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                borderBottomColor: "#fff",
              },
              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: "#fff",
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              },
            }}
            {...rest}
          />
        </ThemeProvider>
    
    </>
  );
};

export default FullWidthTextField;
