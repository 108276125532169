import { useSelector } from "react-redux";
import styles from "./imagegallery.module.scss";
import { getImageGallery, getSessionList } from "../../redux/selector";
import { useState, useEffect } from "react";
import callApi from "../../util/apiCaller";
import RadioButton from "../../components/RadioButton/RadioButton";
import { Container, Row, Col, Card, Stack } from "react-bootstrap";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import HeaderV2 from "../../components/Header/HeaderV2";

const ImageGallery = () => {
  const navigate = useNavigate();
  const images = useSelector((state) => getImageGallery(state));
  const sessionList = useSelector((state) => getSessionList(state));
  const [sessionListId, setSessionListId] = useState("");
  const [imgId, setImgId] = useState("");
  const [imgPrimary, setImgPrimary] = useState(false);
  const [sessionDetail, setSessionDetail] = useState([]);
  const [imageGallery, setImageGallery] = useState(images);

  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    const fetchContent = async (sessionListId) => {
      if (sessionListId) {
        try {
          const res = await callApi(`sessionimage/list`, "post", {
            pageNum: 1,
            pageSize: 50,
            filters: {
              session_id: sessionListId,
            },
          });

          if (res && res?.status === "Success") {
            const { list } = res?.data;
            // setImgList(list)
            let arr = [...list];
            setImageGallery(arr);
            for (let item of arr) {
              if (item?.is_primary === true) {
                setImgId(item?._id);
              }
            }

            setImgPrimary(true);
          }
        } catch (err) {}
      }
    };
    fetchContent(sessionListId);
  }, [sessionListId]);

  // console.log(sessionListId);
  const sessionHandler = (keyName, keyValue) => {
    let arr = [...sessionList];
    if (keyName === "session_list") {
      for (let item of arr) {
        if (item?._id === keyValue) {
          setSessionListId(item?._id);
        }
      }
    }
  };
  const handlePrimaryImage = async (keyName, keyValue) => {
    let arr = [...imageGallery];
    if (keyName === "session_img") {
      for (let item of arr) {
        if (item?._id === keyValue) {
          setImgId(item?._id);
          const ress = await callApi(
            `sessionimage/${item?._id}/session/${sessionListId}/make-primary`,
            "post"
          );
        }
      }
    }
  };
  const ServicesCard = ({ heading, id, img, handlePrimaryImage }) => {
    return (
      <Card
      // style={{ maxWidth: "465px" }}
      // className={`${styles.services_card_wrapper}`}
      >
        <h3
          style={{ marginLeft: "10px", padding: "10px" }}
        >{`Image ${" "} ${heading}`}</h3>
        <Stack className={`${styles.gallery_flex}`}>
          <img
            src={img}
            width={"90%"}
            height={280}
            style={{ borderRadius: "10px" }}
          />
        </Stack>
        <Card.Body>
          {/* <div style={{display:"flex", justifyContent:"space-between", background:"#fbf1e9", padding:"10px 20px", borderRadius:"10px" }}>
              <span style={{fontSize:"22px"}}>Primary Image</span>
              </div>
              : */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fbf1e9",
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "1.375em" }}>
              {id === imgId ? "Primary Image" : "Make Image Primary"}
            </span>
            <span>
              {id === imgId ? null : (
                <ToggleSwitch
                  checked={id === imgId}
                  keyName={"session_img"}
                  handlePrimaryImage={handlePrimaryImage}
                  value={id}
                  labelId={`ss${id}`}
                />
              )}
            </span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  return (
    <>
      <Container className={styles.main}>
        <HeaderV2 />
        {/* <div
          style={{ margin: "10px 0", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
          <span style={{ fontSize: "1.25em" }}> Back</span>
        </div> */}
        <ul className={`${styles.radio_container}`}>
          {sessionList?.map((item, i) => (
            <RadioButton
              key={item._id}
              checked={item._id === sessionListId}
              value={item._id}
              keyName={"session_list"}
              label={`Session${" "}${i + 1}${" "}${new Date(item?.created_at)
                .toLocaleDateString()
                .slice(0, 10)}`}
              labelId={`er${item._id}`}
              patientDetailHandler={sessionHandler}
            />
          ))}
        </ul>

        <Row className={`${styles.services_card_wrapper}`}>
          {imageGallery?.map((item, i) => (
            <Col xs={12} lg={6} style={{ margin: "10px 0" }} key={item._id}>
              {" "}
              <ServicesCard
                heading={i + 1}
                img={item.url}
                id={item._id}
                handlePrimaryImage={handlePrimaryImage}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default ImageGallery;
