import style from "./toggleswitch.module.css";

const ToggleSwitch = ({labelId,value,handlePrimaryImage,checked,keyName}) => {
    return (
        <>
        <label className={`${style.switch}`} htmlFor={labelId}>
  <input type="checkbox" 
  checked={checked}
  id={labelId} 
  value={value}
  onChange={(e) => handlePrimaryImage(keyName,e.target.value)}
  />
  <span className={`${style.slider} ${style.round}`}></span>
</label>
        </>
    )
}

export default ToggleSwitch;