import style from "./autocompletefieldlighttheme.module.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";
import _ from "lodash";

const lighttheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const AutoCompleteFieldLightTheme = ({
  keyName,
  textLabel,
  id,
  data,
  handleUserDetail,
  value,
  ...rest
}) => {
  const { width } = useWindowSize();
  //let allData = _.uniq(data)
  // const filterOptions = createFilterOptions({
  //   matchFrom: 'start',
  //   stringify: (option) => option.toLowerCase(),
  // });
  return (
    <ThemeProvider theme={lighttheme}>
      <CssBaseline />
      <Autocomplete
        className={style.autocomplete_dropdown}
        id={id}
        options={data}
        defaultValue={value}
        clearOnEscape
        selectOnFocus
        autoHighlight
        // filterOptions={filterOptions}
        disableClearable
        getOptionLabel={(option) => option}
        onChange={(e, newValue) => {
          handleUserDetail(keyName, newValue);
        }}
        sx={{
          "& fieldset": {
            borderRadius: width > 768 ? "7px" : "7px",
            border: "1px solid #707070",
            // color: "#111",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: width > 768 ? "7px" : "7px",
            // border: "1px solid #111",
          },
          "& label": {
            lineHeight: width > 768 ? "1.2em" : "1.2em",
          },
          "& label.Mui-focused": {
            color: "#111",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: width > 768 ? "7px" : "7px",
            border: "1px solid #707070",
          },
          "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #707070",
            },
          "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#111",
            },
          "& .MuiSvgIcon-root ": {
            fill: "#f77b00 !important",
          },
        }}
        renderOption={(props, option) => (
          <Box className={style.option_list} component="li" {...props}>
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={textLabel}
            inputProps={{
              style: {
                fontSize: width > 768 ? "1.25em" : "1.25em",
                height: "1.em",
                // padding:"10px",
                border: "none",
              },
              ...params.inputProps,
              // autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
        {...rest}
      />
    </ThemeProvider>
  );
};

export default AutoCompleteFieldLightTheme;
