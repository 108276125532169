import styles from "../PatientDetailPage/patientdetailpage.module.scss";
import Header from "../Header/Header";
import { Container, Row, Col } from "react-bootstrap";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import callApi from "../../util/apiCaller";
import { colors } from "@mui/material";
import { getUserId, getPatientId } from "../../redux/selector";
import { addNewCases, updateCasesDetail } from "../../redux/action";
import {
  DOCTOR_ID,
  PATIENT_ID,
  USER_TYPE,
  CASE_ID,
} from "../../components/constant/authEnums";
import FullPageLoader from "../FullPageLoader";
//import { slice } from "lodash";
import { quoritLogo } from "../../components/images";
import Multiselect from "multiselect-react-dropdown";
import _ from "lodash";
import HeaderV2 from "../../components/Header/HeaderV2";
import { toast } from "react-toastify";
import { SuccessMsg } from "../../util/Message";
//import { Padding } from "@mui/icons-material";

const duration = ["Day(s)", "Week(s)", "Month(s)", "Year(s)"];
const woundMap = {
  "Anterior - Forehead": "forhead",
  "Anterior - face/ear": "face",
  "Anterior - neck/chest": "neck",
  "Anterior - stomach/abdomen": "stomach",
  "Anterior - pelvis/gential/perennial": "pelvin",
  "Anterior - Right thigh": "right_thigh",
  "Anterior - left thigh": "left_thigh",
  "Anterior - Right knee": "right_knee",
  "Anterior - left knee": "left_knee",
  "Anterior - Right leg": "right_leg",
  "Anterior - left leg": "left_leg",
  "Anterior - Right ankle": "right_ankle",
  "Anterior - left ankle": "left_ankle",
  "Anterior - Right toes": "right_toes",
  "Anterior - left toes": "left_toss",
  "Anterior - Right shoulder": "right_shoulder",
  "Anterior - Right Arm/Armpit": "left_shoulder",
  "Anterior - Right front of elbow": "right_before_elbo",
  "Anterior - Right forearm": "right_forearm",
  "Anterior - Right wrist/hand": "right_wrist",
  "Anterior - Right fingers": "right_finger",
  "Anterior - left shoulder": "left_shoulder",
  "Anterior - left Arm/Armpit": "left_arm",
  "Anterior - left front of elbow": "left_before_elbo",
  "Anterior - left forearm": "left_forearm",
  "Anterior - left wrist/hand": "left_wrist",
  "Anterior - left fingers": "left_finger",

  "Posterior - Forehead": "post_forhead",
  "Posterior - face/ear": "post_face",
  "Posterior - back neck": "post_neck",
  "Posterior - dorsum/back": "post_dorsum",
  "Posterior - waist/lumbus": "post_waist",
  "Posterior - Secrum/tailbone": "post_secrum",
  "Posterior - left buttocks": "post_left_buttocks",
  "Posterior - Right buttocks": "post_right_buttocks",
  "Posterior - left thigh/femoral": "post_left_thigh",
  "Posterior - Right thigh/femoral": "post_right_thigh",
  "Posterior - left popliteal/back of knee": "post_left_knee",
  "Posterior - Right popliteal/back of knee": "post_right_knee",
  "Posterior - left calf": "post_left_calf",
  "Posterior - right calf": "post_right_calf",
  "Posterior - left heel": "post_left_heel",
  "Posterior - right heel": "post_right_heel",
  "Posterior - left shoulder": "post_left_shoulder",
  "Posterior - left Back of Arm/Elbow": "post_left_back_arm",
  "Posterior - left Forearm": "post_left_forearm",
  "Posterior - left hand": "post_left_hand",
  "Posterior - left Foot sole": "post_left_foot_sole",
  "Posterior - Right shoulder": "post_right_shoulder",
  "Posterior - Right Back of Arm/Elbow": "post_right_back_arm",
  "Posterior - Right Forearm": "post_right_forearm",
  "Posterior - Right hand": "post_right_hand",
  "Posterior - Right Foot sole": "post_right_foot_sole",
};

const AddPatientPage = ({ data, caseId }) => {
  const userType = window.localStorage.getItem(USER_TYPE);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const doctorId = window.localStorage.getItem(DOCTOR_ID);
  const patientId = window.localStorage.getItem(PATIENT_ID);
  useEffect(() => {
    if (userType === "patient") {
      window.localStorage.setItem(PATIENT_ID, doctorId);
    }
  }, [userType]);
  const [woundImg, setWoundImg] = useState([]);
  const [woundLocation, setWoundLocation] = useState([]);
  const [path, setPath] = useState(true);
  const [errors, setErrors] = useState({});
  const [woundCause, setWoundCause] = useState();
  const [alcoholic, setAlcoholic] = useState("");
  const [nonAlcoholic, setNonAlcoholic] = useState("");
  const [smoking, setSmoking] = useState("");
  const [allergie, setAllergie] = useState("");
  const [nonAllergie, setNonAllergie] = useState("");
  const [nonSmoking, setNonSmoking] = useState("");
  const [woundType, setWoundType] = useState();
  const [woundNum, setWoundNum] = useState("");
  const [loading, setLoading] = useState(false);
  const [woundStr, setWoundStr] = useState("");
  const [woundLoc, setWoundLoc] = useState([]);
  const [mobility, setMobility] = useState();
  const [nutrition, setNutrition] = useState();
  const [booleanPath, setBooleanPath] = useState(false);
  const [wound, setWound] = useState({
    forhead: true,
    face: true,
    neck: true,
    stomach: true,
    pelvin: true,
    right_thigh: true,
    left_thigh: true,
    right_knee: true,
    left_knee: true,
    right_leg: true,
    left_leg: true,
    right_ankle: true,
    left_ankle: true,
    right_toes: true,
    left_toss: true,
    right_shoulder: true,
    left_shoulder: true,
    left_arm: true,
    right_arm: true,
    left_before_albo: true,
    right_before_albo: true,
    right_forearm: true,
    left_forearm: true,
    left_wrist: true,
    right_wrist: true,
    left_finger: true,
    right_finger: true,
    post_forhead: true,
    post_face: true,
    post_neck: true,
    post_dorsum: true,
    post_waist: true,
    post_secrum: true,
    post_left_buttocks: true,
    post_right_buttocks: true,
    post_left_thigh: true,
    post_right_thigh: true,
    post_left_knee: true,
    post_right_knee: true,
    post_left_calf: true,
    post_left_heel: true,
    post_right_calf: true,
    post_right_heel: true,
    post_left_shoulder: true,
    post_right_shoulder: true,
    post_left_back_arm: true,
    post_right_back_arm: true,
    post_left_forearm: true,
    post_right_forearm: true,
    post_left_hand: true,
    post_right_hand: true,
    post_left_foot_sole: true,
    post_right_foot_sole: true,
  });
  const [user, setUser] = useState({
    nutrition_state: "",
    mobility_state: "",
    smoking_condition: "",
    alcoholic_condition: "",
    last_session_at: new Date(),
    case_initiated_at: new Date(),
    // wound_location: [],
    wound_type: [],
    wound_cause: [],
    wound_image_location: [],

    wound_factors: "",
    medications: "",

    allergies: "",
    clinical_examination: "",
  });
  const [padnum, setPadnum] = useState(8);
  useEffect(() => {
    if (location.pathname === "/edit-session") {
      setBooleanPath(true);
      setPadnum(2);
    } else {
      setBooleanPath(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    async function fetchContents(code) {
      try {
        const res = await callApi(`content/code/${code}`, "get");

        if (res && res?.status === "Success") {
          const { params } = res?.data?.content;
          setWoundLocation(params);
          // let param = params?.map((options) => options?.label)
          // setContentCode(param,code)
        } else {
        }
      } catch (err) {}
    }
    fetchContents("wound-locations");
  }, []);

  useEffect(() => {
    if (location.pathname === "/edit-session") {
      setPath(false);
    } else {
      setPath(true);
    }
    setLoading(false);
  }, [location.pathname]);

  useEffect(() => {
    if (!_.isEmpty(data && woundLocation)) {
      let str = data?.wound_duration;
      let s1 = "";
      s1 = str.slice(2, str.length);
      setWoundStr(s1);
      if (data?.wound_duration) {
        let str1 = data?.wound_duration;
        let s = str1.slice(0, 1);
        setWoundNum(parseInt(s));

        //setUser({wound_duration : s1})
      }
      let arr1 = [...data?.wound_location];
      let arr = [...woundLocation];
      for (let key in woundMap) {
        if (arr1?.includes(key)) {
          setWound((prev) => ({ ...prev, [woundMap[key]]: false }));
        }
      }

      let result = [];
      arr1.forEach((item, i) => {
        arr.forEach((item1, j) => {
          if (item1.label === item) {
            result.push(item1);
          }
        });
        // result.push(item)
      });
      setWoundLoc([...result]);
      setUser({
        nutrition_state: data?.nutrition_state,
        mobility_state: data?.mobility_state,

        wound_type: data?.wound_type,
        wound_cause: data?.wound_cause,
        wound_image_location: data?.wound_image_location,

        wound_factors: data?.wound_factors,
        medications: data?.medications,
        smoking_condition: data?.smoking_condition,
        allergies: data?.allergies,
        alcoholic_condition: data?.alcoholic_condition,
        clinical_examination: data?.clinical_examination,
      });
      if (data?.alcoholic_condition === "Yes") {
        setAlcoholic(true);
      } else if (data?.alcoholic_condition === "No") {
        setNonAlcoholic(true);
      }
      if (data?.smoking_condition === "Yes") {
        setSmoking(true);
      } else if (data?.smoking_condition === "No") {
        setNonSmoking(true);
      }
      if (data?.allergies === "Yes") {
        setAllergie(true);
      } else if (data?.allergies === "No") {
        setNonAllergie(true);
      }
    }
  }, [data, woundLocation]);

  function setContentCode(params, code) {
    switch (code) {
      case "cause-wounds":
        setWoundCause(params);
        break;
      case "wound-types":
        setWoundType(params);
        break;
      case "mobility-types":
        setMobility(params);
        break;
      case "nutrition-types":
        setNutrition(params);
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    fetchContent("cause-wounds");
    fetchContent("wound-types");
    fetchContent("mobility-types");
    fetchContent("nutrition-types");
  }, []);
  async function fetchContent(code) {
    try {
      const res = await callApi(`content/code/${code}`, "get");

      if (res && res?.status === "Success") {
        const { params } = res?.data?.content;

        let param = params?.map((options) => options?.label);
        setContentCode(param, code);
      } else {
      }
    } catch (err) {}
  }

  const handlePatientDetail = (keyName, keyValue) => {
    let update = { ...user };
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    if (keyName === "wound_duration") {
      setWoundStr(keyValue);
    } else {
      if (keyName === "alcoholic_condition") {
        if (keyValue === "Yes") {
          setAlcoholic(true);
          setNonAlcoholic(false);
        } else if (keyValue === "No") {
          setAlcoholic(false);
          setNonAlcoholic(true);
        }
      }
      if (keyName === "smoking_condition") {
        if (keyValue === "Yes") {
          setSmoking(true);
          setNonSmoking(false);
        } else if (keyValue === "No") {
          setSmoking(false);
          setNonSmoking(true);
        }
      }
      if (keyName === "allergies") {
        if (keyValue === "Yes") {
          setAllergie(true);
          setNonAllergie(false);
        } else if (keyValue === "No") {
          setAllergie(false);
          setNonAllergie(true);
        }
      }
      if (keyName === "wound_location") {
        // let res = keyValue.map((item) => item?.label)
        // console.log(res);
        update[keyName] = [...keyValue];
      } else {
        update[keyName] = keyValue;
      }
    }
    setUser(update);
  };

  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (user?.wound_location?.length === 0) {
      isValid = false;
      errors["wound_location"] = "Wound location cannot be blank";
    }
    if (!user?.mobility_state) {
      isValid = false;
      errors["mobility_state"] = "Mobility cannot be blank";
    }
    if (user?.wound_cause?.length === 0) {
      isValid = false;
      errors["wound_cause"] = "Wound cause cannot be blank";
    }
    if (user?.wound_type?.length === 0) {
      isValid = false;
      errors["wound_type"] = "Wound type cannot be blank";
    }

    if (!user?.nutrition_state) {
      isValid = false;
      errors["nutrition_state"] = "Nutrition field cannot be blank";
    }
    // if (!user?.phone_number) {
    //     isValid = false;
    //     errors["profile_image"] = "Profile image cannot be blank"
    // }
    if (!user?.wound_duration) {
      isValid = false;
      errors["wound_duration"] = "Wound duration cannot be blank";
    }
    if (!user?.alcoholic_condition) {
      isValid = false;
      errors["alcoholic_condition"] = "Alcoholic condition cannot be blank";
    }
    if (!user?.smoking_condition) {
      isValid = false;
      errors["smoking_condition"] = "Smoking condition cannot be blank";
    }

    return {
      isValid,
      errors,
    };
  };
  const woundLocationHandler = (keyName, keyValue) => {
    let arr = [...woundLocation];
    let result = [...woundLoc];
    for (let item of arr) {
      if (item.value === keyName) {
        if (!result?.includes(item)) {
          result.push(item);
          setWound((prev) => ({ ...prev, [keyValue]: false }));
        } else {
          let arr1 = result.filter((option) => option.label !== item.label);
          setWound((prev) => ({ ...prev, [keyValue]: true }));
          result = [...arr1];
        }
      }
    }
    setWoundLoc(result);
  };

  const handleImgLocation = (e) => {
    setWoundLoc([...e]);
    let arr1 = [...e];
    let result = [];

    for (let item of arr1) {
      for (let key in woundMap) {
        if (key === item.label) {
          result.push(woundMap[key]);
        }
      }
    }
    for (let key in woundMap) {
      if (result?.includes(woundMap[key])) {
        setWound((prev) => ({ ...prev, [woundMap[key]]: false }));
      } else {
        setWound((prev) => ({ ...prev, [woundMap[key]]: true }));
      }
    }
    // setWoundImg([...result])
  };

  const NewCaseHandler = async () => {
    let update = {};
    let caseData = {};
    let wound = "";
    if (woundNum && woundStr) {
      wound = woundNum + " " + woundStr;
      // wound = woundNum?.concat(" ",woundStr)
    }
    let arr = [...woundLoc];
    let res = arr.map((item) => item.label);
    update.wound_duration = wound;
    update.doctor_id = doctorId;
    update.patient_id = patientId;
    update.business_id = "6597d3f68836f7c222e1a504";
    update.case_status = "Ongoing";
    update.wound_location = res;

    caseData.wound_duration = wound;
    // data.doctor_id = doctorId;
    caseData.patient_id = patientId;
    caseData.business_id = "6597d3f68836f7c222e1a504";
    caseData.case_status = "Ongoing";
    caseData.wound_location = res;

    let check = handleValidation({ ...update, ...user });
    if (userType === "patient") {
      if (check.isValid) {
        setLoading(true);
        const res = await callApi("case/new-patient-case", "post", {
          case: { ...caseData, ...user },
        });
        if (res && res.status === "Success") {
          window.localStorage.setItem(CASE_ID, res?.data?.case?._id);
          navigate("/add-new-session");
          setLoading(false);
          toast(SuccessMsg("New case has been added sucessfully"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        setErrors(check.errors);
      }
    } else if (userType === "doctor") {
      if (check.isValid && update?.doctor_id && update?.patient_id) {
        if (data && caseId) {
          dispatch(updateCasesDetail({ ...update, ...user }, caseId));
        } else {
          setLoading(true);
          addNewCases({ ...update, ...user }, navigate).then((res) => {
            if (res) {
              setLoading(false);
            }
          });
        }
      } else {
        setErrors(check.errors);
      }
    }
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error">{errors[keyName]}</p>
    ) : null;
  };

  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          {/* <img src={quoritLogo} alt="loader" className="img-fluid" style={{ maxWidth: "320px" }} /> */}
        </div>
      )}
      {path === false ? null : <HeaderV2 />}
      <Container
        className={`${styles.patient_flex}`}
        style={{ padding: `${padnum}em 0 0.688em` }}
      >
        {booleanPath ? null : <h2>Patient Details</h2>}
        <Row>
          <Col lg={6}>
            <div className={`${styles.case_flex}`}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "120px",
                }}
              >
                <span style={{ marginLeft: "106px" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/bodypart/Asset 60.png",
                        "forhead"
                      );
                    }}
                  >
                    {wound?.forhead ? (
                      <img
                        src="assets/bodypart/Asset 60.png"
                        alt=""
                        style={{ maxWidth: "80px" }}
                      />
                    ) : (
                      <img
                        src="assets/highlightedBodyPart/Asset 140.png"
                        style={{ maxWidth: "80px" }}
                        alt=""
                      />
                    )}
                  </span>

                  <spn
                    onClick={() => {
                      woundLocationHandler(
                        "assets/bodypart/Asset 59.png",
                        "face"
                      );
                    }}
                  >
                    {wound?.face ? (
                      <img
                        src="assets/bodypart/Asset 59.png"
                        alt=""
                        style={{ maxWidth: "80px" }}
                      />
                    ) : (
                      <img
                        src="assets/highlightedBodyPart/Asset 139.png"
                        style={{ maxWidth: "80px" }}
                        alt=""
                      />
                    )}
                  </spn>
                </span>
                <span style={{ display: "flex" }}>
                  <span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 56.png",
                          "right_shoulder"
                        );
                      }}
                    >
                      {wound?.right_shoulder ? (
                        <img
                          src="assets/bodypart/Asset 56.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 136.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 55.png",
                          "right_arm"
                        );
                      }}
                    >
                      {wound?.right_arm ? (
                        <img
                          src="assets/bodypart/Asset 55.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 135.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 46.png",
                          "right_before_albo"
                        );
                      }}
                    >
                      {wound?.right_before_albo ? (
                        <img
                          src="assets/bodypart/Asset 46.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 126.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 47.png",
                          "right_forearm"
                        );
                      }}
                    >
                      {wound?.right_forearm ? (
                        <img
                          src="assets/bodypart/Asset 47.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 127.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 48.png",
                          "right_wrist"
                        );
                      }}
                    >
                      {wound?.right_wrist ? (
                        <img
                          src="assets/bodypart/Asset 48.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 128.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 49.png",
                          "right_finger"
                        );
                      }}
                    >
                      {wound?.right_finger ? (
                        <img
                          src="assets/bodypart/Asset 49.png"
                          alt=""
                          style={{ maxWidth: "95px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 129.png"
                          style={{ maxWidth: "95px" }}
                          alt=""
                        />
                      )}
                    </span>
                  </span>

                  <span>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "98px",
                      }}
                    >
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/bodypart/Asset 58.png",
                            "neck"
                          );
                        }}
                      >
                        {wound?.neck ? (
                          <img
                            src="assets/bodypart/Asset 58.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/highlightedBodyPart/Asset 138.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        )}
                      </span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/bodypart/Asset 45.png",
                            "stomach"
                          );
                        }}
                      >
                        {wound?.stomach ? (
                          <img
                            src="assets/bodypart/Asset 45.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/highlightedBodyPart/Asset 125.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        )}
                      </span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/bodypart/Asset 44.png",
                            "pelvin"
                          );
                        }}
                      >
                        {wound?.pelvin ? (
                          <img
                            src="assets/bodypart/Asset 44.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/highlightedBodyPart/Asset 124.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        )}
                      </span>
                    </span>
                    <span style={{ display: "flex" }}>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/bodypart/Asset 42.png",
                            "right_thigh"
                          );
                        }}
                      >
                        {wound?.right_thigh ? (
                          <img
                            src="assets/bodypart/Asset 42.png"
                            alt=""
                            style={{ maxWidth: "47px" }}
                          />
                        ) : (
                          <img
                            src="assets/highlightedBodyPart/Asset 122.png"
                            style={{ maxWidth: "47px" }}
                            alt=""
                          />
                        )}
                      </span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/bodypart/Asset 43.png",
                            "left_thigh"
                          );
                        }}
                      >
                        {wound?.left_thigh ? (
                          <img
                            src="assets/bodypart/Asset 43.png"
                            alt=""
                            style={{ maxWidth: "47px", marginLeft: "2px" }}
                          />
                        ) : (
                          <img
                            src="assets/highlightedBodyPart/Asset 123.png"
                            style={{ maxWidth: "47px", marginLeft: "2px" }}
                            alt=""
                          />
                        )}
                      </span>
                    </span>
                  </span>

                  <span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 57.png",
                          "left_shoulder"
                        );
                      }}
                    >
                      {wound?.left_shoulder ? (
                        <img
                          src="assets/bodypart/Asset 57.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 137.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 54.png",
                          "left_arm"
                        );
                      }}
                    >
                      {wound?.left_arm ? (
                        <img
                          src="assets/bodypart/Asset 54.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 134.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 53.png",
                          "left_before_albo"
                        );
                      }}
                    >
                      {wound?.left_before_albo ? (
                        <img
                          src="assets/bodypart/Asset 53.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 133.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 52.png",
                          "left_forearm"
                        );
                      }}
                    >
                      {wound?.left_forearm ? (
                        <img
                          src="assets/bodypart/Asset 52.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 132.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 51.png",
                          "left_wrist"
                        );
                      }}
                    >
                      {wound?.left_wrist ? (
                        <img
                          src="assets/bodypart/Asset 51.png"
                          alt=""
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 131.png"
                          style={{ maxWidth: "100px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 50.png",
                          "left_finger"
                        );
                      }}
                    >
                      {wound?.left_finger ? (
                        <img
                          src="assets/bodypart/Asset 50.png"
                          alt=""
                          style={{ maxWidth: "100px", marginLeft: "3px" }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 130.png"
                          style={{ maxWidth: "100px", marginLeft: "3px" }}
                          alt=""
                        />
                      )}
                    </span>
                    {/* <img src="assets/bodypart/Asset 54.png" alt="" /> */}
                  </span>
                </span>

                <span style={{ display: "flex", marginLeft: "100px" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/bodypart/Asset 41.png",
                        "right_knee"
                      );
                    }}
                  >
                    {wound?.right_knee ? (
                      <img
                        src="assets/bodypart/Asset 41.png"
                        alt=""
                        style={{ maxWidth: "45px" }}
                      />
                    ) : (
                      <img
                        src="assets/highlightedBodyPart/Asset 121.png"
                        style={{ maxWidth: "45px" }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/bodypart/Asset 40.png",
                        "left_knee"
                      );
                    }}
                  >
                    {wound?.left_knee ? (
                      <img
                        src="assets/bodypart/Asset 40.png"
                        alt=""
                        style={{ maxWidth: "45px", marginLeft: "7px" }}
                      />
                    ) : (
                      <img
                        src="assets/highlightedBodyPart/Asset 120.png"
                        style={{ maxWidth: "45px", marginLeft: "7px" }}
                        alt=""
                      />
                    )}
                  </span>
                </span>
                <span style={{ display: "flex", marginLeft: "100px" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/bodypart/Asset 39.png",
                        "right_leg"
                      );
                    }}
                  >
                    {wound?.right_leg ? (
                      <img
                        src="assets/bodypart/Asset 39.png"
                        alt=""
                        style={{ maxWidth: "46px" }}
                      />
                    ) : (
                      <img
                        src="assets/highlightedBodyPart/Asset 119.png"
                        style={{ maxWidth: "46px" }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/bodypart/Asset 38.png",
                        "left_leg"
                      );
                    }}
                  >
                    {wound?.left_leg ? (
                      <img
                        src="assets/bodypart/Asset 38.png"
                        alt=""
                        style={{ maxWidth: "44px", marginLeft: "6px" }}
                      />
                    ) : (
                      <img
                        src="assets/highlightedBodyPart/Asset 118.png"
                        style={{ maxWidth: "44px", marginLeft: "6px" }}
                        alt=""
                      />
                    )}
                  </span>
                </span>
                <span style={{ display: "flex" }}>
                  <span style={{ marginLeft: "100px" }}>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 32.png",
                          "right_ankle"
                        );
                      }}
                    >
                      {wound?.right_ankle ? (
                        <img
                          src="assets/bodypart/Asset 32.png"
                          alt=""
                          style={{
                            maxWidth: "50px",
                            marginLeft: "10px",
                            marginTop: "-10px",
                          }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 112.png"
                          style={{
                            maxWidth: "50px",
                            marginLeft: "10px",
                            marginTop: "-10px",
                          }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 33.png",
                          "right_ankle"
                        );
                      }}
                    >
                      {wound?.right_ankle ? (
                        <img
                          src="assets/bodypart/Asset 33.png"
                          alt=""
                          style={{
                            maxWidth: "55px",
                            marginLeft: "-0px",
                            marginTop: "-30px",
                          }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 113.png"
                          style={{
                            maxWidth: "55px",
                            marginLeft: "-0px",
                            marginTop: "-30px",
                          }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 34.png",
                          "right_toes"
                        );
                      }}
                    >
                      {wound?.right_toes ? (
                        <img
                          src="assets/bodypart/Asset 34.png"
                          alt=""
                          style={{
                            maxWidth: "45px",
                            marginLeft: "-0px",
                            marginTop: "-52px",
                          }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 114.png"
                          style={{
                            maxWidth: "45px",
                            marginLeft: "-0px",
                            marginTop: "-52px",
                          }}
                          alt=""
                        />
                      )}
                    </span>
                  </span>
                  <span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 37.png",
                          "left_ankle"
                        );
                      }}
                    >
                      {wound?.left_ankle ? (
                        <img
                          src="assets/bodypart/Asset 37.png"
                          alt=""
                          style={{
                            maxWidth: "50px",
                            marginLeft: "-20px",
                            marginTop: "-10px",
                          }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 117.png"
                          style={{
                            maxWidth: "50px",
                            marginLeft: "-20px",
                            marginTop: "-10px",
                          }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 36.png",
                          "left_ankle"
                        );
                      }}
                    >
                      {wound?.left_ankle ? (
                        <img
                          src="assets/bodypart/Asset 36.png"
                          alt=""
                          style={{
                            maxWidth: "55px",
                            marginLeft: "-13px",
                            marginTop: "-30px",
                          }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 116.png"
                          style={{
                            maxWidth: "55px",
                            marginLeft: "-13px",
                            marginTop: "-30px",
                          }}
                          alt=""
                        />
                      )}
                    </span>

                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/bodypart/Asset 35.png",
                          "left_toss"
                        );
                      }}
                    >
                      {wound?.left_toss ? (
                        <img
                          src="assets/bodypart/Asset 35.png"
                          alt=""
                          style={{
                            maxWidth: "45px",
                            marginLeft: "0px",
                            marginTop: "-53px",
                          }}
                        />
                      ) : (
                        <img
                          src="assets/highlightedBodyPart/Asset 115.png"
                          style={{
                            maxWidth: "45px",
                            marginLeft: "0px",
                            marginTop: "-53px",
                          }}
                          alt=""
                        />
                      )}
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </Col>
          <Col lg={6}>
            {/* posterior */}
            <div className={`${styles.case_flex}`}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "140px",
                }}
              >
                <span style={{ marginLeft: "105px" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 81.png",
                        "post_forhead"
                      );
                    }}
                  >
                    {wound?.post_forhead ? (
                      <img
                        src="assets/newPosterior/Asset 81.png"
                        alt=""
                        style={{ maxWidth: "80px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 186.png"
                        style={{ maxWidth: "80px" }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 83.png",
                        "post_face"
                      );
                    }}
                  >
                    {wound?.post_face ? (
                      <img
                        src="assets/newPosterior/Asset 83.png"
                        alt=""
                        style={{ maxWidth: "80px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 180.png"
                        style={{ maxWidth: "80px" }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 80.png",
                        "post_neck"
                      );
                    }}
                  >
                    {wound?.post_neck ? (
                      <img
                        src="assets/newPosterior/Asset 80.png"
                        alt=""
                        style={{ maxWidth: "80px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 179.png"
                        style={{ maxWidth: "80px" }}
                        alt=""
                      />
                    )}
                  </span>
                </span>
                <span style={{ display: "flex" }}>
                  <span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 62.png",
                          "post_left_shoulder"
                        );
                      }}
                    >
                      {wound?.post_left_shoulder ? (
                        <img
                          src="assets/newPosterior/Asset 62.png"
                          alt=""
                          style={{ maxWidth: "98px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 161.png"
                          style={{ maxWidth: "98px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 63.png",
                          "post_left_back_arm"
                        );
                      }}
                    >
                      {wound?.post_left_back_arm ? (
                        <img
                          src="assets/newPosterior/Asset 63.png"
                          alt=""
                          style={{ maxWidth: "98px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 162.png"
                          style={{ maxWidth: "98px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 66.png",
                          "post_left_forearm"
                        );
                      }}
                    >
                      {wound?.post_left_forearm ? (
                        <img
                          src="assets/newPosterior/Asset 66.png"
                          alt=""
                          style={{ maxWidth: "94px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 165.png"
                          style={{ maxWidth: "94px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 64.png",
                          "post_left_hand"
                        );
                      }}
                    >
                      {wound?.post_left_hand ? (
                        <img
                          src="assets/newPosterior/Asset 64.png"
                          alt=""
                          style={{ maxWidth: "93px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 163.png"
                          style={{ maxWidth: "93px" }}
                          alt=""
                        />
                      )}
                    </span>
                  </span>

                  <span>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "135px",
                      }}
                    >
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/newPosterior/Asset 79.png",
                            "post_dorsum"
                          );
                        }}
                      >
                        {wound?.post_dorsum ? (
                          <img
                            src="assets/newPosterior/Asset 79.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/posteriorHighlighted/Asset 178.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        )}
                      </span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/newPosterior/Asset 78.png",
                            "post_waist"
                          );
                        }}
                      >
                        {wound?.post_waist ? (
                          <img
                            src="assets/newPosterior/Asset 78.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/posteriorHighlighted/Asset 177.png"
                            style={{ maxWidth: "98px" }}
                            alt=""
                          />
                        )}
                      </span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/newPosterior/Asset 77.png",
                            "post_secrum"
                          );
                        }}
                      >
                        {wound?.post_secrum ? (
                          <img
                            src="assets/newPosterior/Asset 77.png"
                            style={{
                              maxWidth: "98px",
                              height: "22px",
                              marginTop: "-2px",
                            }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/posteriorHighlighted/Asset 176.png"
                            style={{
                              maxWidth: "98px",
                              height: "22px",
                              marginTop: "-2px",
                            }}
                            alt=""
                          />
                        )}
                      </span>
                      <span>
                        <span
                          onClick={() => {
                            woundLocationHandler(
                              "assets/newPosterior/Asset 76.png",
                              "post_left_buttocks"
                            );
                          }}
                        >
                          {wound?.post_left_buttocks ? (
                            <img
                              src="assets/newPosterior/Asset 76.png"
                              style={{ maxWidth: "48px", marginLeft: "0px" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src="assets/posteriorHighlighted/Asset 185.png"
                              style={{ maxWidth: "48px", marginLeft: "0px" }}
                              alt=""
                            />
                          )}
                        </span>
                        <span
                          onClick={() => {
                            woundLocationHandler(
                              "assets/newPosterior/Asset 84.png",
                              "post_right_buttocks"
                            );
                          }}
                        >
                          {wound?.post_right_buttocks ? (
                            <img
                              src="assets/newPosterior/Asset 84.png"
                              style={{ maxWidth: "48px", marginLeft: "0px" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src="assets/posteriorHighlighted/Asset 175.png"
                              style={{ maxWidth: "48px", marginLeft: "0px" }}
                              alt=""
                            />
                          )}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/newPosterior/Asset 75.png",
                            "post_left_thigh"
                          );
                        }}
                      >
                        {wound?.post_left_thigh ? (
                          <img
                            src="assets/newPosterior/Asset 75.png"
                            alt=""
                            style={{ maxWidth: "47px", marginLeft: "2px" }}
                          />
                        ) : (
                          <img
                            src="assets/posteriorHighlighted/Asset 174.png"
                            style={{ maxWidth: "47px", marginLeft: "2px" }}
                            alt=""
                          />
                        )}
                      </span>
                      <span
                        onClick={() => {
                          woundLocationHandler(
                            "assets/newPosterior/Asset 85.png",
                            "post_right_thigh"
                          );
                        }}
                      >
                        {wound?.post_right_thigh ? (
                          <img
                            src="assets/newPosterior/Asset 85.png"
                            alt=""
                            style={{ maxWidth: "47px", marginLeft: "0px" }}
                          />
                        ) : (
                          <img
                            src="assets/posteriorHighlighted/Asset 184.png"
                            style={{ maxWidth: "47px", marginLeft: "-1px" }}
                            alt=""
                          />
                        )}
                      </span>
                    </span>
                  </span>

                  <span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 69.png",
                          "post_right_shoulder"
                        );
                      }}
                    >
                      {wound?.post_right_shoulder ? (
                        <img
                          src="assets/newPosterior/Asset 69.png"
                          alt=""
                          style={{ maxWidth: "103px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 168.png"
                          style={{ maxWidth: "98px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 68.png",
                          "post_right_back_arm"
                        );
                      }}
                    >
                      {wound?.post_right_back_arm ? (
                        <img
                          src="assets/newPosterior/Asset 68.png"
                          alt=""
                          style={{ maxWidth: "98px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 167.png"
                          style={{ maxWidth: "98px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 67.png",
                          "post_right_forearm"
                        );
                      }}
                    >
                      {wound?.post_right_forearm ? (
                        <img
                          src="assets/newPosterior/Asset 67.png"
                          alt=""
                          style={{ maxWidth: "98px", marginLeft: "3px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 166.png"
                          style={{ maxWidth: "98px", marginLeft: "3px" }}
                          alt=""
                        />
                      )}
                    </span>
                    <span
                      onClick={() => {
                        woundLocationHandler(
                          "assets/newPosterior/Asset 65.png",
                          "post_right_hand"
                        );
                      }}
                    >
                      {wound?.post_right_hand ? (
                        <img
                          src="assets/newPosterior/Asset 65.png"
                          alt=""
                          style={{ maxWidth: "95px", marginLeft: "3px" }}
                        />
                      ) : (
                        <img
                          src="assets/posteriorHighlighted/Asset 164.png"
                          style={{ maxWidth: "93px", marginLeft: "3px" }}
                          alt=""
                        />
                      )}
                    </span>
                  </span>
                </span>

                <span style={{ display: "flex", marginLeft: "100px" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 74.png",
                        "post_left_knee"
                      );
                    }}
                  >
                    {wound?.post_left_knee ? (
                      <img
                        src="assets/newPosterior/Asset 74.png"
                        alt=""
                        style={{
                          maxWidth: "47px",
                          height: "49px",
                          marginLeft: "0px",
                        }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 173.png"
                        style={{
                          maxWidth: "47px",
                          height: "49px",
                          marginLeft: "0px",
                        }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 86.png",
                        "post_right_knee"
                      );
                    }}
                  >
                    {wound?.post_right_knee ? (
                      <img
                        src="assets/newPosterior/Asset 86.png"
                        alt=""
                        style={{
                          maxWidth: "49px",
                          height: "49px",
                          marginLeft: "-1px",
                        }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 183.png"
                        style={{
                          maxWidth: "49px",
                          height: "49px",
                          marginLeft: "-1px",
                        }}
                        alt=""
                      />
                    )}
                  </span>
                </span>
                <span style={{ display: "flex", marginLeft: "100px" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 73.png",
                        "post_left_calf"
                      );
                    }}
                  >
                    {wound?.post_left_calf ? (
                      <img
                        src="assets/newPosterior/Asset 73.png"
                        alt=""
                        style={{ maxWidth: "47px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 172.png"
                        style={{ maxWidth: "47px", height: "94px" }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 87.png",
                        "post_right_calf"
                      );
                    }}
                  >
                    {wound?.post_right_calf ? (
                      <img
                        src="assets/newPosterior/Asset 87.png"
                        alt=""
                        style={{ maxWidth: "47px", marginLeft: "0px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 182.png"
                        style={{
                          maxWidth: "47px",
                          height: "94px",
                          marginLeft: "0px",
                        }}
                        alt=""
                      />
                    )}
                  </span>
                </span>
                <span style={{ display: "flex" }}>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 70.png",
                        "post_left_foot_sole"
                      );
                    }}
                  >
                    {wound?.post_left_foot_sole ? (
                      <img
                        src="assets/newPosterior/Asset 70.png"
                        alt=""
                        style={{
                          maxWidth: "28px",
                          marginLeft: "97px",
                          marginTop: "-10px",
                        }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 169.png"
                        style={{
                          maxWidth: "28px",
                          marginLeft: "97px",
                          marginTop: "-10px",
                        }}
                        alt=""
                      />
                    )}
                  </span>

                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 88.png",
                        "post_left_heel"
                      );
                    }}
                  >
                    {wound?.post_left_heel ? (
                      <img
                        src="assets/newPosterior/Asset 88.png"
                        alt=""
                        style={{
                          maxWidth: "43px",
                          marginLeft: "-4px",
                          paddingRight: "5px",
                        }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 181.png"
                        style={{
                          maxWidth: "43px",
                          marginLeft: "-4px",
                          paddingRight: "5px",
                        }}
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 72.png",
                        "post_right_heel"
                      );
                    }}
                  >
                    {wound?.post_right_heel ? (
                      <img
                        src="assets/newPosterior/Asset 72.png"
                        alt=""
                        style={{ maxWidth: "45px", marginLeft: "-30px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 171.png"
                        style={{ maxWidth: "45px", marginLeft: "-30px" }}
                        alt=""
                      />
                    )}
                  </span>

                  <span
                    onClick={() => {
                      woundLocationHandler(
                        "assets/newPosterior/Asset 71.png",
                        "post_right_foot_sole"
                      );
                    }}
                  >
                    {wound?.post_right_foot_sole ? (
                      <img
                        src="assets/newPosterior/Asset 71.png"
                        alt=""
                        style={{ maxWidth: "22px", marginLeft: "0px" }}
                      />
                    ) : (
                      <img
                        src="assets/posteriorHighlighted/Asset 170.png"
                        style={{ maxWidth: "22px", marginLeft: "0px" }}
                        alt=""
                      />
                    )}
                  </span>
                </span>
              </span>
            </div>
          </Col>
          <div></div>
        </Row>

        <Row className={`${styles.patient_flex_body}`}>
          <Col lg={6} className={`${styles.patient_flex_body_part}`}>
            <div className={`${styles.patient_flex_child}`}>
              <label>
                Wound Location <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                options={woundLocation}
                placeholder="Wound location"
                //name="label"
                value={woundLoc}
                onChange={(e) => handleImgLocation(e)}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    color: "#111",
                    padding: "10px 0",
                    border: "1px solid black",
                    borderRadius: "10px",
                  }),
                  multiValue: (styles, state) => {
                    return {
                      ...styles,
                      backgroundColor: "#fbf1e9",
                      color: "#111",
                    };
                  },
                }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    text: "black",
                    primary25: "#fbf1e9",
                    primary: "black",
                  },
                })}
                isMulti
              />
              {/* <Multiselect
                                options={woundLocation} // Options to display in the dropdown
                                selectedValues={user?.wound_location} // Preselected value to persist in dropdown
                               // optionValueDecorator={user?.wound_location}
                                onSelect={(e) => handlePatientDetail("wound_location",e)} // Function will trigger on select event
                                onRemove={(e) => handlePatientDetail("wound_location",e)} // Function will trigger on remove event
                                displayValue="label" // Property name to display in the dropdown options
                                /> */}
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("wound_location")}
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <SelectDropdown
                data={woundCause}
                labelId={"wound2"}
                label={"Wound Cause"}
                keyName={"wound_cause"}
                value={user?.wound_cause}
                handleUserDetail={handlePatientDetail}
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("wound_cause")}
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <SelectDropdown
                data={woundType}
                labelId={"wound12"}
                label={"Wound Type"}
                keyName={"wound_type"}
                value={user?.wound_type}
                handleUserDetail={handlePatientDetail}
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("wound_type")}
            </div>
            {/* <div className={`${styles.patient_flex_child}`}>
                        <SelectDropdown labelId={"wound13"} label={"wound duration"}/>
                        </div> */}
            <div className={`${styles.patient_flex_child}`}>
              <SelectDropdown
                data={mobility}
                labelId={"wound14"}
                label={"Mobility"}
                keyName={"mobility_state"}
                value={user?.mobility_state}
                handleUserDetail={handlePatientDetail}
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("mobility_state")}
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <SelectDropdown
                data={nutrition}
                labelId={"wound15"}
                label={"Nutrition"}
                keyName={"nutrition_state"}
                value={user?.nutrition_state}
                handleUserDetail={handlePatientDetail}
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("nutrition_state")}
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <div>
                <label>
                  Wound Duration<span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className={`${styles.wound_duration_case}`}>
                <input
                  type="number"
                  //class="form-control"
                  placeholder="Number"
                  style={{ marginRight: "10px", borderColor: "gray" }}
                  value={woundNum}
                  onChange={(e) => {
                    setWoundNum(e.target.value);
                    //handlePatientDetail("wound_duration", e.target.value)
                  }}
                />
                <SelectDropdown
                  data={duration}
                  labelId={"wound16"}
                  label={"Wound Duration"}
                  keyName={"wound_duration"}
                  value={user?.wound_duration ? user?.wound_duration : woundStr}
                  handleUserDetail={handlePatientDetail}
                />
              </div>
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("wound_duration")}
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <div>
                <label>
                  Alcholic<span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className={styles.radio_flex}>
                <input
                  type="radio"
                  checked={alcoholic}
                  value={"Yes"}
                  onChange={(e) =>
                    handlePatientDetail("alcoholic_condition", e.target.value)
                  }
                />
                <label>Yes</label>
                <input
                  type="radio"
                  checked={nonAlcoholic}
                  value={"No"}
                  onChange={(e) =>
                    handlePatientDetail("alcoholic_condition", e.target.value)
                  }
                />
                <label>No</label>
              </div>
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("alcoholic_condition")}
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <div>
                <label>
                  Smoker<span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className={styles.radio_flex}>
                <input
                  type="radio"
                  checked={smoking}
                  value={"Yes"}
                  onChange={(e) =>
                    handlePatientDetail("smoking_condition", e.target.value)
                  }
                />
                <label>Yes</label>
                <input
                  type="radio"
                  checked={nonSmoking}
                  value={"No"}
                  onChange={(e) =>
                    handlePatientDetail("smoking_condition", e.target.value)
                  }
                />
                <label>No</label>
              </div>
            </div>
            <div style={{ marginLeft: "40px" }}>
              {renderError("smoking_condition")}
            </div>
          </Col>
          <Col lg={6} className={`${styles.patient_flex_body_part}`}>
            <div className={`${styles.patient_flex_child}`}>
              <div>
                <label>Allergies</label>
              </div>
              <div className={styles.radio_flex}>
                <input
                  type="radio"
                  checked={allergie}
                  value={"Yes"}
                  onChange={(e) =>
                    handlePatientDetail("allergies", e.target.value)
                  }
                />
                <label>Yes</label>
                <input
                  type="radio"
                  checked={nonAllergie}
                  value={"No"}
                  onChange={(e) =>
                    handlePatientDetail("allergies", e.target.value)
                  }
                />
                <label>No</label>
              </div>
            </div>
            <div
              className={`${styles.patient_flex_child}`}
              style={{ borderRadius: "10px", borderColor: "#111" }}
            >
              <div>
                <label>Clinical Examination</label>
              </div>
              <textarea
                type="text"
                style={{ borderRadius: "10px", borderColor: "#111" }}
                rows={4}
                class="form-control"
                placeholder="Enter Details"
                value={user?.clinical_examination}
                onChange={(e) =>
                  handlePatientDetail("clinical_examination", e.target.value)
                }
              />
            </div>
            <div className={`${styles.patient_flex_child}`}>
              <div>
                <label>Medications</label>
              </div>

              <textarea
                type="text"
                style={{ borderRadius: "10px", borderColor: "#111" }}
                rows={4}
                class="form-control"
                placeholder="Enter Details"
                value={user?.medications}
                onChange={(e) =>
                  handlePatientDetail("medications", e.target.value)
                }
              />
            </div>
            <div
              className={`${styles.patient_flex_child}`}
              style={{ borderRadius: "10px", borderColor: "#111" }}
            >
              <div>
                <label>other factors which could delay healing</label>
              </div>
              <textarea
                type="text"
                style={{ borderRadius: "10px", borderColor: "#111" }}
                rows={4}
                class="form-control"
                placeholder="Enter Details"
                value={user?.wound_factors}
                onChange={(e) =>
                  handlePatientDetail("wound_factors", e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
        <div className={`${styles.patient_flex_btn}`}>
          <button onClick={NewCaseHandler}>
            {path === false ? "Save Cases" : "Add New Cases"}
          </button>
        </div>
      </Container>
    </>
  );
};

export default AddPatientPage;
