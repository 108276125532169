import React, { useEffect, useState } from "react";
import { Card, Container, Stack } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
// import { Swiper} from 'swiper/react';
import styles from "./sessionImage.module.scss";
import callApi from "../../util/apiCaller";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
// Import Swiper styles
import { getImageGallery } from "../../redux/selector";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import { useParams } from "react-router-dom";
import HeaderV2 from "../../components/Header/HeaderV2";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";

const SessionImage = () => {
  const images = useSelector((state) => getImageGallery(state));
  const [imgId, setImgId] = useState("");
  const [sessionImage, setSessionImage] = useState([]);
  const [remove, setRemove] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    async function fetchContent(id) {
      const res = await callApi(`sessionimage/list`, "post", {
        pageNum: 1,
        pageSize: 50,
        filters: {
          session_id: id,
        },
      });

      if (res && res?.status === "Success") {
        const { list } = res?.data;
        // setImgList(list)
        let arr = [...list];
        setSessionImage(arr);
      }
    }
    if (id) {
      fetchContent(id);
    }
  }, [id, remove]);
  const handleDeleteImg = async (id) => {
    if (window.confirm("Do you want to delete session Image?") === true) {
      if (sessionImage.length > 1) {
        const res = await callApi(`sessionimage/${id}/remove`, "post", {
          is_deleted: true,
        });
        if (res && res.status === "Success") {
          setRemove(!remove);
          toast(
            SuccessMsg("Current session Image has been sucessfully deleted "),
            {
              className: "mail_toast",
              position: "top-center",
              hideProgressBar: true,
              closeButton: false,
              theme: "colored",
            }
          );
        } else {
          toast(SuccessMsg("Something went wrong"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Image should be more than one"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } else {
    }
  };
  const handlePrimaryImage = async (keyName, keyValue) => {
    let arr = [...images];
    if (keyName === "session_img") {
      for (let item of arr) {
        if (item?._id === keyValue) {
          setImgId(item?._id);
          const ress = await callApi(
            `sessionimage/${item?._id}/session/${id}/make-primary`,
            "post"
          );
        }
      }
    }
  };
  const TestimonialCard = ({ img, id }) => {
    return (
      <Card>
        <Card.Body>
          <div>
            <div className={`${styles.img_flex}`}>
              <img src={img} />
            </div>
            <div
              className={`${styles.image_delete_btn}`}
              onClick={() => handleDeleteImg(id)}
            >
              <button>{"DELETE IMAGE"}</button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fbf1e9",
              padding: "10px 20px",
              borderRadius: "10px",
              marginBottom: "2em",
            }}
          >
            <span style={{ fontSize: "1.375em" }}>
              {id === imgId ? "Primary Image" : "Make Image Primary"}
            </span>
            <span>
              {id === imgId ? null : (
                <ToggleSwitch
                  checked={id === imgId}
                  keyName={"session_img"}
                  handlePrimaryImage={handlePrimaryImage}
                  value={id}
                  labelId={`ss${id}`}
                />
              )}
            </span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  return (
    <>
      <HeaderV2 />

      <Container fluid className={styles.main}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          navigation={true}
          zoom={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination, Zoom]}
          className="mySwiper"
          style={{
            width: "90%",
            "--swiper-navigation-color": "#ed8847",
            "--swiper-pagination-color": "#ed8847",
          }}
        >
          {sessionImage?.map((item) => (
            <SwiperSlide key={item?._id}>
              <TestimonialCard img={item?.url} id={item?._id} />
            </SwiperSlide>
          ))}
          {/* <SwiperSlide><TestimonialCard /></SwiperSlide>
    <SwiperSlide><TestimonialCard /></SwiperSlide>
    <SwiperSlide><TestimonialCard /></SwiperSlide>
    <SwiperSlide><TestimonialCard /></SwiperSlide> */}
        </Swiper>
      </Container>
    </>
  );
};

export default SessionImage;
