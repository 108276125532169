import { Container, Stack } from "react-bootstrap";
import styles from "./verifyOtpPage.module.scss";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import './otp.scss';
import { useDispatch } from "react-redux";
import {
  VerifyDoctorViaOtp,
  VerifyPatientViaOtp,
  loginDoctorViaEmailOTP,
  loginDoctorViaPhoneOTP,
  loginPatientViaEmailOTP,
  loginPatientViaPhoneOTP,
} from "../../redux/action";
import { getUser } from "../../redux/selector";
import { USER_TYPE } from "../../components/constant/authEnums";
import { Link } from "react-router-dom";

const VerifyOtpPage = () => {
  const doctor = useSelector((state) => getUser(state));
  const userType = window.localStorage.getItem(USER_TYPE);
  const [otpVal, setOtpVal] = useState({
    otp: "",
  });
  const navigate = useNavigate();
  const [doctorDetail, setDoctorDetail] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (doctor) {
      setDoctorDetail(doctor);
    }
  }, [doctor]);

  const handleOtpChange = (keyName, keyValue) => {
    let update = { ...otpVal };
    update[keyName] = keyValue;
    setErrors({});
    if (doctor?.email) {
      update.contact_email = doctor?.email;
    }
    if (doctor?.phone_number) {
      update.country_code = doctor?.country_code;
      update.phone_number = doctor?.phone_number;
    }
    setOtpVal(update);
  };

  const handleOtpValidation = (otpVal) => {
    let isValid = true;
    let errors = {};
    if (!otpVal?.otp) {
      isValid = false;
      errors["otp"] = "Please enter your otp";
    } else if (otpVal?.otp.length !== 6) {
      isValid = false;
      errors["otp"] = "Otp should of 6 digit";
    }
    return {
      isValid,
      errors,
    };
  };

  const verifyHandler = () => {
    const check = handleOtpValidation(otpVal);
    if (check?.isValid) {
      if (userType === "doctor") {
        dispatch(VerifyDoctorViaOtp(otpVal, navigate));
      } else if (userType === "patient") {
        dispatch(VerifyPatientViaOtp(otpVal, navigate));
      }
    } else {
      setErrors(check.errors);
    }
  };
  // const Msg = () => (
  //     <div className="otp_box">
  //       <img
  //         width={39}
  //         height={39}
  //         src={checkiconWhite}
  //         className="otp_resend_icon"
  //         loading="lazy"
  //         alt="icon"
  //       />
  //       <p className="title">OTP reshared</p>
  //     </div>
  //   );
  const handleResend = () => {
    // debugger;
    if (doctorDetail) {
      if (userType === "doctor") {
        if (doctorDetail?.email) {
          dispatch(
            loginDoctorViaEmailOTP(
              {
                email: doctorDetail?.email,
              },
              navigate
            )
          );
        } else if (doctorDetail?.phone_number) {
          dispatch(
            loginDoctorViaPhoneOTP(
              {
                country_code: doctorDetail?.country_code,
                phone_number: doctorDetail?.phone_number,
              },
              navigate
            )
          );
        }
      }
      if (userType === "patient") {
        if (doctorDetail?.email) {
          dispatch(
            loginPatientViaEmailOTP(
              {
                email: doctorDetail?.email,
              },
              navigate
            )
          );
        } else if (doctorDetail?.phone_number) {
          dispatch(
            loginPatientViaPhoneOTP(
              {
                country_code: doctorDetail?.country_code,
                phone_number: doctorDetail?.phone_number,
              },
              navigate
            )
          );
        }
      }
    }
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error my-1">{errors[keyName]}</p>
    ) : null;
  };
  return (
    <>
      <div className={`${styles.login_flex}`}>
        <div className={`${styles.login_flex_child}`}>
          <div className={`${styles.login_flex_child_sub1}`}>
            <img src="images/quorit_logo_img.png" alt="" />
            <h1>LOGIN</h1>
            <p>Log in with Mobile Number/Email ID</p>
          </div>

          <div className={`${styles.login_flex2}`}>
            <div className={`${styles.login_text_verify}`}>
              <h3>OTP Verification</h3>
            </div>
            <div className={`${styles.login_flex_child_subtext2}`}>
              {doctor?.phone_number ? (
                <>
                  {" "}
                  <span style={{ color: "gray" }}>
                    Enter the OTP sent to{" "}
                    <b style={{ color: "black" }}>{doctor?.phone_number}</b>
                  </span>
                  <button
                    className={`${styles.otp_number_edit}`}
                    onClick={() => {
                      navigate("/login-otp");
                    }}
                  >
                    Edit
                  </button>
                  <img src="/images/edit_icon.svg" alt="" />
                </>
              ) : null}
            </div>
            <div
              className={`${styles.otp_input_wrapper}`}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <OtpInput
                value={otpVal?.otp}
                onChange={(e) => handleOtpChange("otp", e)}
                numInputs={6}
                inputStyle={`${styles.otp_input}`}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              {renderError("otp")}
            </div>
            <div className={`${styles.verify_otp_resend}`}>
              <p>Didn't Receive the OTP?</p>
              <Link className={styles.flex_resend} onClick={handleResend}>
                RESEND OTP
              </Link>
            </div>
            <div className={`${styles.verify_otp_btn}`}>
              <button
                className={`${styles.verify_otp_btn_child}`}
                onClick={verifyHandler}
              >
                VERIFY & PROCEED
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtpPage;
