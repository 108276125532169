//import { useNavigate } from "react-router-dom";
//import { protag } from "../../images";
import style from "./radiobutton3.module.scss";
//import { regiserOTPURL } from "../helpers/constant-words";

const RadioButton = ({ label, labelId,value, patientDetailHandler,keyName, isPro, extraSpace = false, yearBox = false, checked  }) => {
  return (
    <>
      <li className={style.radio_wrapper} >
        {/* {isPro === true && ( */}
          {/* <div
            
            className={style.protag_img}>
            {/* loading="lazy" */}
          {/* </div>  */}
        {/* )} */}
        <input
          className={style.radio_input}
          checked={checked}
          type="radio"
          value={value}
          id={labelId}
           onChange={(e)=> patientDetailHandler(keyName, e.target.value)}
        />
        <label
          className={`${style.radio_label} ${
            extraSpace === true && style.extraSpace
          }`}
          htmlFor={labelId}
        >
          {label}
        </label>
      </li>
    </>
  );
};

export default RadioButton;