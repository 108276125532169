import { Container, Col, Row } from "react-bootstrap";
import styles from "./editsession.module.scss";
// import style from "../AddNewSession/addnewsession.module.css";
import Header from "../Header/Header";
import RadioButton from "../../components/RadioButton/RadioButton";
import RadioButton3 from "../../components/RadioButton3/RadioButton3";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import session from "../../components/constant/sessionConstant.json";
import { useEffect, useState, useRef } from "react";
import {
  Outlet,
  useNavigate,
  NavLink,
  Link,
  useLocation,
} from "react-router-dom";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import { humanFork, wound_img } from "../../components/images";
import AddNewSession from "../AddNewSession/AddNewSession";
import PatientDetailPage from "../PatientDetailPage/PatientDetailPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getCaseDetail,
  getCaseId,
  getPatientDetail,
  getPatientId,
  getRefferedDoctor,
  getUserId,
} from "../../redux/selector";
import AddPatientPage from "../AddPatientPage/AddPatientPage";
import callApi, { callUploadApi } from "../../util/apiCaller";
import {
  CASE_ID,
  PATIENT_ID,
  REFFERED_FROM,
  SESSION_CHAT_ID,
} from "../../components/constant/authEnums";
import {
  setImageGallery,
  setSessionChatId,
  setSessionList,
  updateCasesDetail,
} from "../../redux/action";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HeaderV2 from "../../components/Header/HeaderV2";
import Modal from "../../components/Modal/Modal";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import viewDetail from "../../components/constant/viewdetail.json";
import _ from "lodash";
import { USER_TYPE } from "../../components/constant/authEnums";
import moment from "moment";

//{callUploadApi} from "../../util/apiCaller";

const EditSession = () => {
  const dispatch = useDispatch();
  const dataLink = useRef();
  const [sharedCase, setSharedCase] = useState(false);
  const [colorr, setColorr] = useState("");
  const [loader, setLoader] = useState(false);
  const [sessionType, setSessionType] = useState(session[1]?.label);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [worker, setWorker] = useState("");
  const [sessionList, setSessionLists] = useState([]);
  const [modal, setModal] = useState(false);
  const [sessionListId, setSessionListId] = useState("");
  const [report, setReport] = useState("");
  const [currentSession, setCurrentSession] = useState({});
  const [imgId, setImgId] = useState("");
  const [img, setImg] = useState({});
  const [imgList, setImgList] = useState([]);
  const [disable, setDisable] = useState(false);
  const doctorId = useSelector((state) => getUserId(state));
  const refferDoctor = useSelector((state) => getRefferedDoctor(state));
  const patientId = window.localStorage.getItem(PATIENT_ID);
  const [patientDetail, setPatientDetail] = useState({});
  const [doctorDetail, setDoctorDetail] = useState({});
  const [caseDetail, setCaseDetail] = useState({});
  const caseId = window.localStorage.getItem(CASE_ID);
  const [showPatientDetail, setShowPatientDetail] = useState(true);
  const [showCaseDetail, setShowCaseDetail] = useState(true);
  const navigate = useNavigate();
  const [showBtn, setShowBtn] = useState(false);
  const [createdBy, setCreatedBy] = useState(false);
  const [viewType, setViewType] = useState("");
  //const patientPopulate = useSelector((state) => getPatientDetail(state))
  const userType = window.localStorage.getItem(USER_TYPE);
  const casePopulate = useSelector((state) => getCaseDetail(state));

  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  // fetching sessionlist

  useEffect(() => {
    const fetchContent = async (caseId) => {
      if (caseId) {
        try {
          const res = await callApi(`session/list`, "post", {
            pageNum: 1,
            pageSize: 50,
            filters: {
              case_id: caseId,
            },
          });

          if (res && res?.status === "Success") {
            const { list } = res?.data;
            setSessionLists(list);

            dispatch(setSessionList(list));
            let arr = [...list];

            for (let item of arr) {
              if (item?._id === arr[0]?._id) {
                if (item?.created_by === patientId) {
                  setCreatedBy(true);
                }
                setCurrentSession(item);
                setSessionListId(item?._id);
                const res = await callApi(`sessionimage/list`, "post", {
                  pageNum: 1,
                  pageSize: 50,
                  filters: {
                    session_id: item?._id,
                  },
                });

                if (res && res?.status === "Success") {
                  const { list } = res?.data;
                  // setImgList(list)
                  let arr = [...list];
                  setImgList(arr);
                  dispatch(setImageGallery(arr));
                  //for(let item of arr){
                  //if(item?.url){
                  setImg({
                    link: arr[0]?.url,
                    file_name: arr[0]?.name,
                  });
                  //}
                  // }
                } else {
                }
              }
            }

            // setSessionListId(list[list.length-1]?._id)
          } else {
          }
        } catch (err) {}
      }
    };
    fetchContent(caseId);
  }, [caseId, imgId]);

  //patien detail

  useEffect(() => {
    const fetchContent = async (patientId) => {
      if (patientId) {
        const res = await callApi(`patient/${patientId}`, "get");

        if (res && res?.status === "Success") {
          const { patient } = res?.data;
          setPatientDetail(patient);
        } else {
        }
        try {
        } catch (e) {}
      }
    };
    fetchContent(patientId);
  }, [patientId]);

  //shared doctor detail

  useEffect(() => {
    const fetchContent = async (caseId) => {
      if (caseId) {
        const res = await callApi(`case/${caseId}`, "get");

        if (res && res?.status === "Success") {
          setCaseDetail(res?.data?.case);
          if (res.data.case.is_referred_case === true) {
            setSharedCase(true);
          }
          const ress = await callApi(
            `doctor/${res?.data?.case?.doctor_id}`,
            "get"
          );

          if (ress && ress?.status === "Success") {
            setDoctorDetail(ress?.data?.doctor);
          }
        } else {
        }
        try {
        } catch (e) {}
      }
    };
    if (caseId) {
      fetchContent(caseId);
    }
  }, [caseId]);

  //session image list

  useEffect(() => {
    const fetchContent = async (sessionListId) => {
      if (sessionListId) {
        const res = await callApi(`sessionimage/list`, "post", {
          pageNum: 1,
          pageSize: 50,
          filters: {
            session_id: sessionListId,
          },
        });

        if (res && res?.status === "Success") {
          const { list } = res?.data;
          // setImgList(list)
          let arr = [...list];
          setImgList(arr);
          dispatch(setImageGallery(arr));
          //for(let item of arr){
          //if(item?.url){
          setImg({
            link: arr[0]?.url,
            file_name: arr[0]?.name,
          });
          //}
          // }
        } else {
        }
        try {
        } catch (e) {}
      }
    };
    fetchContent(sessionListId);
  }, [sessionListId]);

  //Uploaded session Images

  const uploadWoundImg = async (keyName, keyValue) => {
    let update = {};
    let data = new FormData();
    data.append("file", keyValue);

    let config = {
      method: "post",
      url: `media/upload`,
      data: data,
    };

    // let result = await axios.request(config);
    let result = await callUploadApi(`media/upload`, "post", data);
    //console.log(result);
    if (
      result?.data?.link &&
      patientId &&
      caseId &&
      sessionListId &&
      doctorId
    ) {
      update["link"] = result?.data?.link;
      update["file_name"] = result?.data?.file_name;
      const resus = await callApi(`sessionimage/new`, "post", {
        sessionimage: {
          business_id: "6597d3f68836f7c222e1a504",
          doctor_id: doctorId,
          patient_id: patientId,
          case_id: caseId,
          session_id: sessionListId,
          name: result?.data?.file_name,
          url: result?.data?.link,
        },
      });
      if (resus && resus.status === "Success") {
        const res = resus?.data?.sessionimage?._id;
        await callApi(
          `sessionimage/${res}/session/${sessionListId}/make-primary`,
          "post"
        );
        setImgId(res);
      }
    }
  };

  //edit button
  useEffect(() => {
    if (userType === "patient") {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, []);
  useEffect(() => {
    if (disable === true) {
      setSessionType(session[1]?.label);
      setColorr("#a70302");
    } else {
      setSessionType(session[0]?.label);
      setColorr("#cccccc");
    }
  }, [disable]);

  //session
  const sessionHandler = (keyName, keyValue) => {
    if (keyValue === session[1]?.label) {
      // navigate("patient-detail-preview")
      setSessionType(keyValue);
      setDisable(true);
    } else if (keyValue === session[0]?.label) {
      // navigate("view-session")
      setDisable(false);
      setSessionType(keyValue);
    }
    let arr = [...sessionList];
    if (keyName === "session_list") {
      for (let item of arr) {
        if (item?._id === keyValue) {
          setCurrentSession(item);
          if (item?.created_by === patientId) {
            setCreatedBy(true);
          } else {
            setCreatedBy(false);
          }
          setSessionListId(item?._id);
        }
      }
    }
  };

  //gallery images
  const imagesHandler = () => {
    navigate("/image-gallery");
  };

  //session comparision
  const compareSessionHandler = () => {
    if (sessionList?.length > 1) {
      navigate("/compare-session-page");
    } else {
      toast(SuccessMsg("there should have atleast two session"), {
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
        className: "mail_toast",
      });
    }
  };

  // List of doctor
  const handleDoctorList = async () => {
    const res = await callApi(`doctor/list`, "post", {
      pageNum: 1,
      pageSize: 50,
      filters: {
        // Include any specific filters here
      },
    });
    if (res && res.status === "Success") {
      const { list } = res?.data;
      setDoctorList(list);
      setModal(true);
    }
  };

  //Mark completed case
  const handleCompledCase = async () => {
    if (caseId) {
      setLoading(true);
      const res = await callApi(`case/${caseId}/update`, "post", {
        case: {
          case_status: "Completed",
        },
      });
      if (res && res.status === "Success") {
        setLoading(false);
        toast(SuccessMsg("Case status has been updated successfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          theme: "light",
        });
      }
    }
  };

  //Report generation

  const handleDownloadReport = async () => {
    if (caseId) {
      const res = await callApi(`media/${caseId}/generate-report`, "get");
      if (res && res?.status === "Success") {
        setWorker(res?.data?._id);
        toast(SuccessMsg("Generating report"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    }
  };

  useEffect(() => {
    if (worker) {
      fetchContent(worker);
    }

    const setTime = setInterval(() => {
      if (worker) {
        //setLoading(true)
        fetchContent(worker);
      }
    }, 5000);

    return () => clearTimeout(setTime);

    console.log(setTime, "SET_TIME");
  }, [worker]);
  // useEffect(() => {
  //     if (report) {
  //       dataLink?.current?.click();
  //     }
  //   }, [report]);

  async function fetchContent(worker) {
    setLoader(true);
    const result = await callApi(`workerjob/${worker}`, "get");
    if (result && result.status === "Success") {
      const { workerjob } = result?.data;
      if (workerjob?.is_completed === true) {
        setReport(workerjob?.document_url);

        setWorker("");
        setLoader(false);
        toast(SuccessMsg("Report has been generated sucessfully "), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } else {
      toast(SuccessMsg("Something went wrong"), {
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
        className: "mail_toast",
      });
    }
  }
  useEffect(() => {
    if (report) {
      function fetchContent(report) {
        const link = document.createElement("a");
        link.href = report;

        //   link.download = true;
        link.target = "_blank";
        link.setAttribute("download", `FileName.pdf`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // document.body.appendChild(link);
        document.body.removeChild(link);
        //link.parentNode.removeChild(link);
      }
      fetchContent(report);
    }
  }, [report]);

  //Patient is visible condition

  useEffect(() => {
    if (isPatient) {
      let update = {};

      update["is_visible_to_patient"] = isPatient;
      if (caseId) {
        dispatch(updateCasesDetail(update, caseId));
      }
    }
  }, [isPatient]);

  //Delete cases

  const handleDeleteCase = async () => {
    if (caseId) {
      if (window.confirm("Do you want to delete this case?") === true) {
        const res = await callApi(`case/${caseId}/remove`, "post", {
          is_deleted: true,
        });
        if (res && res.status === "Success") {
          toast(SuccessMsg("Case has been sucessfully deleted "), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        // toast(SuccessMsg("Case deletion is canceled"),{
        //     position: 'top-center',
        //     hideProgressBar: true,
        //     closeButton: false,
        //     className: "mail_toast",
        // })
      }
    }
  };

  // Delete current session

  const handleDeleteCurrentSession = async () => {
    if (sessionListId) {
      if (window.confirm("Do you want to delete current session?") === true) {
        const res = await callApi(`session/${sessionListId}/remove`, "post", {
          is_deleted: true,
        });
        if (res && res.status === "Success") {
          toast(SuccessMsg("Current session has been sucessfully deleted "), {
            className: "mail_toast",
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            theme: "colored",
          });
        } else {
          toast(SuccessMsg("Something went wrong"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        // toast(SuccessMsg("Current session deletion is canceled"),{
        //     className: "mail_toast",
        //     position: 'top-center',
        //     hideProgressBar: true,
        //     closeButton: false,
        // })
      }
    }
  };

  // view and edit session

  const handleViewDetail = (keyName, keyValue) => {
    if (keyValue === viewDetail[0].label) {
      setViewType(keyValue);
      //setShowPatientDetail(false);
      setShowPatientDetail(!showPatientDetail);
    } else if (keyValue === viewDetail[1].label) {
      setViewType(keyValue);
      // setShowCaseDetail(false);
      setShowCaseDetail(!showCaseDetail);
    }
  };

  //chats

  const chatHandler = () => {
    dispatch(setSessionChatId(sessionListId));
    window.localStorage.setItem(SESSION_CHAT_ID, sessionListId);
    navigate("/chat");
  };

  return (
    <>
      <HeaderV2 />
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {loader && (
        <div className="loadergifdiv2">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {/* <Link className="hidden-element" to={report} ref={dataLink} download></Link> */}
      <Container className={`${styles.edit_session_flex}`}>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        > */}
        {/* <div
            style={{
              marginBottom: "1em",
              paddingLeft: "0.625em",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
            <span style={{ fontSize: "20px" }}> Back</span>
          </div> */}
        {/* </div> */}

        {dropdownActive === true && (
          <div
            className={`${styles.dropdown_box}`}
            onMouseOver={() => setDropdownActive(true)}
            onMouseOut={() => setDropdownActive(false)}
          >
            {/* <Link className={`${styles.accounts}`} to={""}>
                            <div className={`${styles.title}`}>Share</div>
                        </Link> */}
            {userType === "patient" ? null : (
              <>
                {" "}
                <Link
                  className={`${styles.accounts}`}
                  onClick={() => {
                    setIsPatient(!isPatient);
                  }}
                >
                  <div
                    className={`${styles.title}`}
                    style={{
                      display: "flex",
                      justifyContent: "centre",
                      alignItems: "centre",
                    }}
                  >
                    <div style={{ marginRight: "1.25em" }}>
                      Visible to Patient
                    </div>
                    <div>
                      <ToggleSwitch
                        checked={isPatient}
                        keyName={"patient"}
                        value={isPatient}
                        // value={!disable}
                        labelId={`s453`}
                      />
                    </div>
                  </div>
                </Link>
                <Link
                  className={`${styles.accounts}`}
                  onClick={() => handleDoctorList()}
                >
                  <div className={`${styles.title}`}>Share for Discussion</div>
                </Link>
                <Link
                  className={`${styles.accounts}`}
                  onClick={() => handleCompledCase()}
                >
                  <div className={`${styles.title}`}>Close Case</div>
                </Link>
              </>
            )}
            <Link
              className={`${styles.accounts}`}
              onClick={() => handleDownloadReport()}
            >
              <div className={`${styles.title}`}>Download Report</div>
            </Link>
            {userType === "patient" ? null : (
              <>
                {" "}
                <Link
                  className={`${styles.accounts}`}
                  onClick={() => handleDeleteCase()}
                >
                  <div className={`${styles.title}`}>Delete Case</div>
                </Link>
                <Link
                  className={`${styles.accounts}`}
                  onClick={() => handleDeleteCurrentSession()}
                >
                  <div className={`${styles.title}`}>
                    Delete current Session
                  </div>
                </Link>
              </>
            )}
          </div>
        )}
        <div className={`${styles.patient_flex_btn_session}`}>
          <div className={styles.flex_btn}>
            <div className={`${styles.flex_add_session}`}>
              <button
                onClick={() => {
                  navigate("/add-new-session");
                }}
              >
                Add Sessions
              </button>
            </div>
            <ul className={`${styles.radio_container}`}>
              {sessionList?.map((item, i) => (
                <RadioButton
                  key={item?._id}
                  checked={item?._id === sessionListId}
                  keyName={"session_list"}
                  value={item?._id}
                  label={`Session${" "}${i + 1}${"\n"}${moment(
                    item?.created_at
                  ).format("DD-MM-YYYY")}`}
                  labelId={item?._id}
                  patientDetailHandler={sessionHandler}
                />
              ))}
            </ul>
          </div>
          <div onMouseOver={() => setDropdownActive(true)}>
            <MoreVertIcon className={styles.flex_three_dots} />
          </div>
        </div>

        <div className={`${styles.patient_flex_btn}`}>
          <ul className={`${styles.radio_container3}`}>
            {viewDetail?.map((item, i) => (
              <RadioButton3
                key={item.label}
                checked={item.value === viewType}
                value={item.value}
                keyName={"view_type"}
                label={item.label}
                labelId={item.label}
                patientDetailHandler={handleViewDetail}
              />
            ))}
          </ul>
          {/* <Row className={`${styles.patient_flex_btw}`}>
                        <div onClick={() => { navigate("/add-new-session") }}>
                            <button>Add Session</button>
                        </div>
                        <div onClick={() => { setShowPatientDetail(!showPatientDetail) }}>
                            <button>Patient Detail</button>
                        </div>
                        <div onClick={() => { setShowCaseDetail(!showCaseDetail) }}>
                            <button>Case Details</button>
                        </div>
                    </Row> */}
          <ul className={`${styles.radio_container2}`}>
            {userType === "patient"
              ? createdBy
                ? session.map((item) => (
                    <RadioButton3
                      key={item.label}
                      checked={item.value === sessionType}
                      value={item.value}
                      keyName={"session_type"}
                      label={item.label}
                      labelId={item.label}
                      patientDetailHandler={sessionHandler}
                    />
                  ))
                : null
              : session.map((item) => (
                  <RadioButton3
                    key={item.label}
                    checked={item.value === sessionType}
                    value={item.value}
                    keyName={"session_type"}
                    label={item.label}
                    labelId={item.label}
                    patientDetailHandler={sessionHandler}
                  />
                ))}
          </ul>
        </div>
        {showPatientDetail === false ? (
          <>
            <PatientDetailPage data={patientDetail} patientId={patientId} />
          </>
        ) : null}
        {showCaseDetail === false ? (
          <>
            <AddPatientPage data={caseDetail} caseId={caseId} />
          </>
        ) : null}
        {createdBy ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.25em",
              fontWeight: "600",
              marginBottom: "1.25em",
            }}
          >
            {userType === "patient"
              ? "This session was created by you!"
              : "This session was created by patient!"}
          </div>
        ) : null}

        {refferDoctor ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1.25em",
            }}
          >
            {doctorDetail?.contact_name ? (
              <span
                style={{
                  padding: "1.25em",
                  borderRadius: "20px",
                  border: "1px solid black",
                }}
              >
                Shared case by {doctorDetail?.contact_name}
              </span>
            ) : null}
          </div>
        ) : null}
        <div
          className={`${styles.upload_images3}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/session-image/${sessionListId}`);
          }}
        >
          {/* <input type="file" hidden id="uploadimage49"/>
               
                <label htmlFor="uploadimage49" className={`${styles.upload_images}`}> */}
          <img src={img.link} alt="img" style={{ borderRadius: "1.87em" }} />
          {/* <h2>Upload Images
                        </h2>
                    </label> */}
        </div>

        {disable === true ? (
          <div className={`${styles.upload_img}`}>
            <input
              type="file"
              hidden
              id="uploadimage89"
              onChange={(e) => uploadWoundImg("url", e.target.files[0])}
            />

            <label htmlFor="uploadimage89" className={`${styles.upload_more}`}>
              <h4>Upload More Images</h4>
            </label>
          </div>
        ) : showBtn ? (
          <div className={`${styles.upload_img}`}>
            <input
              type="file"
              hidden
              id="uploadimage89"
              onChange={(e) => uploadWoundImg("url", e.target.files[0])}
            />

            <label htmlFor="uploadimage89" className={`${styles.upload_more}`}>
              <h4>Upload More Images</h4>
            </label>
          </div>
        ) : null}

        <div className={`${styles.patient_flex_view}`}>
          <button onClick={imagesHandler}>
            VIEW ALL IMAGES {imgList?.length}
          </button>
          <button onClick={compareSessionHandler}>COMPARE SESSIONS</button>
          {sharedCase && <button onClick={chatHandler}>Chat</button>}
        </div>

        <AddNewSession
          sessionData={currentSession}
          sessionId={sessionListId}
          disable={disable}
          setDisable={setDisable}
          colorr={colorr}
          setColorr={setColorr}
        />
      </Container>
      <Modal
        className="white_theme"
        list={doctorList}
        caseId={caseId}
        show={modal}
        setDoctorList={setDoctorList}
        onHide={() => setModal(false)}
      />
    </>
  );
};

export default EditSession;
