import style from "./toggleswitch2.module.css";

const ToggleSwitch2 = ({labelId,value,disable,checked,setDisable}) => {
    return (
        <>
        <label className={`${style.switch}`} htmlFor={labelId}>
  <input type="checkbox" 
  checked={checked}
  id={labelId} 
  
  onClick={() => setDisable(!disable)}
  />
  <span className={`${style.slider} ${style.round}`}></span>
</label>
        </>
    )
}

export default ToggleSwitch2;