import { Container } from "react-bootstrap";
import styles from "./notification.module.css";
import Header from "../Header/Header";
import { useEffect, useState } from "react";
import callApi from "../../util/apiCaller";
import {
  CASE_ID,
  DOCTOR_ID,
  USER_TYPE,
} from "../../components/constant/authEnums";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { REFFERED_FROM } from "../../components/constant/authEnums";
import { colors } from "@mui/material";
import { useDispatch } from "react-redux";
import { setRefferedDoctor } from "../../redux/action";
import HeaderV2 from "../../components/Header/HeaderV2";

const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState([]);
  const doctorId = window.localStorage.getItem(DOCTOR_ID);
  const userType = window.localStorage.getItem(USER_TYPE);

  useEffect(() => {
    if (userType === "doctor") {
      const fetchNotificationList = async () => {
        const res = await callApi("notification/list", "post", {
          pageNum: 1,
          pageSize: 20,
          filters: { user_type: "doctor", user_id: doctorId },
        });
        if (res && res.status === "Success") {
          const { notificationList } = res?.data;
          setNotification(notificationList);
        }
      };
      fetchNotificationList();
    } else if (userType === "patient") {
      const fetchNotificationList = async () => {
        const res = await callApi("notification/list", "post", {
          pageNum: 1,
          pageSize: 20,
          filters: { user_type: "patient", user_id: doctorId },
        });
        if (res && res.status === "Success") {
          const { notificationList } = res?.data;
          setNotification(notificationList);
        }
      };
      fetchNotificationList();
    }
  }, []);

  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);
  const handleSharedCase = (url) => {
    navigate("/edit-session");
    let str = url.slice(6, url.length - 8);
    //let c = "0123456789";
    // console.log(str);
    // function check(x) {
    //     return c.includes(x) ? true : false;
    // }
    // let matches = [...str].reduce(
    //     (x, y) => (check(y) ? x + y : x),"");

    if (str) {
      window.localStorage.setItem(CASE_ID, str);
      dispatch(setRefferedDoctor(true));
    }
  };
  return (
    <>
      <HeaderV2 />

      <Container className={styles.notification_flex}>
        {/* <Container style={{margin:"10px 0",cursor:"pointer"}} onClick={() => {navigate(-1)}}>
                <ArrowBackIcon />
                <span style={{fontSize:"20px"}}>{" "}Back</span>
                
        </Container> */}
        <div className={`${styles.notify_flex}`}>Notification</div>
        {notification?.map((item) => (
          <div
            className={`${styles.notify_wrapper}`}
            key={item?._id}
            onClick={() => {
              handleSharedCase(item?.url);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  margin: "10px",
                  width: "20px",
                  height: "20px",
                  background: "#ccc",
                  borderRadius: "20px",
                }}
              ></span>
            </div>
            <div>
              <p>{item?.text}</p>
              <p>{new Date(item?.updated_at).toLocaleDateString()}</p>
            </div>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Notification;
