import { Container, Col, Row } from "react-bootstrap";
import { quoritLogo } from "../../components/images";
import styles from "./UserTypePage.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_TYPE } from "../../components/constant/authEnums";
import meta from "../../../package.json";

const UserTypePage = () => {
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleUser = (keyName) => {
    setUser(keyName);
    if (keyName === "doctor") {
      navigate("/login");
      window.localStorage.setItem(USER_TYPE, keyName);
    } else if (keyName === "patient") {
      navigate("/login-otp");
      window.localStorage.setItem(USER_TYPE, keyName);
    }
    setErrors({});
  };
  // const handleValidation = (user) => {
  //     let isValid = true;
  //     let errors = {}
  //     if(!user){
  //         isValid = false;
  //         errors["user"] = "Please select one from above";
  //     }

  //     return {
  //         isValid,
  //         errors
  //     }
  // }
  // const handleUserType = (userType) => {
  //     const check = handleValidation(userType)
  //     if(check.isValid){
  //         if( userType === "doctor"){
  //             navigate("/login")
  //         }else if(userType === "patient"){

  //         }
  //         setErrors({})
  //     }else{

  //         setErrors(check.errors)
  //     }
  // }
  // const renderError = (keyName) => {
  //     return errors && errors[keyName] ? <p className="error">{errors[keyName]}</p> : null;
  //   };
  return (
    <>
      <div className={`${styles.user_type_flex}`}>
        <div className={`${styles.user_type_flex_sub}`}>
          <div className={`${styles.logo}`}>
            <img src={quoritLogo} alt="imglogo" />
          </div>
          <div className={`${styles.text_flex}`}>
            <h3>Who are you?</h3>
          </div>

          <div className={`${styles.img_flex}`}>
            <div
              className={`${styles.img_flex_child}`}
              onClick={() => handleUser("doctor")}
            >
              <div className={`${styles.img_flex_sub}`}>
                <img src={"images/doctor_img1.png"} alt="doctorImg" />
              </div>
              <div style={{ marginTop: "16px" }}>
                <h3>Doctor</h3>
              </div>
            </div>
            <div
              className={`${styles.img_flex_child}`}
              onClick={() => handleUser("patient")}
            >
              <div className={`${styles.img_flex_sub}`}>
                <img src={"images/patient_img.png"} alt="pateintImg" />
              </div>
              <div style={{ marginTop: "16px" }}>
                <h3>Patient</h3>
              </div>
            </div>
          </div>
          {/* <p style={{display:"flex",justifyContent:"center"}}>{renderError("user")}</p> */}
          <div className={styles.btn_flex}>
            <button>NEXT</button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "1.2em",
              padding: "10px 0",
            }}
          >
            {`v.${meta.version}`}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserTypePage;
