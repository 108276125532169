import Modal from "react-bootstrap/Modal";
import styles from "./homemodal.module.scss";
import { Link } from "react-router-dom";

const HomeModal = (props) => {
    return(
        <>
        <Modal
        {...props}
        className={`${styles.define_modal} ${styles.props?.className}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
        <div style={{display:"flex",justifyContent:"flex-end"}}><button  type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {props.onHide()}}></button></div>
          <div className={styles.define_modal_wrapper} style={{display:"flex",justifyContent:"center",paddingRight:" 20px", marginBottom:"40px"}}>
            {/* <div className="icon">&#9432;</div> */}
            <ul className={styles.define_list} >
               
                <li style={{textAlign:"center",
                margin:"30px 0",
                fontWeight:"600"}}><h3 style={{paddingBottom:"10px"}}>How to Use?</h3> </li>
                <Link style={{
                  backgroundColor:"#fbf1e9",
                  padding:"16px 20px",
                
                  fontSize:"20px",
                  textDecoration:"none",
                  color:"black"
                  }} target="_blank" to={""}>Read user manual PDF</Link>
                    <li style={{textAlign:"center",margin:"30px"}}>Or</li>
                <Link style={{
                  backgroundColor:"#fbf1e9",
                  padding:"16px 20px",
                 
                  fontSize:"20px",
                  textDecoration:"none",
                  color:"black"
                  }} target="_blank" to={"https://www.youtube.com/watch?v=Gy-fzLecoFs"}>Watch a video instead?</Link>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default HomeModal;