import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "@mui/material/styles";
import { useWindowSize } from "react-use";
import "./selectdropdown.scss";

// const darkTheme = createTheme({
//     typography: {
//       fontFamily: "Poppins",
//     },
//     palette: {
//       mode: "dark",
//     },
//   });
const lightThemed = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});
// const useStyles = theme => {
//   select: {
//       '&:before': {
//           borderColor: 'black',
//       },
//       '&:after': {
//           borderColor: 'black',
//       },
//       '&:not(.Mui-disabled):hover::before': {
//           borderColor: 'black',
//       },
//   },
//   icon: {
//       fill: 'black',
//   },
//   root: {
//       color: 'black',
//   },
// })

const SelectDropdown = ({ key, id, label, labelId,disabled, data, handleUserDetail, keyName,i, value }) => {
  //const [age, setAge] = useState("");
 // const [menuItem] = useState({ data });
  const { width } = useWindowSize();

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  //const classes = useStyles()
  const menuList = data?.map((option) => (
    <MenuItem value={option} key={option}>
      {option}
    </MenuItem>
  ));


  return (
    <ThemeProvider theme={lightThemed}>
      <CssBaseline />
      <FormControl fullWidth>
        <InputLabel id={labelId} style={{color:"black"}} >{label}<span ></span></InputLabel>
        <Select
          key={key}
          
          labelId={labelId}
          id={labelId}
          name={keyName}
          disabled={disabled}
          value={value}
          label={label}
          // error={true}
          MenuProps={{ PaperProps: { sx: { maxHeight: 200 ,color:"#111"} } }}
          onChange={(e) => handleUserDetail(keyName,e.target.value)}
          inputProps={{
            style: {
              fontSize: width > 768 ? "1.12em" : "1.12em",
              padding:"0px",
            
      
              color:"#111"
            },
          }}
      
          sx={{
            color:  "#707070",
            fontSize: width > 768 ? "1.12em" : "1.12em",
            lineHeight: "0px",
            padding:"0",
            "& fieldset": {
              border: "1px solid black",
              color:  "#707070",
            },
            "& .MuiSelect-select": {
              textAlign: "left",
            },
            "& .select_box .Mui-focused": {
              color: "#111",
              textAlign: "left",
            },
            ".MuiOutlinedInput-notchedOutline": {
              background: "rgba(255, 255, 255, 0.05)",
              borderColor: "#707070",
              color: "#707070",
              zIndex: "-1",
              borderRadius: width > 768 ? "7px" : "7px",
            },
            "& label": {
              lineHeight: width > 768 ? "1em" : "1em",
              color: "#707070",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#707070",
              color: "#707070"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#707070",
              color: "#707070",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderRadius: width > 768 ? "7px" : "7px",
              border: "1px solid #111",
              color:"#707070"
            },
            "& .MuiSelect-root .MuiSelect-filled ": {
              border: "1px solid #111",
              color:"#707070"
            },
            "& .Mui-error": {
              color: "red",
            },
            "& .MuiSvgIcon-root ": {
              fill: "#f77b00 !important",
            },
          }}
        >
        {menuList}
        </Select>
      </FormControl>
     </ThemeProvider>
   );
};
export default SelectDropdown;