import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";

const PasswordInput = ({handleUserDetail, value, keyName, label,id}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { width } = useWindowSize();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const darkTheme = createTheme({
  //   typography: {
  //     fontFamily: "Poppins",
  //   },
  //   palette: {
  //     mode: "dark",
  //   },
  // });

  return (
    <>
      {/* <ThemeProvider theme={darkTheme}>
        <CssBaseline /> */}
        <FormControl fullWidth variant="outlined" >
      
          <InputLabel
            htmlFor="outlined-adornment-password"
           
            sx={{
              "& label": {
                lineHeight: width > 768 ? "1.25em" : "1.25em",
                border:"none"
              },
            }}
          >
            {label}
          </InputLabel>
          <OutlinedInput
          style={{background:"rgb(232,240,254)"}}
            id={id}
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={(e) => handleUserDetail(keyName, e.target.value)}
            endAdornment={
              <InputAdornment position="end" >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
            inputProps={{
              style: {
                fontSize: "1.23em",
                height: "0.675em",
                border:"none",
                padding:"0.9em 0.4em"
              },
            }}
            sx={{
              "& fieldset": {
                borderRadius: "0px 7px 7px 0px",
              
                border: "1px solid $color-707070",
                // background: "rgba(228, 219, 233, 0.05)",
              },
              // input label when focused
              "& label": {
                lineHeight: width > 768 ? "1.25em" : "1.25em",
              },
              // input label when focused
              "& label.Mui-focused": {
                color: "#fff",
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                borderBottomColor: "#fff",
              },
              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: "#fff",
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              },
            }}
          />
        </FormControl>
      {/* </ThemeProvider> */}
    </>
  );
};

export default PasswordInput;

