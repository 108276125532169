import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
//import "./headerv2.scss";
import { notification } from "../../components/images";
import "./headerv3.scss";
import { quoritLogo } from "../../components/images";
import { useWindowSize } from "react-use";
import { logoutHandler } from "../../redux/action";
import { getUser } from "../../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { quoritImg } from "../images";
import HeaderNavItemV2 from "./HeaderNavItemV2";
import { USER_TYPE } from "../../components/constant/authEnums";
// import GlowCta from "../GlowCta/GlowCta";
import cases from "../../components/constant/differentcases.json";
import {
  setCases,
  setCompleted,
  setConsult,
  setOngoing,
  setReffered,
} from "../../redux/action";
const body = document.querySelector("body");
//import _ from "lodash";
const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: "",
    mainLink: "/home-page",
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Add New Cases",
    mainLink: "/patient-search-page",
  },
  {
    id: 3,
    type: "text1",
    mainTitle: "Cases",
    mainLink: "/cases-page",
  },
  {
    id: 5,
    type: "text",
    mainTitle: "Profile",
    mainLink: "/profile-page",
  },
];
const navArr = [
  {
    id: 1,
    type: "img",
    img: "",
    mainLink: "/home-page",
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Add New Cases",
    mainLink: "/add-new-case",
  },
  {
    id: 3,
    type: "text1",
    mainTitle: "Cases",
    mainLink: "/cases-page",
  },
  {
    id: 5,
    type: "text",
    mainTitle: "Profile",
    mainLink: "/profile-page",
  },
];

const HeaderV2 = () => {
  const [loading, setLoading] = useState(true);
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const { width } = useWindowSize();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [userState, setUserState] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userCategory, setUserCategory] = useState(1);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [isConsole, setIsConsole] = useState(false);
  const ref = useRef(null);
  const [headingMobile, setHeadingMobile] = useState([]);
  const location = useLocation();
  const [navHeadeer, setNavHeadeer] = useState([]);
  const [contactName, setContactName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = window.localStorage.getItem(USER_TYPE);

  const navLinksArr = [
    {
      id: 1,
      type: "img",
      img: "",
      mainLink: "/home-page",
      class: "logo_img",
    },
    {
      id: 2,
      type: "text",
      mainTitle: "Add New Cases",
      mainLink: "/patient-search-page",
    },
    {
      id: 3,
      type: "text1",
      mainTitle: "Cases",
      mainLink: "/cases-page",
    },
    {
      id: 5,
      type: "text",
      mainTitle: "Profile",
      mainLink: "/profile-page",
    },

    // {
    //   id: 6,
    //   type: "form",
    //   userStates: [
    //     {
    //       title: "Profile",
    //       slug: "/profile-page",
    //     },

    //   ],
    // },
  ];

  const mobNavLinksArr = [
    {
      id: 1,
      type: "text",
      mainTitle: "Add New Cases",
      mainLink: "/patient-search-page",
      // typeDropdown: "dropdown",
    },
    // {
    //   id: 2,
    //   type: "text",
    //   mainTitle: "Cases",
    //   mainLink: "/cases-page",
    // },
    {
      id: 3,
      type: "text",
      mainTitle: "Profile",
      mainLink: "/profile-page",
    },
  ];
  const mobArr = [
    {
      id: 1,
      type: "text",
      mainTitle: "Add New Cases",
      mainLink: "/add-new-case",
      // typeDropdown: "dropdown",
    },
    // {
    //   id: 2,
    //   type: "text",
    //   mainTitle: "Cases",
    //   mainLink: "/cases-page",
    // },
    {
      id: 3,
      type: "text",
      mainTitle: "Profile",
      mainLink: "/profile-page",
    },
  ];
  useEffect(() => {
    if (userType === "patient") {
      setHeadingMobile(mobArr);
      setNavHeadeer(navArr);
    } else if (userType === "doctor") {
      setHeadingMobile(mobNavLinksArr);
      setNavHeadeer(navLinksArr);
    }
  }, []);

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const navlinkList = navHeadeer?.map((nav, i) => (
    <li key={`3${i}`}>
      {nav.type === "img" && (
        <div
          onClick={() => setNavlinkIndex(i)}
          className={`logo_wrapper ${navlinkIndex === i ? "" : ""} `}
        >
          <a href={"/home-page"}>
            <img
              width={180}
              className={nav.class}
              src={quoritImg}
              alt="atlas img"
              onClick={scrollHandler}
              loading="eager"
            />
          </a>
        </div>
      )}
      {nav.type === "text1" && (
        <div
          onClick={() => {
            setNavlinkIndex(i);
            dispatch(setCases(cases[0].label));
            dispatch(setOngoing(true));
            dispatch(setCompleted(false));
            dispatch(setReffered(false));
            dispatch(setConsult(false));
          }}
          className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
        >
          {nav.linktype === "external" ? (
            <a
              className={nav.class}
              href={nav.mainLink}
              target="_blank"
              rel="noreferrer"
            >
              {nav.mainTitle}
            </a>
          ) : (
            //<Link className={nav.class} to={nav.mainLink}>
            <Link
              className={`${
                nav.linkVariant === "console" && isConsole === true
                  ? ""
                  : nav.class
              }`}
              onClick={() => {
                dispatch(setCases(cases?.[0]?.label));
                dispatch(setOngoing(true));
                dispatch(setCompleted(false));
                dispatch(setReffered(false));
                dispatch(setConsult(false));
              }}
              to={nav.mainLink}
            >
              {nav.mainTitle}
            </Link>
          )}
        </div>
      )}
      {nav.type === "text" && (
        <div
          onClick={() => setNavlinkIndex(i)}
          className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
        >
          {nav.linktype === "external" ? (
            <a
              className={nav.class}
              href={nav.mainLink}
              target="_blank"
              rel="noreferrer"
            >
              {nav.mainTitle}
            </a>
          ) : (
            //<Link className={nav.class} to={nav.mainLink}>
            <Link
              className={`${
                nav.linkVariant === "console" && isConsole === true
                  ? ""
                  : nav.class
              }`}
              to={nav.mainLink}
            >
              {nav.mainTitle}
            </Link>
          )}
        </div>
      )}

      {/* {isConsole === true
        ? nav.type === "search" && (
            <div className="nav_tab">
              <img src={nav.img} alt="search" className={nav.class} />
            </div>
          )
        : null} */}

      {/* {nav.type === "social" && (
        <div
          onClick={() => setNavlinkIndex(i)}
          className={`nav_tab social_links ${
            navlinkIndex === i ? "active" : ""
          } `}
        >
          {nav.links.map((item) => (
            <a
              className={nav.class}
              href={item.url}
              target="_blank"
              rel="noreferrer"
              key={item.url}
            >
              <img
                width={30}
                height={30}
                src={item.img}
                alt={item.url}
                className="social_img"
              />
            </a>
          ))}
        </div>
      )} */}

      {isConsole === false
        ? nav.type === "form" && (
            <div
              //   onClick={userStateHandler}
              className={`nav_tab bg_cta ${
                navlinkIndex === i ? "active" : ""
              } `}
            >
              <Link
                className="multi_text"
                to={nav.userStates[userState].slug}
                // style={{ pointerEvents: "none" }}
              >
                {/* <div className="title">{nav.userStates[userState].title}</div> */}
                <div>
                  {/* <img
                    width={28.48}
                    src={isLightTheme ? rightarrowblack : rightarrowwhite}
                    alt="right arrow"
                    className="right_arrow"
                  /> */}
                  {/* <img
                width={15.5}
                src={blackright}
                alt="right arrow"
                className="right_arrow"
              /> */}
                </div>
              </Link>
            </div>
          )
        : null}
    </li>
  ));
  const doctor = useSelector((state) => getUser(state));
  useEffect(() => {
    if (doctor) {
      let str = doctor?.contact_name;
      let str1 = str?.[0] + str?.[1] + str?.[2] + str?.[3];
      let str2 = "";
      for (let i = 4; i < str?.length; i++) {
        if (str[i] === " ") {
          break;
        } else {
          console.log(str[i]);
          str2 += str[i];
        }
      }
      setContactName(str1 + str2);
    }
  }, [doctor]);
  const handleLogout = () => {
    // if(window.confirm("Do you want to get Logout?") === true){
    dispatch(logoutHandler());
    // }else{
    //   toast(SuccessMsg("Case deletion is canceled"),{

    //     position: 'top-center',
    //     hideProgressBar: true,
    //     closeButton: false,
    //     className: "mail_toast",
    // })
    // }
  };

  useEffect(() => {
    setLoading(false);
  }, [location.pathname]);

  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <img src={quoritImg} alt="loader" className="img-fluid" />
        </div>
      )}
      <div className={"desktop_blankv3"}></div>
      <header
        ref={ref}
        className={` ${"headerv3_sec"} ${isMenuActive ? "menuactive" : ""}   `}
      >
        {width > 1080 ? (
          <>
            {/* <div className="desktop_blank"></div> */}
            <div className="my_container">
              <div className="navlinks">
                <ul>
                  {navlinkList}
                  <li>
                    <div
                      className="nav_tab user_tab"
                      // onClick={userStatusHandler}
                      onMouseOver={() => setDropdownActive(true)}
                      onMouseOut={() => setDropdownActive(false)}
                    >
                      <Link className="user" to={() => false}>
                        <div className="title">
                          {/* {usersArr[userCategory].users[0].name} */}
                          <img
                            width={40}
                            src={notification}
                            alt="dropdown arrow"
                            className="dropdown_arrow"
                          />
                        </div>
                        <div
                          className="initial_circle"
                          style={{
                            background: "#ED8847",
                          }}
                        >
                          <div className="name">
                            {/* {usersArr[userCategory].users[0].name} */}
                            {contactName}
                          </div>
                        </div>
                        <div className="dropdown_arrow_wrap">
                          {/* <img
                              width={9}
                              src={notification}
                              alt="dropdown arrow"
                              className="dropdown_arrow"
                            /> */}
                        </div>
                      </Link>
                    </div>

                    {/* {loggedIn === false && (
                      <div
                        className="nav_tab login_tab"
                        onClick={() => {
                          setLoggedIn(true);
                          setIsPro(true);
                        }}
                      >
                        <Link className="login" to={() => false}>
                          <div className="title">LOGIN</div>
                          <div>
                            <img
                              width={30}
                              height={30}
                              src={loginicon}
                              alt="login icon"
                              className="login_icon"
                            />
                          </div>
                        </Link>
                      </div>
                    )} */}
                    {dropdownActive === true && (
                      <div
                        className="dropdown_box"
                        onMouseOver={() => setDropdownActive(true)}
                        onMouseOut={() => setDropdownActive(false)}
                      >
                        <Link className="accounts" to={"/notification-list"}>
                          <div className="title">Notification</div>
                        </Link>
                        <Link className="accounts" onClick={handleLogout}>
                          <div className="title">Logout</div>
                          <div>
                            {/* <img
                              width={30}
                              height={30}
                              src={loginicon}
                              alt="login icon"
                              className="login_icon"
                            /> */}
                          </div>
                        </Link>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <header className="header">
              <div className="my_container">
                <div className="login_container">
                  <div className="logo_wrapper">
                    <Link className="logo_link" to="/home-page">
                      <img
                        src={`${quoritImg}`}
                        alt="atlas logo"
                        className="atlas_logo"
                      />
                    </Link>
                  </div>
                  <div>
                    <div
                      className="nav_tab user_tab"
                      // onClick={userStatusHandler}
                      // onMouseOver={() => setDropdownActive(true)}
                      // onMouseOut={() => setDropdownActive(false)}
                    >
                      <Link
                        className="user"
                        style={{ textDecoration: "none", display: "flex" }}
                        to={"/notification-list"}
                      >
                        <div className="dropdown_arrow_wrap">
                          <img
                            width={35}
                            src={notification}
                            alt="dropdown arrow"
                            className="dropdown_arrow"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                        <div
                          className="initial_circle"
                          style={{
                            background: "#ED8847",
                          }}
                        >
                          <div className="name">
                            {/* {usersArr[userCategory].users[0].name.charAt(0)} */}
                            {doctor?.contact_name?.slice(0, 1)}
                          </div>
                        </div>
                      </Link>
                      {/* {dropdownActive === true && (
                          <div
                            className="dropdown_box"
                            onMouseOver={() => setDropdownActive(true)}
                            onMouseOut={() => setDropdownActive(false)}
                          >
                            <Link className="accounts" to={() => false}>
                              <div className="title">My Accounts</div>
                            </Link>
                            <Link className="accounts" to={() => false}>
                              <div className="title">LOGIN</div>
                              <div>
                                <img
                                  width={30}
                                  height={30}
                                  src={""}
                                  alt="login icon"
                                  className="login_icon"
                                />
                              </div>
                            </Link>
                          </div>
                        )} */}
                    </div>
                  </div>
                </div>
              </div>
              <nav className="nav_links_wrapper">
                <div
                  className={`nav_line2_wrapper_flex_wrapper ${
                    isMenuActive ? "active" : ""
                  }`}
                >
                  <div className="blank"></div>
                  <div className="logo_wrapper2">
                    <Link className="logo_link2" to="/home-page">
                      <img
                        src={`${quoritImg}`}
                        alt="atlas logo"
                        className="atlas_logo2"
                      />
                    </Link>
                  </div>
                  <ul className="nav_line2_wrapper_flex">
                    {headingMobile?.map((navData, i) => (
                      <HeaderNavItemV2
                        navData={navData}
                        key={parseInt(navData?.id)}
                        arrIndex={i}
                        handleNavIndex={handleNavIndex}
                        navActiveIndex={navActiveIndex}
                        handleLogout={handleLogout}
                        handleHamburgerClick={handleHamburgerClick}
                      />
                    ))}
                    <div className="header_flex2" style={{ padding: "10px 0" }}>
                      <NavLink
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            color: isActive ? "black" : "black",
                            viewTransitionName: isTransitioning ? "slide" : "",
                            fontSize: "2.0em",
                            fontWeight: "600",
                            textDecoration: "none",
                            marginLeft: "4px",
                          };
                        }}
                        to={"/cases-page"}
                        onClick={() => {
                          handleHamburgerClick();
                          dispatch(setCases(cases?.[0]?.label));
                          dispatch(setOngoing(true));
                          dispatch(setCompleted(false));
                          dispatch(setReffered(false));
                          dispatch(setConsult(false));
                        }}
                      >
                        Cases
                      </NavLink>
                    </div>
                    <div className="header_flex2" style={{ padding: "10px 0" }}>
                      <NavLink
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            color: isActive ? "black" : "black",
                            viewTransitionName: isTransitioning ? "slide" : "",
                            fontSize: "2.0em",
                            textDecoration: "none",
                            fontWeight: "600",
                            marginLeft: "4px",
                          };
                        }}
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </NavLink>
                    </div>

                    {/* <li>
                      {width <= 1023 && (
                        <div className="nav_social_media_icons">
                          <p className="social_title">FOLLOW US ON :</p>
                          <a
                            href="https://www.facebook.com/profile.php?id=100091559990889&mibextid=LQQJ4d"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={facebook}
                              alt="facebook"
                              className="nav_social_icons"
                            />
                          </a>
                          <a
                            href="https://instagram.com/archin.za?igshid=MzRlODBiNWFlZA"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={insta}
                              alt="instagram"
                              className="nav_social_icons"
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/92807055/admin/feed/posts/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={linkedIn}
                              alt="linkedIn"
                              className="nav_social_icons"
                            />
                          </a>
                        </div>
                      )}
                    </li> */}
                    {/* <li>
                      {width <= 1023 && (
                        <div className="nav_social_media_icons">
                          <a
                            href={() => false}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Fbgrey className="nav_social_icons" />
                          </a>
                          <a
                            href={() => false}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Instagrey className="nav_social_icons" />
                          </a>
                          <a
                            href={() => false}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Linkgrey className="nav_social_icons" />
                          </a>
                        </div>
                      )}
                    </li> */}
                  </ul>
                </div>
              </nav>

              {/* mobile UI start */}
              <div
                className={`hamburger_lines ${isMenuActive ? "active" : ""}`}
                onClick={handleHamburgerClick}
              >
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
              </div>
            </header>
          </>
        )}
      </header>
    </>
  );
};

export default HeaderV2;
