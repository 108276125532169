export const FETCHING_STATUS = "FETCHING_STATUS";

export const SET_IS_AUTHENTICATION = "SET_IS_AUTHENTICATION";
export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_USER_ID = "SET_USER_ID";

export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_PATIENT_ID = "SET_PATIENT_ID";
export const SET_CASE_ID = "SET_CASE_ID";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_PATIENT_DETAIL = "SET_PATIENT_DETAIL";
export const SET_CASE_DETAIL = "SET_CASE_DETAIL";
export const SET_IMAGE_GALLERY = "SET_IMAGE_GALLERY";
export const SET_SESSION_LIST = "SET_SESSION_LIST";
export const SET_REFFER = "SET_REFFER";

export const SET_ONGOING = "SET_ONGOING";
export const SET_REFFERED = "SET_REFFERED";
export const SET_COMPLETED = "SET_COMPLETED";
export const SET_CONSULT = "SET_CONSULT";
export const SET_CASES = "SET_CASES";
export const SET_ACCEPT_CASES = "SET_ACCEPT_CASES";

export const SET_SESSION_CHAT_ID = "SET_SESSION_CHAT_ID";
