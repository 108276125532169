import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import callApi from "../../util/apiCaller";
import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import _ from "lodash";
import { getUser } from "../../redux/selector";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import FullWidthTextField from "../TextField2/FullWidthTextField";

function FormFiveModal(props) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [acceptArr, setAcceptArr] = useState([]);
  const [rejectArr, setRejectArr] = useState([]);
  const [show, setShow] = useState(false);
  const [pendingArr, setPendingArr] = useState("");
  const [sendId, setSendId] = useState("");
  const [doctorData, setDoctorData] = useState({});
  const [dropdownActive, setDropdownActive] = useState(false);
  const doctor = useSelector((state) => getUser(state));

  const handleRefferCase = async (id) => {
    let arr1 = [...rejectArr];
    arr1.push(id);
    setPendingArr("pending");
    setRejectArr(arr1);
    let arr = [...props.list];
    console.log(arr);

    if (props?.caseId) {
      //setLoading(true)
      const res = await callApi(
        `case/${props?.caseId}/referred-doctor/${id}/refer-case`,
        "post"
      );
      if (res && res.status === "Success") {
        const result = await callApi(`case/${props?.caseId}/update`, "post", {
          case: {
            is_referred_case: true,
          },
        });
        if (result && result.status === "Success") {
          //setLoading(false)
          setShow(!show);
          toast(SuccessMsg("Case referred to doctor"), {
            className: "mail_toast",
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
          });
        } else {
          toast(SuccessMsg("Something went wrong"), {
            className: "mail_toast",
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
          });
        }
      } else {
        toast(SuccessMsg("All ready referred to the doctor"), {
          className: "mail_toast",
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
        });
      }
    }
  };
  const debouncedHandlePincode = useCallback(
    _.debounce((user) => {
      fetchByDoctorId(user);
    }, 700),
    []
  );
  const patientDetailHandler = (keyName, patient) => {
    setSearch(patient);
    debouncedHandlePincode(patient);
  };
  const fetchByDoctorId = async (user) => {
    const res = await callApi(`doctor/list`, "post", {
      pageNum: 1,
      pageSize: 50,
      filters: {
        qtext: user,
      },
    });
    if (res && res.status === "Success") {
      const { list } = res?.data;
      props.setDoctorList(list);
    }
  };
  useEffect(() => {
    async function fetchByDoctorId(doctorId) {
      const res = await callApi(`doctor/${doctorId}`, "get");
      if (res && res?.status === "Success") {
        setDoctorData(res?.data?.doctor);
      }
    }
    if (doctorId) {
      fetchByDoctorId(doctorId);
    }
  }, [doctorId]);
  useEffect(() => {
    async function fetchByDoctorId(caseId) {
      const caseResult = await callApi(`case/${caseId}`, "get");
      if (caseResult && caseResult.status === "Success") {
        console.log(caseResult?.data?.case);
        let accept = [];
        if (caseResult?.data?.case?.accepted_doctor_list?.length > 0) {
          setSendId("accepted");
          accept = [...caseResult?.data?.case?.accepted_doctor_list];
        } else if (caseResult?.data?.case?.rejected_doctor_list?.length > 0) {
          setSendId("rejected");
          accept = [...caseResult?.data?.case?.rejected_doctor_list];
        } else if (caseResult?.data?.case?.referred_doctor_list?.length > 0) {
          setSendId("pending");
          accept = [...caseResult?.data?.case?.referred_doctor_list];
        }
        setAcceptArr(accept);
      }
    }
    if (props.caseId) {
      fetchByDoctorId(props.caseId);
    }
  }, [props.caseId]);
  console.log(props.list);

  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Modal
        {...props}
        className={`define_modal ${props?.className}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <div class="modal-dialog"> */}
        {/* <div class="modal-content"> */}
        {dropdownActive === true && (
          <div
            className="loadergifdiv2"
            //onMouseOver={() => setDropdownActive(true)}
            // onMouseOut={() => setDropdownActive(false)}
          >
            {/* <div className="modal_flex_btn"></div> */}
            <div className="dropdown_box">
              <div className="close_flex">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setDropdownActive(false);
                  }}
                ></button>
              </div>
              <div className="img_flex">
                {doctorData?.profile_image &&
                (doctorData.profile_image?.match(".jpg") ||
                  doctorData?.profile_image?.match(".png") ||
                  doctorData?.profile_image?.match(".jpeg")) ? (
                  <img
                    src={doctorData?.profile_image}
                    width={120}
                    height={120}
                    style={{ borderRadius: "60px" }}
                    alt=""
                  />
                ) : (
                  <img
                    src="images/Profile-Latest.svg"
                    width={120}
                    height={120}
                    style={{ borderRadius: "60px" }}
                    alt=""
                  />
                )}
              </div>
              <div>
                <label>Name</label>
                <FullWidthTextField
                  type="text"
                  key={"23"}
                  value={
                    doctorData?.contact_name ? doctorData?.contact_name : "NA"
                  }
                />
              </div>
              <div>
                <label>Mobile</label>
                <FullWidthTextField
                  type="text"
                  key={"24"}
                  value={
                    doctorData?.phone_number ? doctorData?.phone_number : "NA"
                  }
                />
              </div>
              <div>
                <label>Email</label>
                <FullWidthTextField
                  type="text"
                  key={"25"}
                  value={
                    doctorData?.contact_email ? doctorData?.contact_email : "NA"
                  }
                />
              </div>
              <div>
                <label>Qualification</label>
                <FullWidthTextField
                  type="text"
                  key={"26"}
                  value={
                    doctorData?.qualification ? doctorData?.qualification : "NA"
                  }
                />
              </div>
              <div>
                <label>Specialization</label>
                <FullWidthTextField
                  type="text"
                  key={"27"}
                  value={
                    doctorData?.specialization
                      ? doctorData?.specialization
                      : "NA"
                  }
                />
              </div>
              <div>
                <label>Licence No.</label>
                <FullWidthTextField
                  type="text"
                  key={"28"}
                  value={
                    doctorData?.license_number
                      ? doctorData?.license_number
                      : "NA"
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div class="modal-header">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30em",
              padding: "1em 3.0em",
            }}
          >
            <div
              style={{
                fontSize: "1.5em",
                marginBottom: "10px",

                fontWeight: "600",
              }}
            >
              Send for Referral
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.onHide();
                }}
              ></button>
            </div>
          </div>
          <div className="patient_flex_input">
            <input
              placeholder="Search"
              value={search}
              onChange={(e) => {
                patientDetailHandler("patient", e.target.value);
              }}
            />
            <img src="images/Search.png" alt="" />
          </div>
        </div>

        <Modal.Body>
          {props?.list?.map((data, i) => (
            <div className="define_modal_wrapper" key={data._id}>
              <span className="list_item">
                {data?.profile_image &&
                (data?.profile_image?.match(".jpg") ||
                  data?.profile_image?.match(".png") ||
                  data?.profile_image?.match(".jpeg")) ? (
                  <img
                    src={data?.profile_image}
                    width={60}
                    height={60}
                    style={{ borderRadius: "30px", marginRight: "0.625em" }}
                    alt="img"
                  />
                ) : (
                  <img
                    src="images/Profile-Latest.svg"
                    width={60}
                    height={60}
                    style={{ borderRadius: "30px", marginRight: "0.625em" }}
                    alt=""
                  />
                )}
                {/* <span className="define_list"> */}
                <span
                  key={`define-${Math.random(i)}`}
                  onMouseOver={() => {
                    setDoctorId(data?._id);
                    setDropdownActive(true);
                  }}
                  onMouseOut={() => {
                    setDropdownActive(false);
                  }}
                >
                  {data.contact_name}
                </span>
              </span>
              {/* </span> */}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleRefferCase(data?._id)}
              >
                {acceptArr.includes(data?._id) ? (
                  <p
                    style={{
                      padding: "0.475em 0.625em",
                      marginLeft: "0.475em",
                      border: "1px solid #ED8847",
                      background: "#fbf1e9",
                      borderRadius: "10px",
                      fontSize: "1.125em",
                    }}
                  >
                    {sendId}
                  </p>
                ) : rejectArr.includes(data?._id) ? (
                  <p
                    style={{
                      padding: "0.475em 0.625em",
                      marginLeft: "0.475em",
                      border: "1px solid #ED8847",
                      background: "#fbf1e9",
                      borderRadius: "10px",
                      fontSize: "1.125em",
                    }}
                  >
                    pending
                  </p>
                ) : (
                  <>
                    <SendIcon />
                  </>
                )}
              </span>
            </div>
          ))}
        </Modal.Body>
        <div class="modal-footer">
          {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {props.onHide()}}>Close</button> */}
        </div>
        {/* </div> */}
        {/* </div> */}
      </Modal>
    </>
  );
}

export default FormFiveModal;
