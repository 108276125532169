import { Container, Stack } from "react-bootstrap";
import styles from "./LoginPage.module.scss";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginDoctorViaPassword,
  loginPatientViaPassword,
} from "../../redux/action";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { quoritLogo } from "../../components/images";
import { useLocation } from "react-router-dom";
import { USER_TYPE } from "../../components/constant/authEnums";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";

const LoginPage = () => {
  let [loading, setLoading] = useState(true);
  let location = useLocation();
  const userType = window.localStorage.getItem(USER_TYPE);

  useEffect(() => {
    setLoading(false);
  }, [location.pathname]);
  const [user, setUser] = useState({
    contact_email: "",
    local_password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUserDetail = (keyName, keyValue) => {
    let update = { ...user };
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    update[keyName] = keyValue;
    setUser(update);
  };
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.contact_email) {
      isValid = false;
      errors["contact_email"] = "Contact email cannot be blank";
    }
    if (!user?.local_password) {
      isValid = false;
      errors["local_password"] = "Password cannot be blank";
    } else if (user?.local_password && user?.local_password?.length < 8) {
      isValid = false;
      errors["local_password"] = "Password must be of atleast 8 character";
    }
    return {
      isValid,
      errors,
    };
  };
  const handleLogin = () => {
    const check = handleValidation(user);
    if (check.isValid) {
      if (userType === "doctor") {
        dispatch(loginDoctorViaPassword(user, navigate));
      } else if (userType === "patient") {
        dispatch(loginPatientViaPassword(user, navigate));
      }
      setErrors({});
    } else {
      setErrors(check.errors);
    }
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error my-1">{errors[keyName]}</p>
    ) : null;
  };
  console.log(user);
  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <img
            src={quoritLogo}
            alt="loader"
            className="img-fluid"
            style={{ maxWidth: "320px" }}
          />
        </div>
      )}

      <div className={`${styles.login_flex}`}>
        <div className={`${styles.login_flex_child}`}>
          <div className={`${styles.login_flex_child_sub1}`}>
            <img src="images/quorit_logo_img.png" alt="" />
            <div className={styles.text_login}> LOGIN</div>
            <p>Log in with Mobile Number/Email ID</p>
          </div>

          <div className={`${styles.login_flex_child_sub2}`}>
            <div className={styles.login_row}>
              <img src="email-outline.svg" width={50} height={50} alt="" />
              <FullWidthTextField
                type="text"
                placeholder="Enter Mobile Number/Email ID"
                value={user?.email}
                onChange={(e) =>
                  handleUserDetail("contact_email", e.target.value)
                }
              />
            </div>
            {renderError("contact_email")}
          </div>
          <div className={`${styles.login_flex_child_sub3}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src="images/lock.svg" width={50} height={50} alt="" />
              <PasswordInput
                keyName={"local_password"}
                value={user?.local_password}
                //   label={"Set Password*"}
                id={"main-password"}
                handleUserDetail={handleUserDetail}
              />
            </div>

            {renderError("local_password")}
          </div>

          <button className={`${styles.login_flex2_btn}`} onClick={handleLogin}>
            SUBMIT
          </button>
          <Link className={`${styles.otp_login}`} to={"/login-otp"}>
            <h3>Login with OTP</h3>
          </Link>
          <div className={`${styles.otp_login2}`}>
            <span className={styles.reg_account}>Don't have an account ?</span>
            <Link className={styles.reg_text} to={"/registration-page"}>
              Register
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
