//import logo from './logo.svg';
//import './common.css';
import { Routes, Route } from "react-router-dom";

import LoginPage from "./Pages/loginpage/LoginPage";
import Chat from "./Pages/Chat/Chat";
import Product from "./Pages/Product/Product";
import OtpLoginPage from "./Pages/loginpage/OtpLoginPage";
import VerifyOtpPage from "./Pages/loginpage/VerifyOtpPage";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./Pages/NotFound";
import HomePage from "./Pages/HomePage/HomePage";
import CasesPage from "./Pages/CasesPage/CasesPage";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import PatientSearchPage from "./Pages/PatientSearchPage/PatientSearchPage";
import PatientDetailPage from "./Pages/PatientDetailPage/PatientDetailPage";
import AddPatientPage from "./Pages/AddPatientPage/AddPatientPage";
import AddNewSession from "./Pages/AddNewSession/AddNewSession";
import FullPageLoader from "./Pages/FullPageLoader";
import { Navigate } from "react-router-dom";
import EditSession from "./Pages/EditSession/EditSession";
import CompareSessionPage from "./Pages/CompareSession/CompareSessionPage";
import ImageGallery from "./Pages/Imagegallery/ImageGallery";
import { useEffect, Suspense, useState } from "react";
import Notification from "./Pages/Notification/Notification";
import {
  DOCTOR_ID,
  AUTHENTICATED,
  AUTHENTICATING,
  NOT_AUTHENTICATED,
  PATIENT_ID,
  USER_TYPE,
} from "./components/constant/authEnums";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDoctorDetail,
  setUserId,
  setAuthState,
  setAuthentication,
  setPatientId,
  fetchPatientDetail,
} from "./redux/action";
import { getIsAuthenticated } from "./redux/selector";
import HeaderV2 from "./components/Header/HeaderV2";
import UserTypePage from "./Pages/ChooseUserType/UserTypePage";
import PatientList from "./Pages/PatientList/PatientList";
import SessionImage from "./Pages/Imagegallery/SessionImage";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    let doctorId = window.localStorage.getItem(DOCTOR_ID);
    let userType = window.localStorage.getItem(USER_TYPE);
    if (doctorId) {
      dispatch(setAuthentication(true));
      dispatch(setAuthState(AUTHENTICATED));
      if (userType === "doctor") {
        dispatch(fetchDoctorDetail(doctorId));
      }
      if (userType === "patient") {
        dispatch(fetchPatientDetail(doctorId));
      }
      dispatch(setUserId(doctorId));
    } else {
      dispatch(setAuthState(NOT_AUTHENTICATED));
    }
  }, []);
  // useEffect(() => {
  // let patientId = window.localStorage.getItem(DOCTOR_ID)
  //     if(patientId){
  //       dispatch(setPatientId(patientId))

  //     }
  // },[])

  const authState = useSelector((state) => getIsAuthenticated(state));

  const NotAuthenticatedRoutes = () => {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user-type-page" element={<UserTypePage />} />
        <Route path="/login-otp" element={<OtpLoginPage />} />
        <Route path="/verify-otp" element={<VerifyOtpPage />} />
        <Route path="/registration-page" element={<RegistrationPage />} />
        <Route exact path="/404" element={<NotFound />} />
        {/* <Route path="t/:code" element={<MagicLogin />}/> */}
        <Route path="/" element={<Navigate to={"/user-type-page"} />} />
      </Routes>
    );
  };

  const AuthenticatedRoutes = () => {
    return (
      <Routes>
        <Route path="/home-page" element={<HomePage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/cases-page" element={<CasesPage />} />
        <Route path="/product-page" element={<Product />} />
        <Route path="/patient-list" element={<PatientList />} />
        <Route path="/profile-page" element={<ProfilePage />} />
        <Route path="/patient-search-page" element={<PatientSearchPage />} />
        <Route path="/patient-detail" element={<PatientDetailPage />} />
        <Route path="/add-new-case" element={<AddPatientPage />} />
        <Route path="/add-new-session" element={<AddNewSession />} />
        <Route path="/image-gallery" element={<ImageGallery />} />
        <Route path="/session-image/:id" element={<SessionImage />} />
        <Route path="/edit-session" element={<EditSession />} />
        <Route path="/compare-session-page" element={<CompareSessionPage />} />
        <Route path="/notification-list" element={<Notification />} />
        <Route exact path="/404" element={<NotFound />} />
        {/* <Route path="t/:code" element={<MagicLogin />}/> */}
        <Route path="/" element={<Navigate to="/home-page" />} />
      </Routes>
    );
  };

  const getRoutes = () => {
    switch (authState) {
      case true:
        return <AuthenticatedRoutes />;
      case false:
        return <NotAuthenticatedRoutes />;

      default:
        return <FullPageLoader />;
    }
  };

  return (
    <>
      <ToastContainer />

      <Suspense fallback={<FullPageLoader />}>{getRoutes()}</Suspense>
    </>
  );
}

export default App;
