import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./addnewsession.module.scss";
import {
  setCases,
  setOngoing,
  setReffered,
  setConsult,
  setCompleted,
} from "../../redux/action";
import cases from "../../components/constant/differentcases.json";
import Header from "../Header/Header";
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import { StyledDropZone } from "react-drop-zone";
import { uploadImages } from "../../components/images";
import RadioButton from "../../components/RadioButton2/RadioButton2";
import differentSession from "../../components/constant/differentsession.json";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewSession,
  setSessionId,
  updateSessionDetail,
} from "../../redux/action";
import callApi, { callUploadApi } from "../../util/apiCaller";
import { getUserId, getPatientId, getCaseId } from "../../redux/selector";
import {
  CASE_ID,
  PATIENTS_DOCTOR,
  PATIENT_ID,
  SESSION_ID,
} from "../../components/constant/authEnums";
import { brown, green, pink, yellow } from "@mui/material/colors";
import _, { filter } from "lodash";
import ToggleSwitch2 from "../../components/ToggleSwitch2/ToggleSwitch2";
//import { SET_SESSION_ID } from "../../redux/actionType";
import { quoritLogo } from "../../components/images";
import HeaderV2 from "../../components/Header/HeaderV2";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import { USER_TYPE } from "../../components/constant/authEnums";
import Chart from "react-apexcharts";
import { colors } from "@mui/material";

const infection = ["Yes", "No"];

const AddNewSession = ({
  sessionData,
  sessionId,
  setDisable,
  disable,
  colorr,
}) => {
  const patientId = window.localStorage.getItem(PATIENT_ID);
  const patientDoctor = window.localStorage.getItem(PATIENTS_DOCTOR);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userType = window.localStorage.getItem(USER_TYPE);
  const caseId = window.localStorage.getItem(CASE_ID);
  const [path, setPath] = useState(true);
  const dispatch = useDispatch();
  const doctorId = useSelector((state) => getUserId(state));
  const patientDoc = window.localStorage.getItem(PATIENTS_DOCTOR);
  //const patientId = useSelector((state) => getPatientId(state))
  //const caseId = useSelector((state) => getCaseId(state))
  const doctorIdd = userType === "patient" ? patientDoctor : doctorId;
  const [padnum, setPadnum] = useState(8);
  useEffect(() => {
    if (location.pathname === "/edit-session") {
      setPath(false);
      setPadnum(2);
    } else {
      setPath(true);

      ///setColorr("#a70302")
    }
  }, [location.pathname]);
  const [imageUpload, setImageUpload] = useState({
    link: "",
    file_name: "",
  });
  const [observationId, setObservationId] = useState("");
  const [treatmentId, setTreatmentId] = useState("");
  const [privateId, setPrivateId] = useState("");
  const [errors, setErrors] = useState({});
  const [exudateType, setExudateType] = useState([]);
  const [exudates, setExudates] = useState([]);
  const [surroundingSkin, setSurroundingSkin] = useState([]);
  const [observations, setObservations] = useState("");
  const [painScore, setPainScore] = useState([]);
  const [treatmentNotes, setTreatmentNotes] = useState("");
  const [privateNotes, setPrivateNotes] = useState("");
  const [tissueTypes, setTissueTypes] = useState([]);
  const [epithelialize, setEpithelialize] = useState(0);
  const [pain, setPain] = useState([]);
  const [morbidDiseases, setMorbidDiseases] = useState([]);
  const [area, setArea] = useState(0);
  const [volume, setVolume] = useState(0);
  const [woundImg, setWoundImg] = useState([]);
  const [sessionType, setSessionType] = useState(differentSession[0].label);
  const [summary, setSummary] = useState(true);
  const [labDetail, setLabDetail] = useState(false);
  const [otherParameters, setOtherParameters] = useState(false);
  const [observation, setObservation] = useState(false);
  const [user, setUser] = useState({
    business_id: "6597d3f68836f7c222e1a504",
    // doctor_id: "",
    // patient_id: "",
    // case_id: "",
    blood_pressure: "",
    lab_finding_cbc_count: "",
    lab_finding_blood_sugar: "",
    lab_finding_crp: "",
    lab_finding_tsh: "",
    lab_finding_doppler: "",
    lab_finding_other_relevant_note: "",
    wound_length: 0,
    wound_breadth: 0,
    created_by: "",
    wound_depth: 0,

    // tissue_classification : [
    //   {
    //     tissue_color:"",
    //     tissue_generation_percent: "",
    //   }
    // ],
    wound_area: area,
    wound_volume: volume,
    pink: 0,
    yellow: 0,
    black: 0,
    brown: 0,
    green: 0,
    surrounding_skin: "",
    report_link: [],
    report_name: [],
    wound_exudates: "",
    wound_exudate_type: "",
    wound_odour: "",
    pain: "",
    pain_score: "",
    infection: "",
  });
  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(sessionData)) {
      setUser({
        blood_pressure: sessionData?.blood_pressure,
        lab_finding_cbc_count: sessionData.lab_finding_cbc_count,
        lab_finding_blood_sugar: sessionData?.lab_finding_blood_sugar,
        lab_finding_crp: sessionData?.lab_finding_crp,
        lab_finding_tsh: sessionData?.lab_finding_tsh,
        lab_finding_doppler: sessionData?.lab_finding_doppler,
        lab_finding_other_relevant_note:
          sessionData?.lab_finding_other_relevant_note,
        wound_length: sessionData?.wound_length,
        wound_breadth: sessionData?.wound_breadth,

        wound_depth: sessionData?.wound_depth,
        wound_area: sessionData?.wound_area,
        wound_volume: sessionData?.wound_volume,

        yellow:
          sessionData?.tissue_classification[4]?.tissue_generation_percent,
        black: sessionData?.tissue_classification[1]?.tissue_generation_percent,
        brown: sessionData?.tissue_classification[2]?.tissue_generation_percent,
        green: sessionData?.tissue_classification[3]?.tissue_generation_percent,
        surrounding_skin: sessionData?.surrounding_skin,
        report_link: sessionData?.report_link,
        report_name: sessionData?.report_name,
        wound_exudates: sessionData?.wound_exudates,
        wound_exudate_type: sessionData?.wound_exudate_type,
        wound_odour: sessionData?.wound_odour,
        pain: sessionData?.pain,
        pain_score: sessionData?.pain_score,
        infection: sessionData?.infection,
        create: sessionData?.created_at,
      });
      setArea(sessionData?.wound_area);
      setVolume(sessionData?.wound_volume);
      setEpithelialize(
        parseInt(
          sessionData?.tissue_classification[0]?.tissue_generation_percent
        )
      );
    }
  }, [sessionData]);

  //Update Images

  const uploadDoctorPhoto = async (keyName, keyValue) => {
    setErrors({});
    let update = {};
    let data = new FormData();
    data.append("file", keyValue);

    let config = {
      method: "post",
      url: `media/upload`,
      data: data,
    };

    // let result = await axios.request(config);
    let result = await callUploadApi(`media/upload`, "post", data);
    //console.log(result);
    if (result?.data?.link) {
      update["link"] = result?.data?.link;
      update["file_name"] = result?.data?.file_name;
    }
    let arr = [...woundImg];
    arr.push(update);
    setWoundImg([...arr]);
  };

  //Delete Images
  const imageRemoveHandler = (item) => {
    let filt = woundImg.filter((option) => option !== item);
    setWoundImg(filt);
  };

  //Update and Create Session Detail
  const patientDetailHandler = async (keyName, keyValue) => {
    let update = { ...user };

    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    if (keyName === "session_type") {
      if (keyValue === differentSession[0].label) {
        setSummary(true);
        setOtherParameters(false);
        setObservation(false);
        setLabDetail(false);
      } else if (keyValue === differentSession[1].label) {
        setSummary(false);
        setOtherParameters(false);
        setObservation(false);
        setLabDetail(true);
      } else if (keyValue === differentSession[2].label) {
        setSummary(false);
        setOtherParameters(true);
        setObservation(false);
        setLabDetail(false);
      } else if (keyValue === differentSession[3].label) {
        setSummary(false);
        setOtherParameters(false);
        setObservation(true);
        setLabDetail(false);
      }
      setSessionType(keyValue);
    }
    if (keyName === "report_link") {
      let data = new FormData();
      data.append("file", keyValue);

      // let result = await axios.request(config);
      let result = await callUploadApi(`media/upload`, "post", data);
      //console.log(result);
      if (result?.data?.link) {
        update["report_link"]?.push(result?.data?.link);
        update["report_name"]?.push(result?.data?.file_name);
      }
    } else {
      update[keyName] = keyValue;
    }
    setUser(update);
  };
  useEffect(() => {
    async function fetchContent(sessionId) {
      if (sessionId === undefined) {
        setObservationId("");
        setObservations("");
        setTreatmentNotes("");
        setPrivateNotes("");
        setPrivateId("");
        setTreatmentId("");
      } else {
        try {
          const res = await callApi(`sessionnote/list`, "post", {
            pageNum: 1,
            pageSize: 50,
            filters: {
              session_id: sessionId, // Include any specific filters here
            },
          });

          if (res && res?.status === "Success") {
            const { list } = res?.data;

            let arr = [...list];
            if (arr.length === 0) {
              setObservationId("");
              setObservations("");
              setTreatmentNotes("");
              setPrivateNotes("");
              setPrivateId("");
              setTreatmentId("");
            } else {
              for (let item of arr) {
                if (item?.note_type === "observation") {
                  setObservationId(item?._id);
                  setObservations(item?.text);
                } else if (item?.note_type === "note") {
                  setPrivateId(item?._id);
                  setPrivateNotes(item?.text);
                } else if (item?.note_type === "treatment") {
                  setTreatmentId(item?._id);
                  setTreatmentNotes(item?.text);
                }
              }
            }
          } else {
          }
        } catch (err) {}
      }
    }
    fetchContent(sessionId);
  }, [sessionId]);

  useEffect(() => {
    //setUser({wound_volume: volume})
    let valu =
      user?.wound_breadth * user?.wound_length * parseInt(user?.wound_depth);
    setVolume(valu.toFixed(2));
  }, [user?.wound_breadth, user?.wound_length, user?.wound_depth]);
  useEffect(() => {
    let val = user?.wound_breadth * user?.wound_length;
    setArea(val.toFixed(2));
  }, [user?.wound_breadth, user?.wound_length]);
  useEffect(() => {
    setEpithelialize(
      parseInt(
        100 -
          (parseInt(user?.black) +
            parseInt(user?.green) +
            parseInt(user?.brown) +
            parseInt(user?.yellow))
      )
    );
  }, [user?.black, user?.green, user?.yellow, user?.brown]);

  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};

    if (user?.length === 0) {
      isValid = false;
      errors["woundImg"] = "Wound Image cannot be blank";
    }

    return {
      isValid,
      errors,
    };
  };
  console.log(user, patientId, doctorIdd, caseId);
  const handleApi = async (user, woundImg) => {
    let update = {};
    if (patientId && caseId) {
      update.doctor_id = doctorIdd;
      update.case_id = caseId;
      update.patient_id = patientId;
      update.wound_area = area;
      update.wound_volume = volume;
      if (userType === "patient") {
        update.created_by = patientId;
      }
      let arr = [];
      arr.push(
        { tissue_color: "pink", tissue_generation_percent: epithelialize },
        { tissue_color: "black", tissue_generation_percent: user?.black },
        { tissue_color: "brown", tissue_generation_percent: user?.brown },
        { tissue_color: "green", tissue_generation_percent: user?.green },
        { tissue_color: "yellow", tissue_generation_percent: user?.yellow }
      );
      update["tissue_classification"] = [...arr];
      if (sessionData && sessionId) {
        setLoading(true);
        updateSessionDetail({ ...user, ...update }, sessionId).then((res) => {
          setLoading(false);
        });
        if (observationId) {
          setLoading(true);
          const res = await callApi(
            `sessionnote/${observationId}/update`,
            "post",
            { sessionnote: { text: observations } }
          );
          if (res && res.status === "Status") {
            //setObservationId("")
            setLoading(false);
          }
        } else {
          await callApi(`sessionnote/new`, "post", {
            sessionnote: {
              business_id: "6597d3f68836f7c222e1a504",
              case_id: caseId,
              session_id: sessionId,
              note_made_reference: "observation", // enum ["doctor", "patient"]
              note_made_by: doctorIdd, //id
              text: observations,
              note_type: "observation", // enum ["observation", "treatment", "note"]
            },
          });
        }
        if (privateId) {
          const res = await callApi(`sessionnote/${privateId}/update`, "post", {
            sessionnote: { text: privateNotes },
          });
          if (res && res.status === "Status") {
            //setPrivateId("")
          }
        } else {
          await callApi(`sessionnote/new`, "post", {
            sessionnote: {
              business_id: "6597d3f68836f7c222e1a504",
              case_id: caseId,
              session_id: sessionId,
              note_made_reference: "note", // enum ["doctor", "patient"]
              note_made_by: doctorIdd, //id
              text: privateNotes,
              note_type: "note", // enum ["observation", "treatment", "note"]
            },
          });
        }
        if (treatmentId) {
          await callApi(`sessionnote/${treatmentId}/update`, "post", {
            sessionnote: { text: treatmentNotes },
          }).then((res) => {
            if (res && res.status === "Status") {
              //setTreatmentId("")
            }
          });
        } else {
          await callApi(`sessionnote/new`, "post", {
            sessionnote: {
              business_id: "6597d3f68836f7c222e1a504",
              case_id: caseId,
              session_id: sessionId,
              note_made_reference: "treatment", // enum ["doctor", "patient"]
              note_made_by: doctorIdd, //id
              text: treatmentNotes,
              note_type: "treatment", // enum ["observation", "treatment", "note"]
            },
          });
        }
      }
    }
    const check = handleValidation(woundImg);
    if (check.isValid && patientId && caseId) {
      setLoading(true);
      addNewSession({ ...user, ...update }).then((res) => {
        //if(res & res?.status === "Success"){
        const { session } = res?.data;
        setLoading(false);
        window.localStorage.setItem(SESSION_ID, res?.data?.session?._id);
        dispatch(setSessionId(session?._id));
        toast(SuccessMsg("Session has been Created sucessfully "), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
        //if(session?.id){
        let count = 0;
        for (let item of woundImg) {
          if (item?.link) {
            setLoading(true);
            callApi(`sessionimage/new`, "post", {
              sessionimage: {
                business_id: "6597d3f68836f7c222e1a504",
                doctor_id: doctorIdd,
                patient_id: patientId,
                case_id: caseId,
                session_id: session?._id,
                name: item?.file_name,
                url: item?.link,
              },
            }).then((res) => {
              const result = res?.data?.sessionimage?._id;

              callApi(
                `sessionimage/${result}/session/${session?._id}/make-primary`,
                "post"
              ).then((res) => {
                count++;

                if (woundImg.length === count) {
                  navigate("/cases-page");
                  dispatch(setCases(cases?.[0]?.label));
                  dispatch(setOngoing(true));
                  dispatch(setCompleted(false));
                  dispatch(setReffered(false));
                  dispatch(setConsult(false));
                }
              });

              setLoading(false);
            });
          }
        }

        if (observations) {
          callApi(`sessionnote/new`, "post", {
            sessionnote: {
              business_id: "6597d3f68836f7c222e1a504",
              case_id: caseId,
              session_id: session?._id,
              note_made_reference: "observation", // enum ["doctor", "patient"]
              note_made_by: doctorIdd, //id
              text: observations,
              note_type: "observation", // enum ["observation", "treatment", "note"]
            },
          });
        }
        if (treatmentNotes) {
          callApi(`sessionnote/new`, "post", {
            sessionnote: {
              business_id: "6597d3f68836f7c222e1a504",
              case_id: caseId,
              session_id: session?._id,
              note_made_reference: "treatment", // enum ["doctor", "patient"]
              note_made_by: doctorIdd, //id
              text: treatmentNotes,
              note_type: "treatment", // enum ["observation", "treatment", "note"]
            },
          });
        }
        if (privateNotes) {
          callApi(`sessionnote/new`, "post", {
            sessionnote: {
              business_id: "6597d3f68836f7c222e1a504",
              case_id: caseId,
              session_id: session?._id,
              note_made_reference: "note", // enum ["doctor", "patient"]
              note_made_by: doctorIdd, //id
              text: privateNotes,
              note_type: "note", // enum ["observation", "treatment", "note"]
            },
          });
        }

        // }
      });
    } else {
      setErrors(check.errors);
    }
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error">{errors[keyName]}</p>
    ) : null;
  };
  useEffect(() => {
    fetchContent("exudate-type");
    fetchContent("exudates");
    fetchContent("tissue-types");
    fetchContent("pain-score");
    fetchContent("pain-levels");
    fetchContent("wound-edges");
    fetchContent("surrounding-skin");
    fetchContent("co-morbid-diseases");
  }, []);
  function setContentCode(params, code) {
    switch (code) {
      case "exudate-type":
        setExudateType(params);
        break;
      case "exudates":
        setExudates(params);
        break;
      case "pain-score":
        setPainScore(params);
        break;
      case "pain-levels":
        setPain(params);
        break;
      case "surrounding-skin":
        setSurroundingSkin(params);
        break;
      case "tissue-types":
        setTissueTypes(params);
        break;
      case "co-morbid-diseases":
        setMorbidDiseases(params);
        break;
      default:
        break;
    }
  }
  async function fetchContent(code) {
    try {
      const res = await callApi(`content/code/${code}`, "get");

      if (res && res?.status === "Success") {
        const { params } = res?.data?.content;

        let param = params?.map((options) => options?.label);
        setContentCode(param, code);
      } else {
      }
    } catch (err) {}
  }
  const disableOnTrue = (flag) => {
    return {
      opacity: flag === false ? 1 : 1,
      pointerEvents: flag === false ? "none" : "initial",
    };
  };
  //Tissue Graph
  const options_stack = {
    chart: {
      // background: "#f4f4f4",
      // foreColor: '#333',
      background: "#ffffff",
      foreColor: "#111",
      fontWeight: "600",
      stacked: true,
      stackType: "100%",
    },
    xaxis: {
      categories: [""],
      colors: ["#111"],
    },

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: ["#111", "#74AA49", "#feec3b", "#AA4949", "#EA1E63"],
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "",
      alien: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "25px",
      },
    },
  };
  const series_stack = [
    {
      name: "Necrotic",
      data: [user?.black],
    },
    {
      name: "Infected",
      data: [user?.green],
    },
    {
      name: "Slough",
      data: [user?.yellow],
    },
    {
      name: "Granulating",
      data: [user?.brown],
    },
    {
      name: "Epithelializing",
      data: [epithelialize],
    },
  ];

  return (
    <>
      {path === false ? null : <HeaderV2 />}
      {loading && (
        <div className="loadergifdiv">
          {/* <FullPageLoader /> */}
          <img
            src={quoritLogo}
            alt="loader"
            className="img-fluid"
            style={{ maxWidth: "320px" }}
          />
        </div>
      )}

      <Container
        className={`${styles.session_add_flex}`}
        style={{ padding: `${padnum}em 0 4.688em` }}
      >
        {path === false ? null : woundImg?.length === 0 ? (
          <div style={{ padding: "10px" }}>
            <input
              type="file"
              hidden
              id="uploadimage49"
              onChange={(e) => uploadDoctorPhoto("url", e.target.files[0])}
            />
            <label
              htmlFor="uploadimage49"
              className={`${styles.upload_images1}`}
            >
              <img
                src={uploadImages}
                alt="img"
                style={{ marginRight: "10px" }}
              />
              <h2>Upload Images</h2>
            </label>
          </div>
        ) : (
          <div className={`${styles.show_img}`}>
            <div className={`${styles.show_img_child}`}>
              {woundImg?.map((item) => (
                <>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={item.link}
                      width={150}
                      height={120}
                      alt={"img"}
                      style={{ margin: "10px", borderRadius: "30px" }}
                    />
                    <span
                      className={styles.gradient}
                      style={{}}
                      onClick={() => imageRemoveHandler(item)}
                    >
                      x
                    </span>
                  </div>
                </>
              ))}
            </div>

            <div className={`${styles.upload_img}`}>
              <input
                type="file"
                hidden
                id="uploadimage79"
                onChange={(e) => uploadDoctorPhoto("url", e.target.files[0])}
              />

              <label
                htmlFor="uploadimage79"
                className={`${styles.upload_more}`}
              >
                <h4>Upload More Images</h4>
              </label>
            </div>
          </div>
        )}

        {/* <div className={`${styles.session_flex}`}>
                <NavLink 
                 to="summary"   
                 style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isPending ? "#cccccc" : "",
                      color: isActive ? "red" : "#cccccc",
                      viewTransitionName: isTransitioning ? "slide" : "",
                    };
                  }}
                    >
                <h2>Summary</h2>
                </NavLink>
                <NavLink 
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isPending ? "#cccccc" : "",
                      color: isActive ? "red" : "#cccccc",
                      viewTransitionName: isTransitioning ? "slide" : "",
                    };
                  }}
                //activeClassName={styles.active} 
                to="lab-detail">
                <h2>Lab Detail</h2>
                </NavLink>
                <NavLink 
                //activeClassName={styles.active} 
                style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isPending ? "#cccccc" : "",
                      color: isActive ? "red" : "#cccccc",
                      viewTransitionName: isTransitioning ? "slide" : "",
                    };
                  }}
                to="other-parameter">
                <h2>Other Wound Parameters</h2>
                </NavLink>
                <NavLink 
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isPending ? "#cccccc" : "",
                      color: isActive ? "red" : "#cccccc",
                      viewTransitionName: isTransitioning ? "slide" : "",
                    };
                  }}
                //activeClassName={styles.active} 
                to="observation">
                <h2>Comments & Observations</h2>
                </NavLink>
            </div> */}
        {path === false ? null : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {renderError("woundImg")}
          </div>
        )}
        <ul className={`${styles.radio_container}`}>
          {differentSession.map((item) => (
            <RadioButton
              key={item.label}
              checked={item.label === sessionType}
              keyName={"session_type"}
              value={item.value}
              label={item.label}
              labelId={item.label}
              patientDetailHandler={patientDetailHandler}
            />
          ))}
        </ul>
        {path === false ? (
          sessionData?.created_by === doctorIdd ? (
            <div className={`${styles.disable_flex}`}>
              <span>{disable ? "Disable Edit Mode" : "Enable Edit Mode"}</span>
              <span>
                <ToggleSwitch2
                  type="checkbox"
                  checked={disable}
                  keyName={"session_img"}
                  disable={disable}
                  setDisable={setDisable}
                  // value={!disable}
                  labelId={`s45`}
                />
              </span>
            </div>
          ) : null
        ) : null}
        <div style={disableOnTrue(disable)}>
          {summary ? (
            <>
              <Row>
                <Col>
                  <h2 className={styles.wound_dimension}>Wound Dimensions</h2>
                </Col>
                <Col>
                  <h2 className={styles.tissue_flex}>Tissue Classification </h2>
                </Col>
              </Row>

              <Row className={`${styles.patient_flex_body}`}>
                <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                  <div className={`${styles.patient_flex_child}`}>
                    <label>
                      Length<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      //class="form-control"
                      placeholder="Enter Length in cm"
                      value={user?.wound_length ? user?.wound_length : ""}
                      onChange={(e) =>
                        patientDetailHandler("wound_length", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    {renderError("wound_length")}
                  </div>
                  <div className={`${styles.patient_flex_child}`}>
                    <label>
                      Width<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      //class="form-control"
                      placeholder="Enter Width in cm"
                      value={user?.wound_breadth ? user?.wound_breadth : ""}
                      onChange={(e) =>
                        patientDetailHandler("wound_breadth", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    {renderError("wound_breadth")}
                  </div>
                  <div className={`${styles.patient_flex_child}`}>
                    <label>
                      Depth Max<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      //class="form-control"
                      placeholder="Depth in cm*"
                      value={user?.wound_depth ? user?.wound_depth : ""}
                      onChange={(e) =>
                        patientDetailHandler("wound_depth", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    {renderError("wound_depth")}
                  </div>
                  <div className={`${styles.patient_flex_child}`}>
                    <label>
                      Area<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      // class="form-control"
                      placeholder="Area in cm2"
                      value={area}
                      onChange={(e) => {
                        setArea(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div style={{ "marginLeft": "40px" }}>{renderError("wound_area")}</div> */}
                  <div className={`${styles.patient_flex_child}`}>
                    <label>
                      Volume<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      //class="form-control"
                      placeholder="Volume in cm2"
                      value={volume}
                      onChange={(e) => {
                        setVolume(e.target.value);
                        // patientDetailHandler("wound_volume", e.target.value)
                      }}
                    />
                  </div>
                  {/* <div style={{ "marginLeft": "40px" }}>{renderError("wound_volume")}</div> */}
                </Col>
                <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                  <div className={styles.tissue_percent}>
                    Total Tissue percentage = 100%
                  </div>
                  {path ? (
                    <>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "black", fontWeight: "500" }}>
                          Necrotic :
                        </label>
                        <input
                          type="text"
                          // class="form-control"
                          placeholder="Enter % age of wound area"
                          value={user?.black ? user?.black : ""}
                          onChange={(e) =>
                            patientDetailHandler("black", e.target.value)
                          }
                        />
                      </div>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "green", fontWeight: "500" }}>
                          Infected :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area"
                          value={user?.green ? user?.green : ""}
                          onChange={(e) =>
                            patientDetailHandler("green", e.target.value)
                          }
                        />
                      </div>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "yellow", fontWeight: "500" }}>
                          Slough :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area*"
                          value={user?.yellow ? user?.yellow : ""}
                          onChange={(e) =>
                            patientDetailHandler("yellow", e.target.value)
                          }
                        />
                      </div>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "brown", fontWeight: "500" }}>
                          Granulating :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area "
                          value={user?.brown ? user?.brown : ""}
                          onChange={(e) =>
                            patientDetailHandler("brown", e.target.value)
                          }
                        />
                      </div>
                      {/* <div className={`${styles.patient_flex_child2}`}>
                        <SelectDropdown label={"Blood Group*"} labelId={"blood12"}  />
                        </div> */}
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "pink", fontWeight: "500" }}>
                          Epithelializing :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area"
                          value={
                            epithelialize !== 100 ? parseInt(epithelialize) : ""
                          }
                          onChange={(e) =>
                            patientDetailHandler("pink", e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : disable ? (
                    <>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "black", fontWeight: "500" }}>
                          Necrotic :
                        </label>
                        <input
                          type="text"
                          // class="form-control"
                          placeholder="Enter % age of wound area"
                          value={user?.black ? user?.black : ""}
                          onChange={(e) =>
                            patientDetailHandler("black", e.target.value)
                          }
                        />
                      </div>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "green", fontWeight: "500" }}>
                          Infected :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area"
                          value={user?.green ? user?.green : ""}
                          onChange={(e) =>
                            patientDetailHandler("green", e.target.value)
                          }
                        />
                      </div>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "yellow", fontWeight: "500" }}>
                          Slough :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area*"
                          value={user?.yellow ? user?.yellow : ""}
                          onChange={(e) =>
                            patientDetailHandler("yellow", e.target.value)
                          }
                        />
                      </div>
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "brown", fontWeight: "500" }}>
                          Granulating :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area "
                          value={user?.brown ? user?.brown : ""}
                          onChange={(e) =>
                            patientDetailHandler("brown", e.target.value)
                          }
                        />
                      </div>
                      {/* <div className={`${styles.patient_flex_child2}`}>
                        <SelectDropdown label={"Blood Group*"} labelId={"blood12"}  />
                        </div> */}
                      <div className={`${styles.patient_flex_child7}`}>
                        <label style={{ color: "pink", fontWeight: "500" }}>
                          Epithelializing :
                        </label>
                        <input
                          type="text"
                          //class="form-control"
                          placeholder=" Enter % age of wound area"
                          value={
                            epithelialize !== 100 ? parseInt(epithelialize) : ""
                          }
                          onChange={(e) =>
                            patientDetailHandler("pink", e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Chart
                        options={options_stack}
                        series={series_stack}
                        type="bar"
                        height={450}

                        // stacked= {true}
                        // stackType="100%"
                      />
                    </>
                  )}
                </Col>
              </Row>
            </>
          ) : null}

          {labDetail ? (
            <Row className={`${styles.patient_flex_body}`}>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <label>CBC Count</label>
                  <input
                    type="text"
                    // class="form-control"
                    placeholder="CBC Count"
                    value={user?.lab_finding_cbc_count}
                    onChange={(e) =>
                      patientDetailHandler(
                        "lab_finding_cbc_count",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Blood Sugar</label>
                  <input
                    type="text"
                    // class="form-control"
                    placeholder="Blood Sugar"
                    value={user?.lab_finding_blood_sugar}
                    onChange={(e) =>
                      patientDetailHandler(
                        "lab_finding_blood_sugar",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Blood Pressure</label>
                  <input
                    type="text"
                    // class="form-control"
                    placeholder="Blood Pressure"
                    value={user?.blood_pressure}
                    onChange={(e) =>
                      patientDetailHandler("blood_pressure", e.target.value)
                    }
                  />
                </div>
                <div className={`${styles.profile_flex_child2}`}>
                  <input
                    type="file"
                    hidden
                    id="licence93"
                    onChange={(e) =>
                      patientDetailHandler("report_link", e.target.files[0])
                    }
                  />
                  <label htmlFor="licence93">
                    <img
                      src="./images/upload-icon-grey.svg"
                      width={30}
                      alt="license_upload"
                    />
                    <span style={{ marginLeft: "10px" }}>Upload Report</span>
                  </label>
                </div>
                {user?.report_link?.length !== 0 ? (
                  <div className={`${styles.profile_flex_child2}`}>
                    <Link
                      className={styles.download_link}
                      to={user?.report_link[user?.report_link?.length - 1]}
                      target="_blank"
                      download
                    >
                      {/* <img src={uploadImages} alt="license_upload" /> */}
                      <span style={{ marginLeft: "10px" }}>
                        Download Report
                      </span>
                    </Link>
                  </div>
                ) : null}
                {/* <div>
                        <img src={""}/>
                      </div> */}
              </Col>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <label>CRP</label>
                  <input
                    type="text"
                    //class="form-control"
                    placeholder="CRP"
                    value={user?.lab_finding_crp}
                    onChange={(e) =>
                      patientDetailHandler("lab_finding_crp", e.target.value)
                    }
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>TSH</label>
                  <input
                    type="text"
                    //class="form-control"
                    placeholder="TSH"
                    value={user?.lab_finding_tsh}
                    onChange={(e) =>
                      patientDetailHandler("lab_finding_tsh", e.target.value)
                    }
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Doppler</label>
                  <input
                    type="text"
                    //class="form-control"
                    placeholder="Doppler"
                    value={user?.lab_finding_doppler}
                    onChange={(e) =>
                      patientDetailHandler(
                        "lab_finding_doppler",
                        e.target.value
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          {otherParameters ? (
            <Row className={`${styles.patient_flex_body}`}>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <SelectDropdown
                    label={"Surrounding Skin"}
                    data={surroundingSkin}
                    labelId={"surroundingskin"}
                    keyName={"surrounding_skin"}
                    value={user?.surrounding_skin}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <SelectDropdown
                    label={"Wound Exudate"}
                    data={exudates}
                    labelId={"woundexudate"}
                    value={user?.wound_exudates}
                    keyName={"wound_exudates"}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <SelectDropdown
                    label={"Wound Exudate Type"}
                    data={exudateType}
                    labelId={"woundexudatetype"}
                    keyName={"wound_exudate_type"}
                    value={user?.wound_exudate_type}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>

                <div className={`${styles.patient_flex_child}`}>
                  <SelectDropdown
                    label={"Pain"}
                    data={pain}
                    labelId={"pain2"}
                    keyName={"pain"}
                    value={user?.pain}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  {/* <label>Pain Score</label>
                        <input type="number" 
                        placeholder="Enter pain score (1 to 10)" 
                        style={{width:"100%"}}
                        value={user?.pain_score}
                        onChange={(e) => patientDetailHandler("pain_score",e.target.value)} */}
                  <SelectDropdown
                    label={"Pain Score"}
                    data={painScore}
                    labelId={"pain8"}
                    keyName={"pain_score"}
                    value={user?.pain_score}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>
              </Col>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <SelectDropdown
                    label={"Wound Odour"}
                    data={infection}
                    labelId={"woundodour"}
                    keyName={"wound_odour"}
                    value={user?.wound_odour}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <SelectDropdown
                    label={"Infection"}
                    data={infection}
                    labelId={"infection1"}
                    keyName={"infection"}
                    value={user?.infection}
                    handleUserDetail={patientDetailHandler}
                  />
                </div>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Other relevent findings</label>
                  <textarea
                    type="text"
                    rows={4}
                    placeholder="Enter Details"
                    value={user?.lab_finding_other_relevant_note}
                    onChange={(e) =>
                      patientDetailHandler(
                        "lab_finding_other_relevant_note",
                        e.target.value
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          {observation ? (
            <Row className={`${styles.patient_flex_body}`}>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                <div className={`${styles.patient_flex_child}`}>
                  <label>Observations</label>
                  <textarea
                    type="text"
                    placeholder="Enter Details"
                    rows={4}
                    value={observations ? observations : ""}
                    onChange={(e) => setObservations(e.target.value)}
                    style={{ padding: "5px" }}
                  />
                </div>
                {userType === "patient" ? null : (
                  <div className={`${styles.patient_flex_child}`}>
                    <label>
                      Private Notes(will not be shared with anyone even if you
                      decide to share case with patient or any other doctor)
                    </label>
                    <textarea
                      type="text"
                      placeholder="Enter Details"
                      rows={4}
                      value={privateNotes ? privateNotes : ""}
                      onChange={(e) => setPrivateNotes(e.target.value)}
                      style={{ padding: "5px" }}
                    />
                  </div>
                )}
              </Col>
              <Col lg={6} className={`${styles.patient_flex_body_part}`}>
                {userType === "patient" ? null : (
                  <div className={`${styles.patient_flex_child}`}>
                    <label>Treatment Notes</label>
                    <textarea
                      type="text"
                      placeholder="Enter Details"
                      rows={4}
                      value={treatmentNotes ? treatmentNotes : ""}
                      onChange={(e) => setTreatmentNotes(e.target.value)}
                      style={{ padding: "5px" }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          ) : null}

          <div className={`${styles.patient_flex_btn}`}>
            <button style={{ backgroundColor: path ? "#a70302" : colorr }}>
              CANCEL
            </button>
            <button
              onClick={() => handleApi(user, woundImg)}
              style={{ backgroundColor: path ? "#a70302" : colorr }}
            >
              SAVE
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddNewSession;
