import style from "./Chat.module.scss";
import { DOCTOR_ID } from "../../components/constant/authEnums";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import callApi from "../../util/apiCaller";
import { toast } from "react-toastify";
import { SuccessMsg } from "../../util/Message";
import EditModal from "../Chat/Modal/EditModal";

const Message = ({
  setRender,
  render,
  case_id,
  id,
  text,
  sender,
  contactName,
  date,
}) => {
  const doctor_id = window.localStorage.getItem(DOCTOR_ID);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(false);

  const handleDeleteMessage = async () => {
    const res = await callApi(
      `case/${case_id}/comment/${id}/update-comment`,
      "post",
      {
        is_deleted: true,
      }
    );
    if (res.status === "Success") {
      setRender(!render);
      toast(SuccessMsg(" Deleted successfully "), {
        className: "mail_toast",
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
        theme: "colored",
      });
    }
  };
  return (
    <>
      {show && (
        <div
          className={`${style.popup}`}
          onMouseOver={() => setShow(true)}
          onMouseOut={() => setShow(false)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={() => setShow(!show)}
          >
            <span
              style={{
                border: "1px solid #111 ",
                cursor: "pointer",
                padding: "7px 10px",
                borderRadius: "10px",
              }}
            >
              close
            </span>
          </div>
          <Link
            className={`${style.subtitle}`}
            onClick={() => {
              setModal(true);
              setShow(!show);
            }}
          >
            <div className={`${style.title}`}>Edit</div>
          </Link>
          <Link
            className={`${style.subtitle}`}
            onClick={() => handleDeleteMessage(id)}
          >
            <div className={`${style.title}`}>Delete</div>
          </Link>
        </div>
      )}
      {sender !== doctor_id ? (
        <div
          style={{
            display: "flex",

            justifyContent: "flex-start",
          }}
        >
          <span
            style={{
              textAlign: "left",
              margin: "5px",
              backgroundColor: "#fbf1e9",

              padding: "1.2em",
              borderRadius: "1em",
            }}
          >
            <h3
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                color: "#ed8847",
              }}
            >
              {contactName}
            </h3>
            <p
              style={{
                fontSize: "1.5em",
              }}
            >
              {text}
            </p>
            <h6
              style={{
                fontSize: "1.2em",
                color: "grey",
              }}
            >
              {moment(date).format("DD-MM-YYYY hh:mm")}
            </h6>
          </span>
        </div>
      ) : null}
      {sender === doctor_id ? (
        <div
          style={{
            display: "flex",

            justifyContent: "flex-end",
          }}
        >
          <div onClick={() => setShow(!show)}>
            <MoreVertIcon className={style.flex_three_dots} />
          </div>
          <span
            style={{
              textAlign: "right",
              margin: "5px",
              backgroundColor: "#fbf1e9",

              padding: "1.5em",
              borderRadius: "1em",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
              }}
            >
              {text}
            </p>
            <h6
              style={{
                fontSize: "1.2em",
                color: "grey",
              }}
            >
              {moment(date).format("DD-MM-YYYY hh:mm")}
            </h6>
          </span>
        </div>
      ) : null}
      <EditModal
        render={render}
        setRender={setRender}
        id={id}
        case_id={case_id}
        className="white_theme"
        show={modal}
        onHide={() => setModal(false)}
      />
    </>
  );
};

export default Message;
