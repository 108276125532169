export const NOT_INITIALIZED = "NotInitialized";
export const AUTHENTICATING = "Authenticating";
export const AUTHENTICATED = "Authenticated";
export const NOT_AUTHENTICATED = "NotAuthenticated";
export const APP_TOKEN = "token";
export const DOCTOR_ID = "doctor_id";
export const PATIENT_ID = "patient_id";
export const SESSION_ID = "session_id";
export const CASE_ID = "case_id";
export const REFFERED_FROM = "reffered_from";
export const USER_TYPE = "user_type";
export const PATIENTS_DOCTOR = "patients_doctor";
export const SESSION_CHAT_ID = "session_chat_id";
