import { NOT_INITIALIZED } from "../components/constant/authEnums";
import {
  FETCHING_STATUS,
  SET_AUTH_STATE,
  SET_IS_AUTHENTICATION,
  SET_USER_DETAIL,
  SET_USER_ID,
  SET_PATIENT_ID,
  SET_CASE_ID,
  SET_SESSION_ID,
  SET_PATIENT_DETAIL,
  SET_CASE_DETAIL,
  SET_IMAGE_GALLERY,
  SET_SESSION_LIST,
  SET_REFFER,
  SET_COMPLETED,
  SET_CONSULT,
  SET_ONGOING,
  SET_REFFERED,
  SET_CASES,
  SET_ACCEPT_CASES,
  SET_SESSION_CHAT_ID,
} from "./actionType";
const initialState = {
  authState: NOT_INITIALIZED,
  isFetching: false,
  isLoggingIn: false,
  isAuthenticated: false,
  user: {},
  id: "",
  patientId: "",
  caseId: "",
  sessionId: "",
  patientDetail: {},
  caseDetail: {},
  imageGallery: [],
  sessionList: [],
  reffer: false,
  reffered: false,
  completed: false,
  ongoing: false,
  consult: false,
  cases: "",
  acceptCases: false,
  sessionChatId: "",
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_STATUS:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SET_AUTH_STATE:
      return {
        ...state,
        authState: action.authState,
      };

    case SET_IS_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.state,
      };
    case SET_USER_DETAIL:
      return {
        ...state,
        user: action.user,
      };
    case SET_USER_ID:
      return {
        ...state,
        id: action.id,
      };
    case SET_PATIENT_ID:
      return {
        ...state,
        patientId: action.patientId,
      };
    case SET_CASE_ID:
      return {
        ...state,
        caseId: action.caseId,
      };
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    case SET_PATIENT_DETAIL:
      return {
        ...state,
        patientDetail: action.patientDetail,
      };
    case SET_CASE_DETAIL:
      return {
        ...state,
        caseDetail: action.caseDetail,
      };
    case SET_IMAGE_GALLERY:
      return {
        ...state,
        imageGallery: action.imageGallery,
      };
    case SET_SESSION_LIST:
      return {
        ...state,
        sessionList: action.sessionList,
      };
    case SET_REFFER:
      return {
        ...state,
        reffer: action.reffer,
      };
    case SET_REFFERED:
      return {
        ...state,
        reffered: action.reffered,
      };
    case SET_COMPLETED:
      return {
        ...state,
        completed: action.completed,
      };
    case SET_CONSULT:
      return {
        ...state,
        consult: action.consult,
      };
    case SET_ONGOING:
      return {
        ...state,
        ongoing: action.ongoing,
      };
    case SET_CASES:
      return {
        ...state,
        cases: action.cases,
      };
    case SET_ACCEPT_CASES:
      return {
        ...state,
        acceptCases: action.acceptCases,
      };
    case SET_SESSION_CHAT_ID:
      return {
        ...state,
        sessionChatId: action.sessionChatId,
      };
    default:
      return state;
  }
};

export default AppReducer;
