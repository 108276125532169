import style from "./countrycode.module.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";

const lightTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const CountryCodeDropdown = ({ textLabel, countries ,id, handleUserDetail,keyName}) => {
  const { width } = useWindowSize();
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Autocomplete
       defaultValue={{code:"IN", phone: "91"}}
        className={style.autocomplete_dropdown}
        id={id}
        options={countries}
        disableClearable
        componentsProps={{
          paper: {
            sx: {
              width: 400,
              borderRadius: width > 768 ? "0.4375em" : "0.4375em",
            },
          },
        }}
        autoHighlight
        getOptionLabel={(option) => `${option.code} + ${option.phone}`}
        //value={user}
        onChange={(e,newValue) => handleUserDetail(keyName,newValue.phone)}
        sx={{
          color: "#111",
          "& fieldset": {
            borderRadius: width > 768 ? "0.4375em" : "0.4375em",
            border: "1px solid #707070",
            color: "#111",
            fontSize:"1em"
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: width > 768 ? "0.4375em" : "0.4375em",
          },
          "& label": {
            lineHeight: width > 768 ? "0.9em" : "0.9em",
            color: "#111",
          },
          "& label.Mui-focused": {
            color: "#111",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: width > 768 ? "0.4375em" : "0.4375em",
            border: "1px solid #707070",
          },
          "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #707070",
            },
          "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#111",
            },
          "& .MuiSvgIcon-root ": {
            fill: "#f77b00 !important",
          },
        }}
        renderOption={(props, option) => (
          <Box
            className={style.option_list}
            component="li"
            {...props}
            sx={{
              minWidth: 350,
              zIndex: 9,
              overflow: "visible",
            }}
          >
            <span>{option.code}</span>{" "}
            <span>{option.label}</span>{" "}
            <span>+{option.phone}</span>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={textLabel}
            inputProps={{
              style: {
                fontSize: width > 768 ? "0.9em" : "0.9em",
                 height: "0.9em",
                 border:"none"
              },
              ...params.inputProps,
              // autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
     </ThemeProvider>
  );
};

export default CountryCodeDropdown;
