import { useState, useEffect, useRef } from "react";
import style from "./Chat.module.scss";
import callApi from "../../util/apiCaller";
import { Container } from "react-bootstrap";
import Message from "./Message";
import {
  CASE_ID,
  DOCTOR_ID,
  SESSION_CHAT_ID,
} from "../../components/constant/authEnums";
import { useSelector } from "react-redux";
import { getSessionChatId } from "../../redux/selector";
import { useRaf } from "react-use";
import HeaderV2 from "../../components/Header/HeaderV2";

const Chat = () => {
  const ref = useRef(null);
  const case_id = window.localStorage.getItem(CASE_ID);
  const session_id = window.localStorage.getItem(SESSION_CHAT_ID);
  const doctor_id = window.localStorage.getItem(DOCTOR_ID);
  const [render, setRender] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  //scroll top
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  //fetch message
  useEffect(() => {
    async function fetchComments(case_id) {
      const res = await callApi(`case/${case_id}/comment-list`, "post");

      if (res.status === "Success") {
        setMessages(res.data.commentList.reverse());
      }
    }
    if (case_id) {
      fetchComments(case_id);
    }
  }, [case_id, render]);

  //send message
  const sendMessage = async () => {
    if (newMessage.trim() && session_id && doctor_id) {
      const res = await callApi(
        `case/${case_id}/doctor/${doctor_id}/add-comment`,
        "post",
        {
          session_id: session_id,
          text: newMessage,
        }
      );

      if (res.status === "Success") {
        setRender(!render);
        setNewMessage("");
      }
      // setMessages([
      //   ...messages,
      //   { id: messages.length + 1, text: newMessage, sender: "user" },
      // ]);
      // setNewMessage("");
    }
  };
  console.log(messages);

  return (
    <>
      <HeaderV2 />
      <div className={style.chat_window}>
        <div
          style={{
            padding: "10px",
            fontSize: "1.5em",

            borderBottom: "1px solid #ccc",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Add Your Comments
        </div>
        <div className={style.messages} ref={ref}>
          {messages?.map((msg, i) => (
            // <div key={msg.id}>
            //   <b>{msg.displayName}</b>: {msg.text}
            // </div>
            <Message
              setRender={setRender}
              render={render}
              case_id={case_id}
              key={msg?._id}
              id={msg?._id}
              text={msg?.text}
              contactName={msg?.comment_user_id?.contact_name}
              sender={msg?.comment_user_id?._id}
              date={msg?.updated_at}
            />
          ))}
        </div>
        <div className={style.input_container}>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? sendMessage() : null)}
            placeholder="Type a message"
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </>
  );
};

export default Chat;
