import { useEffect, useState, useCallback } from "react";
import styles from "./PatientList.module.scss";
import callApi from "../../util/apiCaller";
import { DOCTOR_ID } from "../../components/constant/authEnums";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HeaderV2 from "../../components/Header/HeaderV2";
import { Container } from "react-bootstrap";
import _ from "lodash";
import { useNavigate, Link } from "react-router-dom";
import PatientDetailPage from "../PatientDetailPage/PatientDetailPage";
import { Card } from "react-bootstrap";

const PatientList = () => {
  const navigate = useNavigate();
  const doctor_id = window.localStorage.getItem(DOCTOR_ID);
  const [patientList, setPatientList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [dropdownActive, setDropdownActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const patientDataPopulate = (id) => {
    setPatientId(id);
    setShow(true);
    let arr = [...patientList];
    for (let item of arr) {
      if (id === item?.patient?._id) {
        setData(item?.patient);
      }
    }
  };
  const debouncedHandle = useCallback(
    _.debounce((user) => {
      fetchByDoctorId(user);
    }, 700),
    []
  );
  const fetchByDoctorId = (user) => {
    if (user) {
      callApi(`patient/doctor/${doctor_id}/list`, "post", {
        pageNum: 1,
        pageSize: 50,
        filters: {
          qtext: user,
        },
      }).then((res) => {
        if (res?.status === "Success") {
          const { list } = res?.data;
          setPatientList([...list]);
        } else {
        }
      });
    }
  };
  const patientDetailHandler = (keyName, e) => {
    let search = e.target.value;

    setSearch(search);
  };
  useEffect(() => {
    debouncedHandle(search);
  }, [search]);
  useEffect(() => {
    async function fetchContent(doctor_id) {
      setLoading(true);
      const res = await callApi(`patient/doctor/${doctor_id}/list`, "post", {
        pageNum: 1,
        pageSize: 50,
        filters: {},
      });
      if (res && res?.status === "Success") {
        const { list } = res?.data;
        setPatientList([...list]);
        setLoading(false);
        // toast(SuccessMsg("Patient detail generated sucessfully "), {
        //   className: "mail_toast",
        //   position: "top-center",
        //   hideProgressBar: true,
        //   closeButton: false,
        //   theme: "colored",
        // });
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    }
    if (doctor_id) {
      fetchContent(doctor_id);
    }
  }, [doctor_id, search]);
  useEffect(() => {
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <HeaderV2 />
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Container className={styles.flex_list}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <div
            style={{ margin: "10px 0", paddingLeft: "40px", cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
            <span style={{ fontSize: "20px" }}> Back</span>
            {/* </ArrowBackIcon> */}
          {/* </div>  */}
          {show ? (
            <div onMouseOver={() => setDropdownActive(true)}>
              <MoreVertIcon style={{ fontSize: "2.2em", color: "#ed8847" }} />
            </div>
          ) : null}
        </div>
        {dropdownActive === true && (
          <div
            className={`${styles.dropdown_box}`}
            onMouseOver={() => setDropdownActive(true)}
            onMouseOut={() => setDropdownActive(false)}
          >
            <Link
              className={`${styles.accounts}`}
              onClick={() => setDisable(!disable)}
            >
              <div className={`${styles.title}`}>
                {disable ? "Disable Detail" : "Edit Detail"}
              </div>
            </Link>
          </div>
        )}
        {show ? (
          <PatientDetailPage
            data={data}
            patientId={patientId}
            disable={disable}
          />
        ) : (
          <>
            {" "}
            <div className={`${styles.patient_flex_input}`}>
              <input
                placeholder="Search"
                // value={search}
                onChange={(e) => patientDetailHandler("patient", e)}
              />
              <img src="images/Search.png" alt="" />
            </div>
            {patientList?.map((item) => (
              <div
                className={`${styles.patient_list}`}
                key={item?._id}
                onClick={() => patientDataPopulate(item?.patient?._id)}
              >
                <div className={`${styles.cases_flex}`}>
                  <div className={`${styles.cases_flex_child1}`}>
                    <span style={{ marginRight: "10px" }}>
                      Patient ID: {item?.patient?.patient_num}
                    </span>
                  </div>
                  <div className={`${styles.cases_flex_child2}`}>
                    <div>
                      <h3>{item?.patient?.contact_name}</h3>

                      <p></p>
                      <h5>Phone Number: {item?.patient?.phone_number}</h5>
                    </div>
                    <div>Email Id: {item?.patient?.contact_email}</div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </Container>
    </>
  );
};

export default PatientList;
