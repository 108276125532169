import {
  SET_AUTH_STATE,
  SET_IS_AUTHENTICATION,
  SET_USER_DETAIL,
  SET_USER_ID,
  SET_PATIENT_ID,
  SET_SESSION_ID,
  SET_CASE_ID,
  SET_CASE_DETAIL,
  SET_PATIENT_DETAIL,
  SET_IMAGE_GALLERY,
  SET_SESSION_LIST,
  SET_REFFER,
  SET_ONGOING,
  SET_REFFERED,
  SET_COMPLETED,
  SET_CONSULT,
  SET_CASES,
  SET_ACCEPT_CASES,
  SET_SESSION_CHAT_ID,
} from "./actionType";
import callApi from "../util/apiCaller";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../util/Message";
import {
  APP_TOKEN,
  CASE_ID,
  DOCTOR_ID,
  PATIENT_ID,
  SESSION_ID,
  AUTHENTICATED,
  PATIENTS_DOCTOR,
  REFFERED_FROM,
  USER_TYPE,
} from "../components/constant/authEnums";

export const setAuthState = (authState) => ({
  type: SET_AUTH_STATE,
  authState,
});

export const setAuthentication = (state) => ({
  type: SET_IS_AUTHENTICATION,
  state,
});
export const setUserId = (id) => ({
  type: SET_USER_ID,
  id,
});
export const setRefferedDoctor = (reffer) => ({
  type: SET_REFFER,
  reffer: reffer,
});
export const setAcceptCases = (acceptCases) => ({
  type: SET_ACCEPT_CASES,
  acceptCases: acceptCases,
});
export const setUser = (user) => ({
  type: SET_USER_DETAIL,
  user: user,
});
export const setPatientId = (patientId) => ({
  type: SET_PATIENT_ID,
  patientId: patientId,
});
export const setCaseId = (caseId) => ({
  type: SET_CASE_ID,
  caseId: caseId,
});
export const setSessionId = (sessionId) => ({
  type: SET_SESSION_ID,
  sessionId: sessionId,
});
export const setPatientDetail = (patientDetail) => ({
  type: SET_PATIENT_DETAIL,
  patientDetail: patientDetail,
});
export const setCaseDetail = (caseDetail) => ({
  type: SET_CASE_DETAIL,
  caseDetail: caseDetail,
});
export const setImageGallery = (imageGallery) => ({
  type: SET_IMAGE_GALLERY,
  imageGallery: imageGallery,
});
export const setSessionList = (sessionList) => ({
  type: SET_SESSION_LIST,
  sessionList: sessionList,
});
export const setOngoing = (ongoing) => ({
  type: SET_ONGOING,
  ongoing: ongoing,
});
export const setCompleted = (completed) => ({
  type: SET_COMPLETED,
  completed: completed,
});
export const setReffered = (reffered) => ({
  type: SET_REFFERED,
  reffered: reffered,
});
export const setConsult = (consult) => ({
  type: SET_CONSULT,
  consult: consult,
});
export const setCases = (cases) => ({
  type: SET_CASES,
  cases: cases,
});
export const setSessionChatId = (sessionChatId) => ({
  type: SET_SESSION_CHAT_ID,
  sessionChatId: sessionChatId,
});

export function signupDoctor(doctor, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/doctor/signup`, "post", { doctor });

      if (res && res?.status === "Success") {
        const { doctor } = res?.data;
        if (doctor) {
          dispatch(
            setUser({
              country_code: doctor?.country_code,
              phone_number: doctor?.phone_number,
              email: doctor?.contact_email,
            })
          );
          toast(SuccessMsg("Registered sucessfully"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
        dispatch(
          loginDoctorViaPhoneOTP(
            {
              country_code: doctor?.country_code,
              phone_number: doctor?.phone_number,
            },
            navigate
          )
        );
        dispatch(
          loginDoctorViaEmailOTP(
            {
              email: doctor?.contact_email,
            },
            navigate
          )
        );
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {}
  };
}

export function signupPatient(patient, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/patient/signup`, "post", { patient });

      if (res && res?.status === "Success") {
        const { patient } = res?.data;
        if (patient) {
          dispatch(
            setUser({
              country_code: patient?.country_code,
              phone_number: patient?.phone_number,
              email: patient?.contact_email,
            })
          );
          toast(SuccessMsg("Registered sucessfully"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
        dispatch(
          loginPatientViaPhoneOTP(
            {
              country_code: patient?.country_code,
              phone_number: patient?.phone_number,
            },
            navigate
          )
        );
        dispatch(
          loginPatientViaEmailOTP(
            {
              email: patient?.contact_email,
            },
            navigate
          )
        );
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {}
  };
}

export function loginDoctorViaPhoneOTP(doctor, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/doctor/phone-otp`, "post", doctor);

      if (res && res?.status === "Success") {
        dispatch(setUser(doctor));
        if (navigate) {
          navigate("/verify-otp");
          toast(SuccessMsg("Otp is Sent to registered phone/email"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function loginPatientViaPhoneOTP(patient, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/patient/phone-otp`, "post", patient);

      if (res && res?.status === "Success") {
        dispatch(setUser(patient));
        if (navigate) {
          navigate("/verify-otp");
          toast(SuccessMsg("Otp is Sent to registered phone/email"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function loginDoctorViaEmailOTP(doctor, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/doctor/email-otp`, "post", doctor);

      if (res && res?.status === "Success") {
        dispatch(setUser(doctor));
        if (navigate) {
          navigate("/verify-otp");
          toast(SuccessMsg("Otp is Sent to registered phone/email"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function loginPatientViaEmailOTP(patient, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/patient/email-otp`, "post", patient);

      if (res && res?.status === "Success") {
        dispatch(setUser(patient));
        if (navigate) {
          navigate("/verify-otp");
          toast(SuccessMsg("Otp is Sent to registered phone/email"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function loginDoctorViaPassword(doctor, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/doctor/login`, "post", { doctor });

      if (res && res?.status === "Success") {
        const { doctor, token } = res?.data;
        if (token) {
          window.localStorage.setItem(DOCTOR_ID, doctor?._id);
          window.localStorage.setItem(APP_TOKEN, token);
          dispatch(setAuthState(AUTHENTICATED));
          dispatch(setAuthentication(true));
          if (window.localStorage.getItem(APP_TOKEN)) {
            dispatch(fetchDoctorDetail(doctor?._id));
            // dispatch(setUser(doctor));
            dispatch(setUserId(doctor?._id));
          }
        }

        if (navigate) {
          navigate("/home-page");
          toast(SuccessMsg("Logged in sucessfully"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(ErrorsMsg("Something went wrong"), {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function loginPatientViaPassword(patient, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/patient/login`, "post", { patient });

      if (res && res?.status === "Success") {
        const { patient, token } = res?.data;
        if (token) {
          window.localStorage.setItem(DOCTOR_ID, patient?._id);
          window.localStorage.setItem(APP_TOKEN, token);
          dispatch(setAuthState(AUTHENTICATED));
          dispatch(setAuthentication(true));
          if (window.localStorage.getItem(APP_TOKEN)) {
            dispatch(fetchPatientDetail(patient?._id));
            // dispatch(setUser(patient));
            dispatch(setUserId(patient?._id));
          }
        }

        if (navigate) {
          navigate("/home-page");
          toast(SuccessMsg("Logged in sucessfully"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(ErrorsMsg("Something went wrong"), {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function fetchDoctorDetail(doctor_id) {
  return async (dispatch) => {
    try {
      const res = await callApi(`doctor/${doctor_id}`, "get");

      if (res && res?.status === "Success") {
        const { doctor } = res?.data;
        dispatch(setUser(doctor));
        dispatch(setUserId(doctor?._id));
        // if(navigate){
        //     navigate("/home-page")
        // }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function fetchPatientDetail(patient_id) {
  return async (dispatch) => {
    try {
      const res = await callApi(`patient/${patient_id}`, "get");

      if (res && res?.status === "Success") {
        const { patient } = res?.data;
        dispatch(setUser(patient));
        dispatch(setUserId(patient?._id));
        // if(navigate){
        //     navigate("/home-page")
        // }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function VerifyDoctorViaOtp(doctors, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/doctor/validate-otp`, "post", {
        doctor: doctors,
      });

      if (res && res?.status === "Success") {
        const { doctor, token } = res?.data;

        if (token) {
          window.localStorage.setItem(DOCTOR_ID, doctor?._id);
          window.localStorage.setItem(APP_TOKEN, token);
          dispatch(setAuthState(AUTHENTICATED));
          dispatch(setAuthentication(true));
          if (window.localStorage.getItem(APP_TOKEN)) {
            dispatch(fetchDoctorDetail(doctor?._id));
          }
        }
        if (navigate) {
          navigate("/home-page");
          toast(SuccessMsg("Successfully logged In"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function VerifyPatientViaOtp(patients, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`auth/patient/validate-otp`, "post", {
        patient: patients,
      });

      if (res && res?.status === "Success") {
        const { patient, token } = res?.data;

        if (token) {
          window.localStorage.setItem(DOCTOR_ID, patient?._id);
          window.localStorage.setItem(APP_TOKEN, token);
          dispatch(setAuthState(AUTHENTICATED));
          dispatch(setAuthentication(true));
          if (window.localStorage.getItem(APP_TOKEN)) {
            dispatch(fetchPatientDetail(patient?._id));
          }
        }
        if (navigate) {
          navigate("/home-page");
          toast(SuccessMsg("Successfully logged In"), {
            position: "top-center",
            hideProgressBar: true,
            closeButton: false,
            className: "mail_toast",
          });
        }
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}

export function updateDoctorDetail(doctor_id, doctor) {
  return async (dispatch) => {
    try {
      const res = await callApi(`doctor/${doctor_id}/update`, "post", {
        doctor,
      });

      if (res && res?.status === "Success") {
        const { doctor } = res?.data;
        dispatch(setUser(doctor));
        toast(SuccessMsg("Doctor detail has been updated sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}

export function addPatientDetail(doctor, navigate) {
  return async (dispatch) => {
    try {
      const res = await callApi(`patient/new-patient`, "post", {
        patient: doctor,
      });

      if (res && res?.status === "Success") {
        const { patient } = res?.data;
        window.localStorage.setItem(PATIENT_ID, patient?._id);
        dispatch(setPatientId(patient?._id));
        navigate("/add-new-case");
        toast(SuccessMsg("Patient detail has been added sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      } else {
        toast(SuccessMsg("Something went wrong"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export const addNewCases = async (cases, navigate) => {
  //return async dispatch => {
  try {
    const res = await callApi(`case/new-case`, "post", { case: cases });

    if (res && res?.status === "Success") {
      if (navigate) {
        navigate("/add-new-session");
      }
      if (res?.data?.case) {
        window.localStorage.setItem(CASE_ID, res?.data?.case?._id);
        toast(SuccessMsg("New case has been added sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
      return res;
    } else {
      toast(SuccessMsg("Something went wrong"), {
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
        className: "mail_toast",
      });
    }
  } catch (err) {
    // dispatch(SET_IS_LOADING(false));
  }
  //}
};
export function updateCasesDetail(cases, case_id) {
  return async (dispatch) => {
    try {
      const res = await callApi(`case/${case_id}/update`, "post", {
        case: cases,
      });

      if (res && res?.status === "Success") {
        toast(SuccessMsg("Case detail has been updated sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      } else {
        toast(ErrorsMsg("Something went wrong"), {
          className: "mail_toast",
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export function updatePatientDetail(patient, patient_id) {
  return async (dispatch) => {
    try {
      const res = await callApi(`patient/${patient_id}/update`, "post", {
        patient,
      });

      if (res && res?.status === "Success") {
        toast(SuccessMsg("Patient detail has been updated sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      } else {
        toast(ErrorsMsg("Something went wrong"), {
          className: "mail_toast",
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));
    }
  };
}
export const updateSessionDetail = async (session, session_id) => {
  // return async dispatch => {
  try {
    const res = await callApi(`session/${session_id}/update`, "post", {
      session,
    });

    if (res && res?.status === "Success") {
      toast(SuccessMsg("session has been updated sucessfully"), {
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
        className: "mail_toast",
      });
      return res;
    } else {
      toast(ErrorsMsg("Something went wrong"), {
        className: "mail_toast",
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
      });
    }
  } catch (err) {
    // dispatch(SET_IS_LOADING(false));
  }
  //}
};
export const acceptRefferedCases = async (case_id, refer_id, refername) => {
  //return async (dispatch) => {
  let ress = {};
  if (refername === "accept") {
    ress["is_referred_case_accepted"] = true;
  } else if (refername === "reject") {
    ress["is_referred_case_rejected"] = true;
  }
  try {
    const res = await callApi(
      `case/${case_id}/referred-case/${refer_id}/action`,
      "post",
      {
        ...ress,
      }
    );

    if (res && res?.status === "Success") {
      if (refername === "accept") {
        //dispatch(setAcceptCases(true));
        toast(SuccessMsg("Case has been accepted sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      } else {
        // dispatch(setAcceptCases(true));
        toast(SuccessMsg("Case has been rejected sucessfully"), {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        });
      }
      return res;
    } else {
      toast(ErrorsMsg("Something went wrong"), {
        className: "mail_toast",
        position: "top-center",
        hideProgressBar: true,
        closeButton: false,
      });
    }
  } catch (err) {
    // dispatch(SET_IS_LOADING(false));
  }
  // };
};
export const addNewSession = async (session, navigate) => {
  return await callApi(`session/new`, "post", { session });
};

export const logoutHandler = () => {
  return (dispatch) => {
    window.localStorage.removeItem(DOCTOR_ID);
    window.localStorage.removeItem(PATIENT_ID);
    window.localStorage.removeItem(CASE_ID);
    window.localStorage.removeItem(SESSION_ID);
    window.localStorage.removeItem(APP_TOKEN);
    window.localStorage.removeItem(PATIENTS_DOCTOR);
    window.localStorage.removeItem(REFFERED_FROM);
    window.localStorage.removeItem(USER_TYPE);

    window.location = "/user-type-page";
  };
};
