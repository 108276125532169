import { Container, Stack } from "react-bootstrap";
import styles from "./registrationPage.module.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { countries } from "../../db/dataTypesData";
import CountryCodeDropdown from "../../components/CountryCodeDropdown/CountryCodeDropdown";
import SelectDropdown from "../../components/SelectDropDown/SelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import { signupDoctor, signupPatient } from "../../redux/action";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutoCompleteFieldLightTheme from "../../components/AutoCompleteFieldLightTheme/AutoCompleteFieldLightTheme";
import { USER_TYPE } from "../../components/constant/authEnums";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import _ from "lodash";
import { toast } from "react-toastify";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";

const salute = ["Dr", "Prof", "Mr", "Mrs", "Ms"];

const RegistrationPage = () => {
  const [user, setUser] = useState({
    contact_email: "",
    contact_name: "",
    country_code: "91",
    phone_number: "",
    local_password: "",
    business_id: "6597d3f68836f7c222e1a504",
    location: "",
    role: "manager",
    username: "",
    salute: "Dr",
  });
  const userType = window.localStorage.getItem(USER_TYPE);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUserDetail = (keyName, keyValue) => {
    let update = { ...user };
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
    update[keyName] = keyValue;
    setUser(update);
  };
  useEffect(() => {
    if (user?.country_code !== "91") {
      toast(
        SuccessMsg(
          "Mobile OTP is supported only in India. International users will receive the OTP through Email"
        ),
        {
          position: "top-center",
          hideProgressBar: true,
          closeButton: false,
          className: "mail_toast",
        }
      );
    }
  }, [user?.country_code]);
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.contact_email) {
      isValid = false;
      errors["contact_email"] = "Email id cannot be blank";
    }
    if (!user?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Contact name cannot be blank";
    }
    if (!user?.phone_number) {
      isValid = false;
      errors["phone_number"] = "Phone number cannot be blank";
    }
    if (!user?.country_code) {
      isValid = false;
      errors["country_code"] = "country code cannot be blank";
    }
    if (!user?.local_password) {
      isValid = false;
      errors["local_password"] = "Password cannot be blank";
    } else if (user?.local_password && user?.local_password?.length < 8) {
      isValid = false;
      errors["local_password"] = "Password should be of atleast 8 character";
    }
    return {
      isValid,
      errors,
    };
  };

  const handleRegistration = () => {
    let update = {};
    update.contact_name = user?.salute + " " + _.startCase(user?.contact_name);
    let check = handleValidation({ ...user, ...update });
    if (check.isValid) {
      if (userType === "doctor") {
        dispatch(signupDoctor({ ...user, ...update }, navigate));
      } else if (userType === "patient") {
        dispatch(signupPatient({ ...user, ...update }, navigate));
      }
      setErrors({});
    } else {
      setErrors(check.errors);
    }
  };
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="error my-1">{errors[keyName]}</p>
    ) : null;
  };
  return (
    <>
      <div className={`${styles.reg_flex}`}>
        <div className={`${styles.reg_flex_child}`}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
            {/* <span style={{ fontSize: "20px" }}>{" "}back</span> */}
            {/* </ArrowBackIcon> */}
          </div>
          <div className={`${styles.reg_flex_child_sub1}`}>
            <img src="images/quorit_logo_img.png" alt="" />
            <h1>REGISTRATION</h1>
          </div>
          <form className={`${styles.reg_flex2}`}>
            <div
              className={`${styles.reg_flex2_child1}`}
              style={{ flexDirection: "column" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={{ width: "150px" }}>
                  <AutoCompleteFieldLightTheme
                    labelId={"salute"}
                    id={"salute"}
                    keyName={"salute"}
                    data={salute}
                    textLabel={"Salute"}
                    value={user?.salute}
                    handleUserDetail={handleUserDetail}
                  />
                </div>

                <input
                  type="text"
                  placeholder="Doctor Name*"
                  value={user?.contact_name}
                  onChange={(e) =>
                    handleUserDetail("contact_name", e.target.value)
                  }
                  style={{ marginLeft: "5px", borderRadius: "7px" }}
                />
              </div>
              {renderError("contact_name")}
            </div>
            <div className={`${styles.reg_flex2_child_mail}`}>
              <div style={{ display: "flex", width: "100%" }}>
                <img src="email-outline.svg" width={50} height={50} alt="" />
                <FullWidthTextField
                  key={"23"}
                  type="text"
                  placeholder="Doctor Email*"
                  value={user?.contact_email}
                  onChange={(e) =>
                    handleUserDetail("contact_email", e.target.value)
                  }
                />
              </div>
              {renderError("contact_email")}
            </div>
            <div
              className={`${styles.reg_flex2_child1}`}
              style={{ flexDirection: "column" }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div className={styles.code_text}>
                  <CountryCodeDropdown
                    countries={countries}
                    textLabel={"Code*"}
                    id={"code2"}
                    keyName={"country_code"}
                    handleUserDetail={handleUserDetail}
                  />
                </div>
                <input
                  type="number"
                  placeholder="Phone Number*"
                  value={user?.phone_number}
                  onChange={(e) =>
                    handleUserDetail("phone_number", e.target.value)
                  }
                  style={{ marginLeft: "-3px", borderRadius: "7px" }}
                />
              </div>
              {renderError("phone_number")}
            </div>
            <div
              className={`${styles.reg_flex2_child1}`}
              style={{ flexDirection: "column" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <img src="images/lock.svg" width={50} height={50} alt="" />
                <PasswordInput
                  keyName={"local_password"}
                  value={user?.local_password}
                  //   label={"Set Password*"}
                  id={"main-password7"}
                  handleUserDetail={handleUserDetail}
                />
                {/* <input type="password"
                                        placeholder="Enter your Password*"
                                        value={user?.local_password}
                                        onChange={(e) => handleUserDetail("local_password", e.target.value)}
                                    /> */}
              </div>
              {renderError("local_password")}
            </div>
          </form>
          <button
            className={`${styles.reg_flex2_btn}`}
            onClick={handleRegistration}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
};

export default RegistrationPage;
