export const getIsAuthenticated = (state) => state.appreducer.isAuthenticated;
export const getUser = (state) => state.appreducer.user;
export const getUserId = (state) => state.appreducer.id;
export const getPatientId = (state) => state.appreducer.patientId;
export const getCaseId = (state) => state.appreducer.caseId;
export const getSessionId = (state) => state.appreducer.sessionId;

export const getPatientDetail = (state) => state.appreducer.patientDetail;
export const getCaseDetail = (state) => state.appreducer.caseDetail;
export const getImageGallery = (state) => state.appreducer.imageGallery;
export const getSessionList = (state) => state.appreducer.sessionList;

export const getRefferedDoctor = (state) => state.appreducer.reffer;

export const getReffered = (state) => state.appreducer.reffered;
export const getConsult = (state) => state.appreducer.consult;
export const getCompleted = (state) => state.appreducer.completed;
export const getOngoing = (state) => state.appreducer.ongoing;

export const getCases = (state) => state.appreducer.cases;
export const getAcceptCases = (state) => state.appreducer.acceptCases;

export const getSessionChatId = (state) => state.appreducer.sessionChatId;
